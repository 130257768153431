import React, { Fragment } from "react";
import { messageDisplay, responseDownloadFileErrors, responseErrors, responseSuccess } from "utils";
import { validationSearchForm } from "utils/validation";
// Component
import { InputButton } from "components/UI";
// Hooks
import { useConfirmDialogContext } from "context/confirmDialogContext";
// Service
import {
  useReprocessDownloadScanTransMutation,
  useReprocessIgnoreMutation,
  useReprocessMutation,
  useReprocessSearchMutation,
} from "shared/services/common/monitoring";
// Constant
import { MessageType, ModeAction, MSG_TYPE } from "state/enum";
import { userProfile } from "constant";
import { BUTTON_VALUE } from "shared/constants/button.constant";
import { API_REPROCESS } from "shared/constants/api-name/common/reprocess";
import { PAGINATION } from "shared/constants/table.constant";
import { FIRST_PAGE, ROW_PER_PAGE } from "shared/constants";
import { useLoadingContext } from "shared/hooks/LoadingContext";
export default function ButtonBar(props) {
  const {
    mode,
    form,
    rows,
    setRows,
    setMode,
    onSearch,
    setOnSearch,
    isAdmin,
    refresh,
    table: { rowModesModel, rowSelectionModel, setRowModesModel, setRowSelectionModel },
    pagination: { setPagination, setPageNumber, pagination, pageNumber },
    setMsg: { setMsgAlert, setMsgError },
    // stateButton: { searchBtn, reprocessBtn, ignoreBtn, downloadBtn },
  } = props;
  const { startLoading, stopLoading } = useLoadingContext();
  // API
  const searchReprocess = useReprocessSearchMutation();
  const reprocess = useReprocessMutation();
  const ignoreReprocess = useReprocessIgnoreMutation();
  const downloadReprocess = useReprocessDownloadScanTransMutation();
  const confirmDialogCtx = useConfirmDialogContext();
  const handleSearchBtn = async event => {
    try {
      event.preventDefault();
      setMsgError([]);
      setMsgAlert([]);
      setMode(ModeAction.VIEW);
      setOnSearch(false);
      setRowModesModel({});
      setRowSelectionModel([]);
      setPageNumber(FIRST_PAGE);
      setPagination({});
      setRows([]);
      const body = {
        [API_REPROCESS.DATA_OWNER]: form?.dataOwner,
        [API_REPROCESS.BUSINESS_NAME]: form?.businessName,
        [API_REPROCESS.PLANT]: form?.plant,
        [API_REPROCESS.BATCH_ROUND]: form?.batchRound,
        [PAGINATION.PAGE_NUMBER]: pageNumber,
        [PAGINATION.ROW_PER_PAGE]: ROW_PER_PAGE,
      };
      const { isSuccess, errors } = validationSearchForm({
        data: body,
        rule: [
          {
            field: API_REPROCESS.BUSINESS_NAME,
            type: MessageType.EMPTY,
            key: ["Business Data"],
          },
        ],
      });
      if (!isSuccess) {
        setMsgError(errors);
        window.scrollTo(0, 0);
        return;
      }
      startLoading();
      const searchData = await searchReprocess(body);
      stopLoading();
      const data =
        searchData?.result?.data?.map((item, index) => ({
          no: index + 1,
          [API_REPROCESS.ROW_NUMBER]: item?.rowNumber,
          [API_REPROCESS.DATA_OWNER]: item?.dataOwner,
          [API_REPROCESS.UPLOAD_SOURCE]: item?.uploadSource,
          [API_REPROCESS.RECORD_ID]: item?.recordId,
          [API_REPROCESS.PLAN_SERVER]: item?.plantServer,
          [API_REPROCESS.SCAN_TRANS_HID]: item?.scanTransHId,
          [API_REPROCESS.HH_CD]: item?.hhCd,
          [API_REPROCESS.HH_LOCATION_ID]: item?.hhLocationId,
          [API_REPROCESS.HH_LOCATION_DESC]: item?.hhLocationDesc,
          [API_REPROCESS.SCAN_MODE]: item?.scanMode,
          [API_REPROCESS.SCAN_SUB_MODE]: item?.scanSubMode,
          [API_REPROCESS.MANUAL_INPUT_FLAG]: item?.manualInputFlag,
          [API_REPROCESS.REF_LOCATION_ID]: item?.refLocationId,
          [API_REPROCESS.REF_LOCATION_DESC]: item?.refLocationDesc,
          [API_REPROCESS.DLV_VHC_ID]: item?.dlvVhcId,
          [API_REPROCESS.DLV_INSTR_SHT_NO]: item?.dlvInstrShtNo,
          [API_REPROCESS.SHIPPING_INFO_H_ID]: item?.shippingInfoHId,
          [API_REPROCESS.TOTAL_QTY]: item?.totalQty,
          [API_REPROCESS.DISCREPANCY_QTY]: item?.discrepancyQty,
          [API_REPROCESS.STATUS]: item?.sts,
          [API_REPROCESS.VANNING_STATUS]: item?.vanningSts,
          [API_REPROCESS.SEND_FLAG]: item?.sendFlag,
          [API_REPROCESS.H_CREATE_DATE]: item?.hCreateDt,
          [API_REPROCESS.H_CREATE_BY]: item?.hCreateBy,
          [API_REPROCESS.H_UPDATE_DATE]: item?.hUpdateDt,
          [API_REPROCESS.H_UPDATE_BY]: item?.hUpdateBy,
          [API_REPROCESS.SCAN_TRANS_DESTINATION_ID]: item?.scanTransDId,
          [API_REPROCESS.R_PACKAGE_TYPE_ID]: item?.rPkgTypeId,
          [API_REPROCESS.R_PACKAGE_TYPE]: item?.rPkgType,
          [API_REPROCESS.R_PACKAGE_TYPE_COMPANY_ABBR]: item?.rPkgOwnerCompAbbr,
          [API_REPROCESS.R_PACKAGE_STATUS_FROM]: item?.rPkgStsFrom,
          [API_REPROCESS.R_PACKAGE_STATUS_TO]: item?.rPkgStsTo,
          [API_REPROCESS.BARCODE_ID]: item?.barcodeId,
          [API_REPROCESS.PACK_STATUS_FROM]: item?.packStsFrom,
          [API_REPROCESS.PACK_STATUS_TO]: item?.packStsTo,
          [API_REPROCESS.SCAN_TIME_STAMP]: item?.scanTimestamp,
          [API_REPROCESS.DESTINATION_UPDATE_DATE]: item?.dUpdateDt,
          [API_REPROCESS.DESTINATION_UPDATE_BY]: item?.dUpdateBy,
          [API_REPROCESS.SCAN_QTY]: item?.scanQty,
          [API_REPROCESS.PLANT]: item?.plant,
          [API_REPROCESS.BUSINESS_NAME]: item?.businessName,
          [API_REPROCESS.PROCESS_DATE]: item?.processDt,
          [API_REPROCESS.BATCH_ROUND]: item?.batchRound,
          [API_REPROCESS.PARKING_FLAG]: item?.parkingFlag,
          [API_REPROCESS.REPROCESS_FLAG]: item?.reprocessFlag,
        })) ?? [];

      // const { searchData, data } = await getSearch(body);
      if (!data.length) {
        setMsgError([messageDisplay({ type: MSG_TYPE.ERROR, code: "MSTD0059AERR", msg: "" })]);
        window.scrollTo(0, 0);
        document.getElementById("select-download-business-data").focus();
        return false;
      }
      setPagination(searchData?.result?.pagination ?? {});
      setPageNumber(searchData?.result?.pagination?.pageNumber);
      setRows(data);
      setOnSearch(true);
      return;
    } catch (error) {
      stopLoading();
      const errors = responseErrors(error);
      setMsgError([`${errors}`]);
      return;
    }
  };
  const handleReprocessBtn = async () => {
    try {
      setMsgAlert([]);
      setMsgError([]);
      const confirm = await confirmDialogCtx.success({
        type: "COMMON_REPROCESS_CONFIRM_REPROCESS",
      });
      if (!confirm) {
        return;
      }

      let dataArray = [];
      rowSelectionModel.forEach(index => {
        const row = rows.find(v => v.rowNumber === index);
        let temp = {
          [API_REPROCESS.PLANT]: row?.plant,
          [API_REPROCESS.BATCH_ROUND]: row?.batchRound,
          [API_REPROCESS.BUSINESS_NAME]: row?.businessName,
        };
        dataArray.push(temp);
      });
      const reprocessData = {
        [API_REPROCESS.DATA_OWNER]: userProfile.dataOwner,
        [API_REPROCESS.BUSINESS_NAME]: form?.businessName,
        [API_REPROCESS.DATA]: dataArray,
      };
      startLoading();
      const response = await reprocess(reprocessData);

      // const msg = messageDisplay({ type: MSG_TYPE.INFO, code: "MSTD0090AINF", msg: [""] });
      const msg = responseSuccess(response);
      // const msg = response.message;
      setMsgAlert([msg]);
      window.scrollTo(0, 0);

      const body = {
        [API_REPROCESS.DATA_OWNER]: form?.dataOwner,
        [API_REPROCESS.BUSINESS_NAME]: form?.businessName,
        [API_REPROCESS.PLANT]: form?.plant,
        [API_REPROCESS.BATCH_ROUND]: form?.batchRound,
        [PAGINATION.PAGE_NUMBER]: pageNumber,
        [PAGINATION.ROW_PER_PAGE]: ROW_PER_PAGE,
      };
      await refresh(body);
      stopLoading();
      document.getElementById("select-download-business-data").focus();
      return true;
    } catch (error) {
      stopLoading();
      const errors = responseErrors(error);
      setMsgError([`${errors}`]);
      window.scrollTo(0, 0);
      return;
    }
  };
  const handleIgnoreBtn = async () => {
    try {
      setMsgAlert([]);
      setMsgError([]);
      const confirm = await confirmDialogCtx.success({
        type: "COMMON_REPROCESS_CONFIRM_IGNORE",
      });
      if (!confirm) {
        return;
      }

      let dataArray = [];
      rowSelectionModel.forEach(index => {
        const row = rows.find(v => v.rowNumber === index);
        let temp = {
          [API_REPROCESS.PLANT]: row?.plant,
          [API_REPROCESS.BATCH_ROUND]: row?.batchRound,
          [API_REPROCESS.BUSINESS_NAME]: row?.businessName,
        };
        dataArray.push(temp);
      });
      const reprocessData = {
        [API_REPROCESS.DATA_OWNER]: userProfile.dataOwner, // !MOCK
        [API_REPROCESS.BUSINESS_NAME]: form?.businessName,
        [API_REPROCESS.DATA]: dataArray,
      };
      startLoading();
      const response = await ignoreReprocess(reprocessData);
      const msg = responseSuccess(response);
      setMsgAlert([msg]);
      window.scrollTo(0, 0);

      const body = {
        [API_REPROCESS.DATA_OWNER]: form?.dataOwner,
        [API_REPROCESS.BUSINESS_NAME]: form?.businessName,
        [API_REPROCESS.PLANT]: form?.plant,
        [API_REPROCESS.BATCH_ROUND]: form?.batchRound,
        [PAGINATION.PAGE_NUMBER]: pageNumber,
        [PAGINATION.ROW_PER_PAGE]: ROW_PER_PAGE,
      };
      await refresh(body);
      stopLoading();

      document.getElementById("select-download-business-data").focus();
      return true;
    } catch (error) {
      stopLoading();
      const errors = responseErrors(error);
      setMsgError([`${errors}`]);
      window.scrollTo(0, 0);
      return;
    }
  };
  const handleDownloadBtn = async () => {
    setMsgError([]);
    setMsgAlert([]);
    try {
      const ONE_RECORD = 1;
      if (rowSelectionModel.length !== ONE_RECORD) {
        const msg = messageDisplay({ type: MSG_TYPE.ERROR, code: "MDL00021AERR", msg: ["[Download]"] });
        setMsgError([msg]);
        return;
      }

      const selectedNo = rowSelectionModel[0];
      const row = rows.find(v => v.rowNumber === selectedNo);

      // const fileName = `${row?.businessName}_${row?.plant}_${row?.batchRound}_${getLocalDate().format("YYYYMMDDHHmm")}`;
      const reprocessData = {
        [API_REPROCESS.DATA_OWNER]: userProfile.dataOwner,
        [API_REPROCESS.BUSINESS_NAME]: row?.businessName,
        [API_REPROCESS.PLANT]: row?.plant,
        [API_REPROCESS.BATCH_ROUND]: row?.batchRound,
      };
      startLoading();
      if (form?.businessName === "Scan Transaction") {
        await downloadReprocess(reprocessData, "scan-transaction");
      } else {
        await downloadReprocess(reprocessData, "vanning");
      }
      stopLoading();
      document.getElementById("select-download-business-data").focus();
      return;
    } catch (error) {
      stopLoading();
      const err = responseDownloadFileErrors(error);
      setMsgError(err);
      window.scroll(0, 0);
      return;
    }
  };
  return (
    <Fragment>
      <InputButton
        id="btn-search"
        value={BUTTON_VALUE.SEARCH}
        onClick={handleSearchBtn}
        onKeyDown={e => {
          if (!onSearch && e.key === "Tab") {
            e.preventDefault();
            document.getElementById("select-upload-business-data").focus();
          }
        }}
      />
      <InputButton
        id="btn-reprocess"
        value={BUTTON_VALUE.RE_PROCESS}
        onClick={handleReprocessBtn}
        disabled={mode !== ModeAction.SELECTED}
      />
      <InputButton
        id="btn-ignore"
        value={BUTTON_VALUE.IGNORE}
        onClick={handleIgnoreBtn}
        disabled={mode !== ModeAction.SELECTED}
      />
      {isAdmin && (
        <InputButton
          id="btn-download"
          value={BUTTON_VALUE.DOWNLOAD}
          disabled={mode !== ModeAction.SELECTED}
          onClick={handleDownloadBtn}
        />
      )}
    </Fragment>
  );
}
