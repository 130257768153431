import { saveAs } from "utils/download-file";
import { useMutateData, useCustomQuery, useMutateDownload } from "shared/services/base.service";

import { userProfile } from "constant";
import { waitAndDownload } from "../common-dowload/download-helper-service";

const BaseServicePath = process.env.REACT_APP_API_REPORT;

const ReportCommonApiService = () => ({
  useReportCommonGetReportQuery: reportId => {
    const data = useCustomQuery({
      url: `${BaseServicePath}/report-common/get-report`,
      method: "POST",
      enabled: !!reportId,
      select: data => data.result,
      keepPreviousData: true,
      body: {
        cd: reportId,
      },
    });
    return data;
  },
  useReportCommonGetRPackageOwnerQuery: dataOwner => {
    const data = useCustomQuery({
      url: `${BaseServicePath}/report-common/get-available-r-package-owner`,
      method: "POST",
      enabled: true,
      select: data => data.result,
      keepPreviousData: true,
      body: {
        dataOwner: dataOwner ?? "TMATH",
      },
    });
    return data;
  },
  useReportCommonGetRPackageTypeQuery: ({ rPackageOwner }) => {
    const data = useCustomQuery({
      url: `${BaseServicePath}/report-common/get-available-r-package-type`,
      method: "POST",
      enabled: !!rPackageOwner,
      select: data => data.result,
      keepPreviousData: true,
      body: {
        dataOwner: "TMATH",
        rPackageOwner: "TMATH", // selected R Package Onwer Company Abbr
        groupCd: "", // optional
        rPackageId: "", // optional
      },
    });
    return data;
  },
  useReportCommonGetRPackageOwnerQuery96i0: dataOwner => {
    const data = useCustomQuery({
      url: `${BaseServicePath}/report-common/get-available-r-package-owner-ldn96i0`,
      method: "POST",
      enabled: true,
      select: data => data.result,
      keepPreviousData: true,
      body: {
        dataOwner: dataOwner ?? "TMATH",
      },
    });
    return data;
  },
  useReportCommonGetRPackageTypeQuery96i0: ({ rPackageOwner }) => {
    const data = useCustomQuery({
      url: `${BaseServicePath}/report-common/get-available-r-package-type-ldn96i0`,
      method: "POST",
      enabled: !!rPackageOwner,
      select: data => data.result,
      keepPreviousData: true,
      body: {
        dataOwner: "TMATH",
        rPackageOwner: "TMATH", // selected R Package Onwer Company Abbr
        groupCd: "", // optional
        rPackageId: "", // optional
      },
    });
    return data;
  },
  useReportCommonGetLocationNameListQuery: ({ dataOwner }) => {
    const data = useCustomQuery({
      url: `${BaseServicePath}/report-common/get-location-name-list-master`,
      method: "POST",
      enabled: true,
      select: data => data.result,
      body: {
        dataOwner: dataOwner,
      },
    });
    return data;
  },
  useReportCommonGetCountryListQuery: countryCd => {
    const data = useCustomQuery({
      url: `${BaseServicePath}/report-common/get-country-list`,
      method: "POST",
      enabled: true,
      select: data => data.result,
      keepPreviousData: false,
      body: {
        // countryCd: countryCd, // optional
        countryCd: userProfile.countryCd, // optional
        loginUserCompanyAbbr: userProfile.dataOwner,
      },
    });
    return data;
  },
  useReportCommonGetWarehouseListQuery: ({ countryCd }) => {
    const { data, refetch } = useCustomQuery({
      key: ["report-warehouse", countryCd],
      url: `${BaseServicePath}/report-common/get-warehouse`,
      method: "POST",
      enabled: !!countryCd,
      select: data => data.result,
      body: {
        dataOwner: userProfile.dataOwner,
        countryCd: countryCd,
        loginUserCompanyAbbr: userProfile.dataOwner,
      },
    });
    return { data, refetch };
  },
  useReportCommonRPackageCategoryQuery: dataOwner => {
    const data = useCustomQuery({
      url: `${BaseServicePath}/report-common/get-available-r-package-category`,
      method: "POST",
      enabled: true,
      select: data => data.result,
      keepPreviousData: true,
      body: {
        dataOwner: dataOwner,
      },
    });
    return data;
  },
  useReportCommonRPackageCategoryListQuery: dataOwner => {
    const data = useCustomQuery({
      url: `${BaseServicePath}/report-common/get-available-r-package-category-list`,
      method: "POST",
      enabled: true,
      select: data => data.result,
      keepPreviousData: true,
      body: {
        dataOwner: dataOwner,
      },
    });
    return data;
  },
  useReportCommonTransactionCategoryListQuery: dataOwner => {
    const data = useCustomQuery({
      url: `${BaseServicePath}/report-common/get-transaction-report`,
      method: "POST",
      enabled: true,
      select: data => data.result,
      keepPreviousData: true,
      body: {
        dataOwner: dataOwner,
      },
    });
    return data;
  },
  useReportCommonDataTypeListQuery: dataOwner => {
    const data = useCustomQuery({
      url: `${BaseServicePath}/report-common/get-data-type`,
      method: "POST",
      enabled: true,
      select: data => data.result,
      body: {
        dataOwner: dataOwner,
      },
    });
    return data;
  },
  useExcelDownloadMutation: () => {
    const { mutate } = useMutateData({
      invalidateKeys: [],
    });
    return async ({ body, reportName }) => {
      return await mutate({
        url: `${"/web-api-report" ?? BaseServicePath}/report-common/${reportName}`,
        method: "POST",
        data: body,
      });
    };
  },
  useExcelDownloadMutationWithUrl: () => {
    const { mutate } = useMutateData({
      invalidateKeys: [],
    });
    return async ({ body, url }) => {
      return await mutate({
        url: `${"/web-api-report" ?? BaseServicePath}${url}`,
        method: "POST",
        data: body,
      });
    };
  },
  useExcelDownloadOnlineJavaMutation: () => {
    const { mutate } = useMutateDownload({
      invalidateKeys: [],
    });
    return async (body, pathReport) => {
      return await mutate(
        {
          url: `${BaseServicePath}/report-common/${pathReport}`,
          method: "POST",
          data: body,
          responseType: "arraybuffer",
        },
        {
          onSuccess: response => {
            // waitAndDownload(60, response);
            saveAs(response);
          },
        }
      );
    };
  },
  useExcelDownloadOnlineMutation: () => {
    const { mutate } = useMutateDownload({
      invalidateKeys: [],
    });
    return async (body, pathReport) => {
      return await mutate(
        {
          url: `${BaseServicePath}/report-common/${pathReport}`,
          method: "POST",
          data: body,
        }
        // {
        //   onSuccess: response => {
        //     // waitAndDownload(60, response);
        //   },
        // }
      );
    };
  },
  useExcelDownloadOnDemandMutation: () => {
    const { mutate } = useMutateData({
      invalidateKeys: [],
    });
    return async (body, pathReport) => {
      console.log(body);
      console.log(pathReport);
      return await mutate({
        url: `${BaseServicePath}/report-common/${pathReport}`,
        method: "POST",
        data: body,
      });
    };
  },
});

export const {
  useReportCommonGetReportQuery,
  useReportCommonGetRPackageOwnerQuery,
  useReportCommonGetRPackageTypeQuery,
  useReportCommonGetRPackageOwnerQuery96i0,
  useReportCommonGetRPackageTypeQuery96i0,
  useReportCommonGetLocationNameListQuery,
  useReportCommonGetCountryListQuery,
  useReportCommonGetWarehouseListQuery,
  useReportCommonRPackageCategoryQuery,
  useReportCommonRPackageCategoryListQuery,
  useReportCommonTransactionCategoryListQuery,
  useReportCommonDataTypeListQuery,
  useExcelDownloadMutation,
  useExcelDownloadOnDemandMutation,
  useExcelDownloadOnlineMutation,
  useExcelDownloadOnlineJavaMutation,
  useExcelDownloadMutationWithUrl,
} = ReportCommonApiService();
