import React from "react";
import { NumericFormat } from "react-number-format";
import { useGridApiContext } from "@mui/x-data-grid";
import { TextField, styled } from "@mui/material";
import { isNull } from "lodash";

const StyledTextField = styled(TextField)(({ theme, error }) => ({
  "& .MuiInput-root": {
    border: `1.5px solid ${error ? theme.palette.primary.main : theme.palette.secondary.medium}`,
    backgroundColor: theme.palette.primary.light,
    borderRadius: ".3rem",
  },
  "& .MuiInput-root.Mui-focused": {
    border: `1.5px solid ${theme.palette.tertiary.main} !important`,
    borderRadius: ".3rem",
  },
  "& .MuiInputBase-input": {
    padding: "4px 10px",
    textAlign: "right",
  },
}));

const InputEditTableCurrency = ({
  value,
  field,
  id,
  disabled = false,
  className,
  required,
  params,
  style,
  decimalScale = 0,
  maxLimit = 1000000000,
  onChange = null,
}) => {
  const apiRef = useGridApiContext();

  const handleInputChange = event => {
    const newValue = event.value;
    apiRef.current.setEditCellValue({ id, field, value: newValue });
    if (!isNull(onChange)) {
      onChange(event);
    }
  };

  return (
    <div style={{ margin: "0 5px", width: "100%" }}>
      <NumericFormat
        value={value === 0 ? "" : value}
        disabled={disabled}
        className={className}
        thousandSeparator=","
        decimalScale={decimalScale}
        fixedDecimalScale
        customInput={StyledTextField}
        onValueChange={handleInputChange}
        required={required}
        allowNegative={false}
        allowLeadingZeros={false}
        style={{ height: "30px", ...style }}
        isAllowed={values => {
          const { floatValue } = values;
          return floatValue < maxLimit || !floatValue;
        }}
        {...params}
      />
    </div>
  );
};
export default InputEditTableCurrency;
