import React, { Fragment, useState } from "react";
import { Grid, Box, Divider } from "@mui/material";
//Component
import { HeaderContentBar } from "components/Layout";
import { AlertMessage, DropDown } from "components/UI";
import Grouping from "pages/Forecast/ShipmentManagementScreen/components/Grouping/Grouping";
import Mapping from "pages/Forecast/ShipmentManagementScreen/components/Mapping/Mapping";
//TYPE
import { ModeAction } from "state/enum";
//Service
import {
  useShipmentOperationTypeQuery,
  useShipmentExporterQuery,
  useShipmentImporterQuery,
  useShipmentRPackageQuery,
  useShipmentStatusQuery,
} from "shared/services/inventory-forecast";
import { DEFAULT_FORM } from "./constants/shipment.constant";
import { API_SHIPMENT } from "shared/constants";
export default function ShipmentManagementScreen() {
  const [appId, setAppId] = useState(null);
  const [form, setForm] = useState(DEFAULT_FORM);
  const [msgError, setMsgError] = useState([]);
  const [msgAlert, setMsgAlert] = useState([]);
  const [onSearch, setOnSearch] = useState(false);
  const [mode, setMode] = useState(ModeAction.VIEW);
  //API
  const { data: operationList } = useShipmentOperationTypeQuery();
  const { data: exporterList } = useShipmentExporterQuery();
  const { data: importerList } = useShipmentImporterQuery();
  const { data: rPkgList } = useShipmentRPackageQuery();
  const { data: statusList } = useShipmentStatusQuery();
  const handleChangeOperation = () => {
    if (form?.operationId === "G") {
      return (
        <Grouping
          form={form}
          setForm={setForm}
          onSearch={onSearch}
          setOnSearch={setOnSearch}
          mode={mode}
          setMode={setMode}
          setMsg={{ setMsgError, setMsgAlert }}
          dataList={{ exporterList, importerList, rPkgList, statusList }}
        />
      );
    } else if (form?.operationId === "M") {
      return (
        <Mapping
          form={form}
          setForm={setForm}
          setMsg={{ setMsgError, setMsgAlert }}
          onSearch={onSearch}
          setOnSearch={setOnSearch}
          mode={mode}
          setMode={setMode}
          setAppId={setAppId}
          dataList={{ exporterList, importerList, rPkgList, statusList }}
        />
      );
    } else return "";
  };

  const handleFocus = e => {
    if (form?.operationId === "") {
      const handleTabKey = (event) => {
        if (event.key === "Tab") {
          event.preventDefault();
        }
      };

      e.target.addEventListener("keydown", handleTabKey);

      e.target.addEventListener("blur", () => {
        e.target.removeEventListener("keydown", handleTabKey);
      });
    }
  };

  return (
    <Fragment>
      <Box sx={{ padding: "1rem" }}>
        <HeaderContentBar title="WDN920K0 : Shipment Management Screen" />

        <AlertMessage type={"warning"} message={msgError} />
        <AlertMessage type={"success"} message={msgAlert} />
        <div id="appid" style={{ display: "none" }}>
          {appId}
        </div>
        <Grid container spacing={2} sx={{ mt: 1, md: 4, alignItems: "center" }}>
          <Grid item xs={2}>
            <strong>*Operation : </strong>
          </Grid>
          <Grid item xs={3}>
            <DropDown
              data-testid="input-operation"
              id="input-operation"
              required
              fullWidth={true}
              value={form?.operationId}
              onChange={e => {
                setForm({
                  [API_SHIPMENT.OPERATION_ID]: e.target.value,
                  [API_SHIPMENT.DATA_OWNER]: form?.dataOwner,
                  [API_SHIPMENT.COMPANY_ABBR]: form?.dataOwner,
                  [API_SHIPMENT.USER_ID]: form.userId,
                  [API_SHIPMENT.EXPORTER_ID]: "",
                  // Id of TMATH
                  [API_SHIPMENT.IMPORTER_ID]: "CMP_1208_000001",
                  [API_SHIPMENT.R_RKG_OWNER_ID]: "",
                  [API_SHIPMENT.FORECAST_MONTH]: "",
                  [API_SHIPMENT.SHIPMENT_STATUS_ID]: "",
                  [API_SHIPMENT.ETD_FORM]: "",
                  [API_SHIPMENT.ETD_TO]: "",
                  [API_SHIPMENT.ETA_FORM]: "",
                  [API_SHIPMENT.ETA_TO]: "",
                  [API_SHIPMENT.NCV_FILE_UPLOAD]: "",
                  [API_SHIPMENT.NCV_FILE_UPLOAD]: "",
                  [API_SHIPMENT.FILE_NAME]: {
                    [API_SHIPMENT.ORG_ACTL_FILE_NAME]: "",
                    [API_SHIPMENT.ORG_NCV_FILE_NAME]: "",
                    [API_SHIPMENT.ORG_BL_FILE_NAME]: "",
                  },
                  [API_SHIPMENT.FILE]: {
                    [API_SHIPMENT.ORG_ACTL_FILE_NAME]: {},
                    [API_SHIPMENT.ORG_NCV_FILE_NAME]: {},
                    [API_SHIPMENT.ORG_BL_FILE_NAME]: {},
                  },
                });
                setMsgAlert([]);
                setMsgError([]);
                setOnSearch(false);
                setMode(ModeAction.VIEW);
              }}
              menu={operationList?.map(val => ({
                key: val.cd,
                value: val.value,
              }))}
              disabled={[ModeAction.ADD, ModeAction.EDIT].includes(mode)}
              placeholder="<Select>"
              defaultValue=""
              autoFocus={true}
              onFocus={handleFocus}
            />
          </Grid>
          <Grid item xs={12}>
            <Divider />
          </Grid>
          <Grid item xs={12}>
            {handleChangeOperation()}
          </Grid>
        </Grid>
      </Box>
    </Fragment>
  );
}
