import React from "react";
import { Grid, styled } from "@mui/material";
import { DataGrid, GridRowEditStopReasons, GridRowModes } from "@mui/x-data-grid";
import { ModeAction } from "state/enum";
import PaginationTable from "./Pagination";
import { isNull } from "lodash";

const StyledDataGird = styled(DataGrid)(({ theme, error, disableCheckBoxAll, borderTable }) => ({
  "& .MuiDataGrid-columnHeaderTitleContainer.MuiDataGrid-withBorderColor": {
    border: "none",
  },
  "& .MuiDataGrid-columnHeaderTitle": {
    whiteSpace: "wrap",
    lineHeight: "20px",
    textAlign: "center",
  },
  "& .MuiDataGrid-columnHeaderTitleContainerContent": {
    whiteSpace: "wrap",
    lineHeight: "20px",
    textAlign: "center",
  },
  "& .MuiDataGrid-cell.MuiDataGrid-withBorderColor": {
    border: "0.5px solid #cecece",
    outline: "none",
  },
  "& .MuiDataGrid-cell.MuiDataGrid-cell--editing:focus-within": {
    outline: "none",
  },

  "& .MuiDataGrid-columnSeparator": {
    display: "none",
  },
  "&.MuiDataGrid-root": {
    border: borderTable,
  },
  "& .MuiDataGrid-columnHeader": {
    borderLeft: "1px solid #ffff",
    borderTop: "none",
  },
  "& .MuiDataGrid-columnHeaders": {
    "& .MuiDataGrid-withBorderColor:focus": {
      outline: "none",
    },
    background: "#7F7F7F",
    color: "#ffff",
  },
  "& .MuiDataGrid-virtualScroller": {
    // minHeight: "350px",
  },
  "& .Mui-odd": {
    background: "#e3e3e3",
  },
  "& .MuiDataGrid-columnHeaderCheckbox .MuiDataGrid-columnHeaderTitleContainer": {
    display: disableCheckBoxAll ? "none" : "",
  },

  //for WDN95031
  "& .custom-row": {
    "& .MuiDataGrid-row--editing": {
      "& .MuiDataGrid-cell": {
        backgroundColor: "#FFD7D7",
      },
    },
  },
}));

export default function DataGridTable({
  id,
  mode,
  setMode,
  rows,
  processRowUpdate,
  onProcessRowUpdateError,
  isRowUnSelectable,
  handleRowDisableSelect = null,
  borderTable = "none",
  rowHeight = 60,
  tableHeight = "auto",
  autoHeight = false,
  onCellClick = true,
  onCellDoubleClick = false,
  onKeyDownEditMode = false,
  onPagination = true,
  checkboxSelection = true,
  disableCheckBoxAll = true,
  isMultipleSelection = false,
  disableRowSelectionOnClick = false,
  handleClassNameRow = null,
  classNameOfCell,
  column: { columns, columnGroupingModel, columnVisibilityModel },
  pagination: { pagination, pageNumber, handleChangePagination },
  rowSelection: { rowModesModel, setRowModesModel, rowSelectionModel, setRowSelectionModel },
  ...props
}) {
  const handleRowSelectionModelChange = newRowSelectionModel => {
    if (newRowSelectionModel?.length) {
      setMode(ModeAction.SELECTED);
    } else {
      setMode(ModeAction.VIEW);
    }
    setRowSelectionModel(newRowSelectionModel);
  };

  const handleRowModesModelChange = newRowModesModel => {
    setRowModesModel(newRowModesModel);
  };

  const handleRowEditStop = (params, event) => {
    event.defaultMuiPrevented = true;

    if (params.reason === GridRowEditStopReasons.rowFocusOut) {
      event.defaultMuiPrevented = true;
    }
    if (params.reason === GridRowEditStopReasons.enterKeyDown) {
      event.defaultMuiPrevented = true;
    }
    if (params.reason === GridRowEditStopReasons.escapeKeyDown) {
      event.defaultMuiPrevented = true;
    }
    if (params.reason === GridRowEditStopReasons.tabKeyDown) {
      event.defaultMuiPrevented = true;
    }
    if (params.reason === GridRowEditStopReasons.shiftTabKeyDown) {
      event.defaultMuiPrevented = true;
    }
  };
  const handleRowSelectable = params => {
    // For on Multiple select row mode
    if (isMultipleSelection) {
      return true;
    }
    // For on single select row mode
    if (mode === ModeAction.SELECTED) {
      return params.row.rowNumber !== rowSelectionModel[0] ? false : true;
    }
    if ([ModeAction.ADD, ModeAction.EDIT].includes(mode)) return false;

    if (!isNull(handleRowDisableSelect)) {
      // For disable checkBox selection by  field
      return handleRowDisableSelect(params);
    }
    return true;
  };
  const handleOnKeyDown = id => () => {
    console.log("onKeyDown", id);
    let tempRowModesModel = rowModesModel;
    const rowSelect = rowSelectionModel.map(val => {
      tempRowModesModel = {
        ...tempRowModesModel,
        [val]: { mode: GridRowModes.Edit },
      };
    });

    if (rowSelect) {
      setRowModesModel(tempRowModesModel);
    }
  };
  const handleRowClassName = params => {
    if (!isNull(handleClassNameRow)) {
      // console.log(handleClassNameRow(params));
      return handleClassNameRow(params);
    }
    return params.indexRelativeToCurrentPage % 2 === 0 ? "Mui-even" : "Mui-odd";
  };
  return (
    <>
      <Grid container spacing={2} maxHeight="100%">
        <Grid
          item
          xs={12}
          id="data_table"
          sx={{
            // height: "400px",
            height: `${tableHeight}`,
            minHeight: "350px",
            width: "100%",
            ...classNameOfCell,
          }}>
          <StyledDataGird
            data-testid={id}
            id={id}
            editMode="row"
            getRowId={row => row?.rowNumber}
            getRowClassName={handleRowClassName}
            getRowHeight={() => {
              return autoHeight ? "auto" : null;
            }}
            rowHeight={rowHeight}
            checkboxSelection={checkboxSelection}
            disableRowSelectionOnClick={disableRowSelectionOnClick}
            isRowSelectable={handleRowSelectable}
            experimentalFeatures={{ columnGrouping: true }}
            columnGroupingModel={columnGroupingModel}
            columnVisibilityModel={columnVisibilityModel}
            rows={rows}
            columns={columns}
            processRowUpdate={processRowUpdate}
            rowModesModel={rowModesModel}
            rowSelectionModel={rowSelectionModel}
            onRowModesModelChange={handleRowModesModelChange}
            onRowSelectionModelChange={handleRowSelectionModelChange}
            onRowEditStop={handleRowEditStop}
            onCellClick={(params, event) => {
              if (onCellClick) {
                event.defaultMuiPrevented = true;
              } else {
                event.stopPropagation();
              }
            }}
            onCellKeyDown={(params, event) => {
              handleOnKeyDown(params?.row?.rowNumber);
            }}
            onCellDoubleClick={(params, event) => {
              if (onCellDoubleClick) {
                event.defaultMuiPrevented = true;
              } else {
                event.stopPropagation();
              }
            }}
            onProcessRowUpdateError={onProcessRowUpdateError}
            onCellEditStart={params => {
              console.log("onCellEditStart", params);
            }}
            onCellEditStop={params => console.log("onCellEditStop", params)}
            hideFooter
            disableColumnMenu
            borderTable={borderTable}
            disableCheckBoxAll={disableCheckBoxAll}
            classNameOfCell={classNameOfCell}
            {...props}
          />
        </Grid>
        {onPagination && (
          <Grid item xs={12} sx={{ display: "flex", justifyContent: "space-between", py: 2 }}>
            <PaginationTable
              pagination={pagination}
              pageNumber={pageNumber}
              handleChangePagination={handleChangePagination}
              mode={mode}
            />
          </Grid>
        )}
      </Grid>
    </>
  );
}
