import React from "react";
import MenuItem from "@mui/material/MenuItem";
import { Select, FormControl, InputLabel } from "@mui/material";
import { useGridApiContext } from "@mui/x-data-grid";

const InputEditTableDropDown = ({
  value,
  memu,
  field,
  id,
  disabledd = false,
  setValue = undefined,
  className,
  required,
  placeholder,
  defaultValue = "",
  isShow = true,
  autoFocus = false,
}) => {
  // const { id, value, field, hasFocus } = props;
  const apiRef = useGridApiContext();
  const ref = React.useRef();

  const handleValueChange = event => {
    const newValue = event.target.value; // The new value entered by the user
    apiRef.current.setEditCellValue({ id, field, value: newValue });
    if (setValue) setValue(newValue);
  };
  return (
    isShow && (
      <div style={{ margin: "0 5px", width: "100%" }}>
        <FormControl style={{ width: "100%" }}>
          <InputLabel
            id="test-select-label"
            shrink={false}
            size="small"
            variant="outlined"
            style={{
              left: "45%",
              top: "50%",
              transform: "translate(-50%, -50%)",
            }}>
            {memu ? (!memu?.map(item => item.key).includes(value) ? placeholder : "") : placeholder}
          </InputLabel>
          <Select
            ref={ref}
            sx={{
              minWidth: "100%",
              backgroundColor: "white",
              "&:focus": {
                border: "1px solid #000 !important",
              },
            }}
            size="small"
            value={value}
            autoFocus={autoFocus}
            onChange={handleValueChange}
            disabled={disabledd}
            className={className}
            required={required}
            style={{ height: "32px" }}
            MenuProps={{
              style: {
                maxHeight: `${memu?.length > 30 ? "60%" : "100%"}`,
              },
            }}>
            <MenuItem value={defaultValue}>
              <span>{placeholder}</span>
            </MenuItem>
            {memu &&
              memu.map((val, index) => (
                <MenuItem key={index} value={val.key}>
                  {val.value}
                </MenuItem>
              ))}
          </Select>
        </FormControl>
      </div>
    )
  );
};

export default InputEditTableDropDown;
