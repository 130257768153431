import React, { useMemo, useState, useEffect } from "react";
import { Grid, Divider } from "@mui/material";
import { DatePickerField, InputButton, InputText } from "components/UI";
import { useHistorySearch } from "service/barcodeprint";
import { userProfile } from "constant";
import DataTable from "pages/DataTable";
import { messageDisplay, responseErrors } from "utils";
import { validationSearchForm } from "utils/validation";
import { FIRST_PAGE } from "shared/constants";
import { MessageType } from "state/enum";
import useColumnHistory from "../hooks/useColumnHistory";

const HistoryMode = ({ printType, setMsgError, setMsgAlert }) => {
  const [mode, setMode] = useState("view");
  const [rows, setRows] = useState([]);
  const [rowModesModel, setRowModesModel] = useState({});
  const [rowSelectionModel, setRowSelectionModel] = useState([]);
  const [onSearch, setOnSearch] = useState(false);

  const [form, setForm] = useState({
    printType: printType,
    printDateFrom: "",
    printDateTo: "",
    barcodeId: "",
  });

  // columns
  const columns = useMemo(() => {
    // eslint-disable-next-line react-hooks/rules-of-hooks
    return useColumnHistory();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [rows]);

  useEffect(() => {
    document.getElementById("input_printDateFrom").focus();
  }, []);

  // api
  const { mutateAsync: searchDataAsync } = useHistorySearch();

  // get search
  const getSearch = async (pageNumber = 1) => {
    try {
      setMsgError([]);
      setMsgAlert([]);
      setOnSearch(true);
      console.log(form);
      const searchData = await searchDataAsync({
        dataOwner: userProfile.dataOwner,
        printType: form.printType,
        barcodeId: form.barcodeId,
        printDateFrom: form.printDateFrom,
        printDateTo: form.printDateTo,
        pageNumber: pageNumber,
        rowsPerPage: 10,
      });
      console.log("searchData : ", searchData);

      const data =
        searchData?.result?.data?.map((item, index) => ({
          rowNumber: index + 1,
          ...item,
        })) ?? [];

      return { searchData, data };
    } catch (error) {
      const errors = responseErrors(error);
      setMsgError(errors);
      window.scrollTo(0, 0);
      return;
    }
  };

  // handle search
  const handleSearch = async () => {
    try {
      // const { searchData, data } = await getSearch();
      setMsgError([]);
      setMsgAlert([]);
      setRows([]);
      setPageNumber(FIRST_PAGE);
      setPagination({});
      setOnSearch(false);

      const body = {
        dataOwner: userProfile.dataOwner,
        printType: form.printType,
        barcodeId: form.barcodeId,
        printDateFrom: form.printDateFrom,
        printDateTo: form.printDateTo,
        pageNumber: FIRST_PAGE,
        rowsPerPage: 10,
      };
      const { isSuccess, errors } = validationSearchForm({
        data: body,
        rule: [
          {
            field: "printDateFrom",
            type: MessageType.EMPTY,
            key: ["Print Date (From)"],
          },
        ],
      });
      if (!isSuccess) {
        setMsgError(errors);
        window.scrollTo(0, 0);
        return;
      }
      const searchData = await searchDataAsync(body);

      const data =
        searchData?.result?.data?.map((item, index) => ({
          rowNumber: index + 1,
          printDt: item.printDt,
          barcodeIdFrom: item.barcodeIdFrom,
          barcodeIdTo: item.barcodeIdTo,
          printBy: item.printBy,
          printQty: item.printQty,
          numOfCopy: item.numOfCopy,
          printType: item.printType,
          ...item,
        })) ?? [];
      if (!data?.length) {
        setMsgError([messageDisplay({ type: "error", code: "MSTD0059AERR", msg: "" })]);
        return;
      }
      setPagination(searchData?.result?.pagination ?? {});
      setPageNumber(searchData?.result?.pagination?.pageNumber);
      setRows(data);
      setOnSearch(true);
    } catch (error) {
      const errors = responseErrors(error);
      setMsgError(errors);
      window.scrollTo(0, 0);
      return;
    }
  };

  // handle clear
  const handleClear = () => {
    setMsgError([]);
    setMsgAlert([]);
    setRows([]);
    setPageNumber(FIRST_PAGE);
    setPagination({});
    setOnSearch(false);

    setForm(old => ({
      ...old,
      printDateFrom: "",
      printDateTo: "",
      barcodeId: "",
    }));
  };

  // 1. pagination
  const [pagination, setPagination] = useState({});
  const [pageNumber, setPageNumber] = useState(1);

  const handleChangePagination = async (event, value) => {
    try {
      setMsgError([]);
      setMsgAlert([]);
      setPageNumber(value);
      const { searchData, data } = await getSearch(value);
      setPagination(searchData?.result?.pagination ?? {});
      setPageNumber(searchData?.result?.pagination?.pageNumber);
      setRows(data);
    } catch (error) {
      const errors = responseErrors(error);
      setMsgError(errors);
      window.scrollTo(0, 0);
      return;
    }
  };
  return (
    <>
      <Grid item xs={6} />
      <Grid item xs={2}>
        <strong>*Print Date (From) : </strong>
      </Grid>
      <Grid item xs={3}>
        <DatePickerField
          id="input_printDateFrom"
          fullWidth={true}
          onChange={e =>
            setForm(old => ({
              ...old,
              printDateFrom: e,
            }))
          }
          value={form.printDateFrom}
          required
        />
      </Grid>
      <Grid item xs={1} />

      <Grid item xs={2}>
        <strong>Print Date (To) : </strong>
      </Grid>
      <Grid item xs={3}>
        <DatePickerField
          id="input_printDateTo"
          fullWidth={true}
          onChange={e =>
            setForm(old => ({
              ...old,
              printDateTo: e,
            }))
          }
          value={form.printDateTo}
        />
      </Grid>
      <Grid item xs={1} />

      <Grid item xs={2}>
        <strong>R-Module Barcode ID : </strong>
      </Grid>
      <Grid item xs={3}>
        <InputText
          fullWidth
          id="input_barcodeId"
          value={form?.barcodeId}
          onChange={e => {
            setForm(old => ({
              ...old,
              barcodeId: e.target.value.match(/^[a-zA-Z0-9]*$/) ? e.target.value.toUpperCase() : old.barcodeId,
            }));
          }}
          maxLength={14}
        />
      </Grid>

      <Grid item xs={12} display="flex" justifyContent="flex-end" marginTop="24px">
        <InputButton value="Search" onClick={handleSearch} />
        <InputButton
          value="Clear"
          onClick={handleClear}
          onKeyDown={e => {
            if (!onSearch && e.key === "Tab") {
              e.preventDefault();
              document.getElementById("input_printDateFrom").focus();
            }
          }}
        />
      </Grid>
      <Grid item xs={12}>
        <Divider sx={{ my: 2 }} />
      </Grid>
      <Grid item xs={12}>
        <DataTable
          mode={mode}
          onSearch={onSearch}
          rowSelectionModel={rowSelectionModel}
          setRowSelectionModel={setRowSelectionModel}
          setMode={setMode}
          rows={rows}
          rowModesModel={rowModesModel}
          columns={columns}
          setRowModesModel={setRowModesModel}
          // processRowUpdate={processRowUpdate}
          pagination={pagination}
          pageNumber={pageNumber}
          handleChangePagination={handleChangePagination}
          columnGroupingModel={null}
          columnVisibilityModel={
            {
              // currencys: false,
              // rPkgTypeId: false,
            }
          }
          checkboxSelection={false}
          rowHeight={60}
          rowHeightAuto={false}
        />
      </Grid>
    </>
  );
};

export default HistoryMode;
