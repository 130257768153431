import { useMutation as useCustomMutation } from "@tanstack/react-query";
import axios from "configs/axiosConfig";
import apiClient from "utils/api-client";
import { useLoadingContext } from "shared/hooks/LoadingContext";

const httpMethods = {
  GET: apiClient.get,
  POST: apiClient.post,
  PUT: apiClient.put,
  PATCH: apiClient.patch,
  DELETE: apiClient.delete,
};

const trimValue = (value) => {
  if (typeof value === "string") {
    return value.trim();
  }
  if (Array.isArray(value)) {
    return value.map(trimValue);
  }
  if (typeof value === "object" && value !== null) {
    return Object.fromEntries(
      Object.entries(value).map(([key, val]) => [key, trimValue(val)])
    );
  }
  return value;
};

const useMutation = ({
  url,
  method = "POST",
  key,
  enabled = false,
  overRideUrl = false,
}) => {
  const { startLoading, stopLoading } = useLoadingContext();

  return useCustomMutation({
    mutationFn: async (postData) => {
      startLoading();
      try {
        const trimmedData = trimValue(postData);

        if (method === "DELETE") {
          if (overRideUrl) {
            const { data } = await axios.delete(url, {
              data: trimmedData,
            });
            return data;
          }
          const { data } = await httpMethods[method](url, {
            data: trimmedData,
          });
          return data;
        }

        if (method === "GET") {
          if (overRideUrl) {
            const { data } = await axios.get(url, {
              params: trimmedData,
            });
            return data;
          }
          const { data } = await apiClient.get(url, {
            params: trimmedData,
          });
          return data;
        }

        if (method === "POST") {
          if (overRideUrl) {
            const { data } = await axios.post(url, trimmedData);
            return data;
          }
        }

        const { data } = await httpMethods[method](url, trimmedData);
        return data;
      } finally {
        stopLoading();
      }
    },
    onSuccess: (data) => {
      console.log("Mutation successful:", data);
      stopLoading();
    },
    onError: (error) => {
      console.log("Mutation error:", error);
      stopLoading();
      throw error;
    },
    mutationKey: key,
    enabled: enabled,
  });
};

export default useMutation;
