import { useQuery as useReactQuery } from "@tanstack/react-query";
import axios from "configs/axiosConfig";
import { useLoadingContext } from "shared/hooks/LoadingContext";
import apiClient from "utils/api-client";

const httpMethods = {
  GET: apiClient.get,
  POST: apiClient.post,
  PUT: apiClient.put,
  PATCH: apiClient.patch,
  DELETE: apiClient.delete,
};

const trimValue = (value) => {
  if (typeof value === "string") {
    return value.trim();
  }
  if (Array.isArray(value)) {
    return value.map(trimValue);
  }
  if (typeof value === "object" && value !== null) {
    return Object.fromEntries(
      Object.entries(value).map(([key, val]) => [key, trimValue(val)])
    );
  }
  return value;
};

const useQuery = ({
  url,
  method = "GET",
  data = {},
  params = {},
  enabled = false,
  select = undefined,
  keepPreviousData = false,
  overRideUrl = false,
}) => {
  const { startLoading, stopLoading } = useLoadingContext();

  if (!httpMethods[method]) {
    throw new Error(`Unsupported method: ${method}`);
  }

  const queryKey = [url, method, params, enabled];

  const query = useReactQuery({
    queryKey: queryKey,
    queryFn: async () => {
      startLoading();
      try {
        const queryUrl = new URL(
          overRideUrl
            ? url
            : process.env.REACT_APP_API_URL +
              process.env.REACT_APP_API_PREFIX +
              url
        );

        Object.entries(params).forEach(([key, value]) => {
          queryUrl.searchParams.append(key, trimValue(value));
        });

        const trimmedData = trimValue(data);

        let axiosClient = await httpMethods[method](
          queryUrl,
          trimmedData || {}
        );
        if (overRideUrl) {
          axiosClient = await axios.get(url, trimmedData || {});
        }

        const response = axiosClient;

        if (select) {
          return select(response.data);
        }
        return response.data;
      } finally {
        stopLoading();
      }
    },
    enabled: enabled,
    keepPreviousData: keepPreviousData,
  });

  return { ...query, refetch: query.refetch };
};

export default useQuery;
