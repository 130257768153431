import React, { Fragment, useEffect, useState } from "react";
import { InputButton } from "components/UI";
//UTIL
import { messageDisplay, responseDownloadFileErrors, responseErrors } from "utils";
import { validationSearchForm } from "utils/validation";
//Hooks
import { useConfirmDialogContext } from "context/confirmDialogContext";
// TYPE
import { MessageType, ModeAction, MSG_TYPE } from "state/enum";
import { BUTTON_VALUE, API_ON_DEMAND_BATCH } from "shared/constants";
import { useOnDemandBatchDownloadMutation, useOnDemandBatchExecuteMutation } from "shared/services/common/monitoring";

export default function ActionTableBar(props) {
  const {
    form,
    rows,
    mode,
    setMode,
    setRowSelectionModel,
    setRowModesModel,
    setAppId,
    setMsg: { setMsgError, setMsgAlert },
    rowSelection: { rowSelectionModel },
  } = props;
  const [executeBtn, setExecuteBtn] = useState(true);

  const confirmDialogCtx = useConfirmDialogContext();
  const executeOnDemand = useOnDemandBatchExecuteMutation();
  const downloadOnDemand = useOnDemandBatchDownloadMutation();

  useEffect(() => {
    if (mode === ModeAction.SELECTED) {
      setExecuteBtn(false);
    } else if (mode === ModeAction.VIEW) {
      setExecuteBtn(true);
    }
  }, [mode]);
  const handleExecuteBtn = async () => {
    setAppId(null);
    const confirm = await confirmDialogCtx.success({ type: "COMMON_ON_DEMAND_BATCH_CONFIRM_EXECUTE" });
    if (!confirm) {
      return;
    }
    setMsgError([]);
    setMsgAlert([]);
    try {
      if (!rowSelectionModel.length) {
        const msg = messageDisplay({ type: MSG_TYPE.ERROR, code: "MSTD1016AERR", msg: [""] });
        setMsgError([msg]);
        return;
      }
      const selectedNo = rowSelectionModel[0];

      const row = rows.find(v => v.rowNumber === selectedNo);
      const body = {
        [API_ON_DEMAND_BATCH.DATA_OWNER]: form?.dataOwner,
        [API_ON_DEMAND_BATCH.PROJECT_CODE]: row?.projectCode,
        [API_ON_DEMAND_BATCH.BATCH_ID]: row?.batchId,
        [API_ON_DEMAND_BATCH.USER_ID]: form?.userId,
      };
      const { isSuccess, errors } = validationSearchForm({
        data: body,
        rule: [
          {
            field: API_ON_DEMAND_BATCH.PROJECT_CODE,
            type: MessageType.EMPTY,
            key: ["Project Code"],
          },
          {
            field: API_ON_DEMAND_BATCH.BATCH_ID,
            type: MessageType.EMPTY,
            key: ["Batch ID"],
          },
        ],
      });
      if (!isSuccess) {
        setMsgError(errors);
        window.scrollTo(0, 0);
        return;
      }
      const response = await executeOnDemand(body);
      const msg = messageDisplay({ type: MSG_TYPE.INFO, code: "MDN92015A", msg: [`Batch ID '${row?.batchId}'`] });
      setMsgAlert([msg]);
      setAppId(response?.result?.appId);
      setMode(ModeAction.VIEW);
      setRowSelectionModel([]);
      setRowModesModel({});
      window.scrollTo(0, 0);
      return;
    } catch (error) {
      const errors = responseErrors(error);
      setMsgError([`${errors}`]);
      return;
    }
  };
  const handleDownloadBtn = async () => {
    try {
      setMsgError([]);
      setMsgAlert([]);
      // const fileName = `${form?.projectCode}_${getLocalDate().format("YYYYMMDDHHmmss")}`;
      const body = {
        [API_ON_DEMAND_BATCH.DATA_OWNER]: form?.dataOwner,
        [API_ON_DEMAND_BATCH.PROJECT_CODE]: form?.projectCode,
        [API_ON_DEMAND_BATCH.BATCH_ID]: form?.batchId,
      };

      await downloadOnDemand(body);
      const msg = messageDisplay({ type: MSG_TYPE.INFO, code: "MSTD0097AINF", msg: [""] });
      setMsgAlert([msg]);
      window.scrollTo(0, 0);
      return;
    } catch (error) {
      console.log(error);
      const errors = responseDownloadFileErrors(error);
      setMsgError(errors);
      return;
    }
  };
  return (
    <Fragment>
      <InputButton id="btn-execute" value={BUTTON_VALUE.EXECUTE} disabled={executeBtn} onClick={handleExecuteBtn} />
      <InputButton id="btn-download" value={BUTTON_VALUE.DOWNLOAD} disabled={false} onClick={handleDownloadBtn} />
    </Fragment>
  );
}
