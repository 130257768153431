import { saveAs } from "utils/download-file";
import { useMutateData, useCustomQuery, useMutateDownload } from "shared/services/base.service";
const BaseServicePath = process.env.REACT_APP_API_STANDARD;
const LogMonitoringApiService = () => ({
  // GET MODULE
  useLogMonitorGetModuleQuery: () => {
    const data = useCustomQuery({
      url: `${BaseServicePath}/category/log-monitoring/module`,
      method: "GET",
      enabled: true,
      select: data => data.result,
      keepPreviousData: true,
    });
    return data;
  },
  // GET FUNCTION
  useLogMonitorGetFunctionQuery: moduleId => {
    const data = useCustomQuery({
      url: `${BaseServicePath}/category/log-monitoring/function/${moduleId}`,
      method: "GET",
      enabled: true,
      select: data => data.result,
      keepPreviousData: true,
    });
    return data;
  },
  // GET LEVEL
  useLogMonitorGetLevelQuery: () => {
    const data = useCustomQuery({
      url: `${BaseServicePath}/category/log-monitoring/level`,
      method: "GET",
      enabled: true,
      select: data => data.result,
      keepPreviousData: true,
    });
    return data;
  },
  // POST SUMMARY SEARCH
  useLogSummarySearchMutation: () => {
    const { mutate } = useMutateData({
      invalidateKeys: [],
    });
    return async searchData => {
      return await mutate({
        url: `${BaseServicePath}/log-monitoring/summary/search`,
        method: "POST",
        data: searchData,
      });
    };
  },
  // POST DETAIL SEARCH
  useLogDetailSearchMutation: () => {
    const { mutate } = useMutateData({
      invalidateKeys: [],
    });
    return async searchData => {
      return await mutate({
        url: `${BaseServicePath}/log-monitoring/detail/search`,
        method: "POST",
        data: searchData,
      });
    };
  },
  //POST DOWNLOAD EXCEL
  useLogMonitorDownloadExcelMutation: () => {
    const { mutate } = useMutateDownload({
      invalidateKeys: [],
    });
    return async (body) => {
      return await mutate(
        {
          url: `${BaseServicePath}/log-monitoring/detail/download/excel`,
          method: "POST",
          data: body,
          responseType: "arraybuffer",
        },
        {
          onSuccess: response => {
            saveAs(response);
            // const blob = new Blob([data], {
            //   type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
            // });
            // (blob, fileName);
          },
        }
      );
    };
  },
});
export const {
  useLogMonitorGetModuleQuery,
  useLogMonitorGetFunctionQuery,
  useLogMonitorGetLevelQuery,
  useLogDetailSearchMutation,
  useLogSummarySearchMutation,
  useLogMonitorDownloadExcelMutation,
} = LogMonitoringApiService();
