import React from "react";
import { Box } from "@mui/material";
import { InputButton, EditableDropDown, EditableDatePicker, EditableTextField } from "components/UI";
import { COLUMN_SHIPPING_INFO } from "../constants/table";
import { ModeAction } from "state/enum";
import { API_INVOICE_SHIPPING_INFO } from "shared/constants/api-name/invoice/shipmentInfoMain";
import { findObject } from "utils";
import { isEmpty } from "lodash";
export const useColumn = ({
  rows,
  mode,
  // setValueFieldTable,
  handleValueFieldTable,
  handleOpenContainerSubScreen,
  dataList: { exporterList, importerList, loadingPortList, dischargingPortList, consigneeList, brokerList },
}) => {
  return [
    // Col 1
    {
      field: API_INVOICE_SHIPPING_INFO.ROW_NUMBER,
      headerName: COLUMN_SHIPPING_INFO.NO,
      headerAlign: "center",
      align: "right",
      width: 50,
      sortable: false,
      editable: false,
      renderCell: params => {
        if (params?.row?.rowNumber === rows.slice(-1)?.[0]?.rowNumber && ModeAction.ADD === mode) {
          return <div></div>;
        }
        return params.value;
      },
    },
    // Col 2
    {
      field: API_INVOICE_SHIPPING_INFO.EXPORTER_CODE,
      headerName: COLUMN_SHIPPING_INFO.EXPORTER_CODE,
      headerAlign: "center",
      align: "center",
      minWidth: 150,
      flex: 0.1,
      sortable: false,
      editable: true,
      renderEditCell: params => {
        if (mode === ModeAction.ADD) {
          return (
            <EditableDropDown
              {...params}
              required
              value={params.value}
              autoFocus={true}
              onChange={e => {
                handleValueFieldTable(e.target.value, params?.field);
                params.api.setEditCellValue({
                  id: params.row.rowNumber,
                  field: API_INVOICE_SHIPPING_INFO.LOADING_PORT,
                  value: "",
                });
              }}
              defaultValue=""
              placeholder="<Select>"
              menu={exporterList?.map(val => ({ key: val?.impExpCd, value: val?.impExpCd }))}
            />
          );
        }
        return params.value;
      },
    },
    // Col 2
    {
      field: API_INVOICE_SHIPPING_INFO.IMPORTER_COMPANY_ID,
      headerName: COLUMN_SHIPPING_INFO.IMPORTER_COMPANY,
      headerAlign: "center",
      align: "left",
      minWidth: 150,
      flex: 0.1,
      sortable: false,
      editable: true,
      renderCell: params => {
        return params.row.impCompanyAbbr;
      },
      renderEditCell: params => {
        if (mode === ModeAction.ADD) {
          return (
            <EditableDropDown
              {...params}
              defaultValue=""
              value={params.row.impCompanyAbbrId}
              onChange={e => {
                handleValueFieldTable(e.target.value, params?.field);
                params.api.setEditCellValue({
                  id: params.row.rowNumber,
                  field: API_INVOICE_SHIPPING_INFO.DISCHARGING_PORT,
                  value: "",
                });
              }}
              required
              menu={importerList?.map(val => ({ key: val?.companyId, value: val?.companyAbbr }))}
              placeholder="<Select>"
            />
          );
        }
        return params.row.impCompanyAbbr;
        // return params.value;
      },
    },
    { field: API_INVOICE_SHIPPING_INFO.IMPORTER_COMPANY, editable: false },
    // Col 3
    {
      field: API_INVOICE_SHIPPING_INFO.IMPORTER_CODE,
      headerName: COLUMN_SHIPPING_INFO.IMPORTER_CODE,
      headerAlign: "center",
      align: "left",
      minWidth: 100,
      flex: 0.1,
      sortable: false,
      editable: false,
    },
    // Col 4
    {
      field: API_INVOICE_SHIPPING_INFO.ETD_DATE,
      headerName: COLUMN_SHIPPING_INFO.ETD,
      headerAlign: "center",
      align: "center",
      minWidth: 200,
      flex: 0.1,
      sortable: false,
      editable: true,
      renderEditCell: params => {
        if (mode === ModeAction.ADD) {
          return <EditableDatePicker {...params} required />;
        }
        return params.value;
      },
    },
    // Col 5
    {
      field: API_INVOICE_SHIPPING_INFO.CONSIGNEE_ID,
      headerName: COLUMN_SHIPPING_INFO.CONSIGNEE,
      headerAlign: "center",
      align: "center",
      minWidth: 150,
      flex: 0.1,
      sortable: false,
      editable: true,
      renderCell: params => {
        return params.row.consignee;
      },
      renderEditCell: params => {
        if (mode === ModeAction.ADD) {
          return (
            <EditableDropDown
              {...params}
              defaultValue=""
              value={params?.row?.consigneeId}
              placeholder="<Select>"
              menu={consigneeList?.map(val => ({ key: val?.cd, value: val?.value }))}
            />
          );
        } else if (mode === ModeAction.EDIT) {
          return (
            <EditableDropDown
              {...params}
              autoFocus={true}
              defaultValue=""
              placeholder="<Select>"
              value={params?.row?.consigneeId}
              onChange={e => {
                if (isEmpty(e.target.value)) {
                  params.api.setEditCellValue({
                    id: params?.row?.rowNumber,
                    field: API_INVOICE_SHIPPING_INFO.CONSIGNEE,
                    value: "",
                  });
                  return;
                }
                params.api.setEditCellValue({
                  id: params.row.rowNumber,
                  field: API_INVOICE_SHIPPING_INFO.CONSIGNEE,
                  value: findObject({ data: consigneeList, value: e.target.value, property: "cd", field: "value" }),
                });
                return;
              }}
              menu={consigneeList?.map(val => ({ key: val?.cd, value: val?.value }))}
            />
          );
        }
        return params.row.consignee;
      },
    },
    { field: API_INVOICE_SHIPPING_INFO.CONSIGNEE },
    // Col 5
    {
      field: API_INVOICE_SHIPPING_INFO.LOADING_PORT,
      headerName: COLUMN_SHIPPING_INFO.LOADING_PORT,
      headerAlign: "center",
      align: "left",
      minWidth: 200,
      flex: 0.1,
      sortable: false,
      editable: true,
      renderEditCell: params => {
        if (mode === ModeAction.ADD) {
          return (
            <EditableDropDown
              {...params}
              required
              defaultValue=""
              placeholder="<Select>"
              menu={loadingPortList?.map(val => ({ key: val?.cd, value: val?.value }))}
            />
          );
        } else if (mode === ModeAction.EDIT) {
          return (
            <EditableDropDown
              {...params}
              required
              value={params?.value}
              defaultValue=""
              placeholder="<Select>"
              menu={loadingPortList?.map(val => ({ key: val?.cd, value: val?.value }))}
            />
          );
        }
        return params.value;
      },
    },
    // Col 6
    {
      field: API_INVOICE_SHIPPING_INFO.DISCHARGING_PORT,
      headerName: COLUMN_SHIPPING_INFO.DISCHARGING_PORT,
      headerAlign: "center",
      align: "left",
      minWidth: 200,
      flex: 0.1,
      sortable: false,
      editable: true,
      renderEditCell: params => {
        if (mode === ModeAction.ADD) {
          return (
            <Box width={"90%"}>
              <EditableDropDown
                {...params}
                required
                defaultValue=""
                placeholder="<Select>"
                menu={dischargingPortList?.map(val => ({ key: val?.cd, value: val?.value }))}
              />
            </Box>
          );
        } else if (mode === ModeAction.EDIT) {
          return (
            <Box width={"90%"}>
              <EditableDropDown
                {...params}
                required
                defaultValue=""
                value={params?.value}
                placeholder="<Select>"
                menu={dischargingPortList?.map(val => ({ key: val?.cd, value: val?.value }))}
              />
            </Box>
          );
        }
        return params.value;
      },
    },
    // Col 7
    {
      field: API_INVOICE_SHIPPING_INFO.VESSEL_NAME,
      headerName: COLUMN_SHIPPING_INFO.VESSEL_NAME,
      headerAlign: "center",
      align: "left",
      minWidth: 250,
      flex: 0.1,
      sortable: false,
      editable: true,
      renderEditCell: params => {
        if (mode === ModeAction.ADD) {
          return <EditableTextField {...params} required defaultValue="" maxLength={40} />;
        } else if (mode === ModeAction.EDIT) {
          return <EditableTextField value={params?.value} {...params} required defaultValue="" />;
        }
        return params.value;
      },
    },
    // Col 8
    {
      field: API_INVOICE_SHIPPING_INFO.BROKER,
      headerName: COLUMN_SHIPPING_INFO.BROKER,
      headerAlign: "center",
      align: "left",
      minWidth: 150,
      flex: 0.1,
      sortable: false,
      editable: true,
      renderEditCell: params => {
        if (mode === ModeAction.ADD) {
          return (
            <Box width={"90%"}>
              <EditableDropDown
                {...params}
                required
                value={params.value}
                defaultValue=""
                placeholder="<Select>"
                menu={brokerList?.map(val => ({ key: val?.cd, value: val?.value }))}
              />
            </Box>
          );
        } else if (mode === ModeAction.EDIT) {
          return (
            <Box width={"90%"}>
              <EditableDropDown
                {...params}
                required
                defaultValue=""
                placeholder="<Select>"
                value={params?.value}
                menu={brokerList?.map(val => ({ key: val?.value, value: val?.value }))}
              />
            </Box>
          );
        }
        return params.value;
      },
    },
    // Col 9
    {
      field: API_INVOICE_SHIPPING_INFO.ORDER_TYPE,
      headerName: COLUMN_SHIPPING_INFO.ORDER_TYPE,
      headerAlign: "center",
      align: "left",
      minWidth: 100,
      flex: 0.1,
      sortable: false,
      editable: false,
    },
    // Col 10
    {
      field: API_INVOICE_SHIPPING_INFO.CAR_FAMILY_CODE,
      headerName: COLUMN_SHIPPING_INFO.CAR_FAMILY_CODE,
      headerAlign: "center",
      align: "left",
      minWidth: 100,
      flex: 0.1,
      sortable: false,
      editable: false,
    },
    // Col 11
    {
      field: API_INVOICE_SHIPPING_INFO.VANNING_STATUS,
      headerName: COLUMN_SHIPPING_INFO.VANNING_STATUS,
      headerAlign: "center",
      align: "left",
      minWidth: 100,
      flex: 0.1,
      sortable: false,
      editable: false,
    },
    // Col 12
    {
      field: "containerDetail",
      headerName: COLUMN_SHIPPING_INFO.CONTAINER_DETAIL,
      headerAlign: "center",
      align: "center",
      minWidth: 150,
      flex: 0.1,
      sortable: false,
      editable: true,
      renderCell: params => {
        return (
          <Box width={"100%"} sx={{ display: "flex", justifyContent: "center" }}>
            <InputButton
              value="Detail"
              onClick={() => handleOpenContainerSubScreen(params.row)}
              disabled={[ModeAction.ADD, ModeAction.EDIT].includes(mode)}
            />
          </Box>
        );
      },
      renderEditCell: params => {
        if (mode === ModeAction.ADD) {
          return (
            <Box width={"100%"} sx={{ display: "flex", justifyContent: "center" }}>
              <InputButton value="Detail" onClick={() => handleOpenContainerSubScreen(params.row)} disabled />
            </Box>
          );
        } else if (mode === ModeAction.EDIT) {
          return (
            <Box width={"100%"} sx={{ display: "flex", justifyContent: "center" }}>
              <InputButton value="Detail" onClick={() => handleOpenContainerSubScreen(params.row)} />
            </Box>
          );
        }
      },
    },
    // Col 13
    {
      field: API_INVOICE_SHIPPING_INFO.SHIPPING_INFO_H_ID,
      editable: false,
    },
    // Col 14
    {
      field: API_INVOICE_SHIPPING_INFO.VANNING_INFO_H_ID,
      editable: false,
    },
  ];
};
