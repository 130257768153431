import { useMutateData, useMutateDownload } from "shared/services/base.service";
import { saveAs } from "utils/download-file";
const BaseServicePath = process.env.REACT_APP_API_INVENTORY_FORECAST;

const GroupingApiService = () => ({
  //POST CREATE
  useMappingCreateMutation: () => {
    const { mutate } = useMutateData({
      invalidateKeys: [],
    });
    return async createData => {
      return await mutate({
        url: `${BaseServicePath}/shipment-management/mapping/create`,
        data: createData,
        method: "POST",
        headers: {
          "content-type": "multipart/form-data",
        },
      });
    };
  },
  //POST EDIT
  useMappingUpdateMutation: () => {
    const { mutate } = useMutateData({
      invalidateKeys: [],
    });
    return async (shipmentId, shipmentCd, updateData, hasFile) => {
      console.log('updateData --> ', updateData);
      return await mutate({
        url: `${BaseServicePath}/shipment-management/mapping/edit/${shipmentId}/${shipmentCd}`,
        data: updateData,
        method: "POST",
        // params: { fileUploadId: shipmentId, shipmentCD: shipmentCd },
        headers: {
          "content-type": hasFile ? "multipart/form-data" : 'application/json',
        },
      });
    };
  },
  //POST CANCEL
  useMappingCancelMutation: () => {
    const { mutate } = useMutateData({
      invalidateKeys: [],
    });
    return async (shipmentId, cancelData) => {
      return await mutate({
        url: `${BaseServicePath}/shipment-management/mapping/cancel/${shipmentId}`,
        data: cancelData,
        method: "DELETE",
      });
    };
  },
  //POST UPLOAD FILE
  useMappingUploadMutation: () => {
    const { mutate } = useMutateData({
      invalidateKeys: [],
    });
    return async (fileType, uploadData) => {
      return await mutate({
        url: `${BaseServicePath}/shipment-management/mapping/upload/${fileType}`,
        data: uploadData,
        method: "POST",
        headers: {
          "content-type": "multipart/form-data",
        },
      });
    };
  },
  //POST DOWNLOAD PDF
  useMappingExportPdfMutation: () => {
    const { mutate } = useMutateDownload({
      invalidateKeys: [],
    });
    return async ncvFileName => {
      return await mutate(
        {
          url: `${BaseServicePath}/pdf-files/${ncvFileName}`,
          method: "GET",
        },
        {
          onSuccess: response => {
            saveAs(response);
          },
        }
      );
    };
  },
});

export const {
  useMappingCreateMutation,
  useMappingUpdateMutation,
  useMappingCancelMutation,
  useMappingUploadMutation,
  useMappingExportPdfMutation,
} = GroupingApiService();
