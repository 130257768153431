import { Grid } from "@mui/material";
import { CheckBox, DropDown, InputButton } from "components/UI";
import { userProfile } from "constant";
import { useConfirmDialogContext } from "context/confirmDialogContext";
import dayjs from "dayjs";
import React from "react";
import { BUTTON_VALUE } from "shared/constants";
import {
  useExportInvoiceCancelInvoiceMutation,
  useExportInvoiceDownloadExcelMutation,
} from "shared/services/invoice";
import { ModeAction, MSG_TYPE } from "state/enum";
import {
  formatDate,
  formatDateToSave,
  message,
  messageDisplay,
  responseDownloadFileErrors,
  responseErrors,
  responseSuccess,
} from "utils";
import { getLocalDate } from "utils/init-config-date";
import _ from "lodash";

export default function ButtonAction(props) {
  const {
    form,
    setForm,
    mode,
    setMsg: { setMsgAlert, setMsgError },
    setOpenPopupSub,
    setOpenPopupCancelSub,
    dataList: { reportFormatData },
    rowSelection: {
      rowModesModel,
      setRowModesModel,
      rowSelectionModel,
      setRowSelectionModel,
    },
    rows,
    onSearch,
    refreshRowsTable,
  } = props;

  const confirmDialogCtx = useConfirmDialogContext();

  const downloadExcel = useExportInvoiceDownloadExcelMutation();
  const cancelInvoice = useExportInvoiceCancelInvoiceMutation();

  const handleCheckTotalM3 = (e) => {
    setForm((prev) => ({
      ...prev,
      totalsM3Flag: e.target.checked,
    }));
  };

  const handleDownloadExcel = async (e) => {
    try {
      if (!rowSelectionModel.length) {
        alert(
          "MDN95005AERR: A record must be selected to execute Generate Report operation."
        );
        return;
      }

      const formattedData = rowSelectionModel.map((val) => {
        const row = rows.find((v) => v.rowNumber === val);
        return {
          dataOwner: userProfile.dataOwner,
          invoiceNo: row ? row.invNo : "",
          invoiceDt: row
            ? `${row.invDt.split("/")[2]}-${row.invDt.split("/")[1]}-${row.invDt.split("/")[0]}`
            : "",
          expCd: row ? row.expCd : "",
          impCd: row ? row.impCompanyCd : "",
        };
      });

      // const filename = `Export_Invoice_Creation_${getLocalDate().format("YYYYMMDDHHmm")}.${form?.reportFormatCd === "PDF" ? "pdf" : "xlsx"}`;
      const body = {
        dataOwner: userProfile.dataOwner,
        invoiceNo: formattedData.map((v) => v.invoiceNo),
        fileType: _.isEmpty(form?.reportFormatCd)
          ? ""
          : form?.reportFormatCd === "PDF"
            ? "PDF"
            : "Excel",
        invoiceDt: formattedData.map((v) => v.invoiceDt),
        expCd: formattedData.map((v) => v.expCd),
        impCd: formattedData.map((v) => v.impCd),
        isZip: false, //fix
        totalsM3Flag: form?.totalsM3Flag ? "Y" : "N",
      };
      await downloadExcel(body);
      setMsgError([]);
    } catch (error) {
      const errors = responseDownloadFileErrors(error);
      setMsgError(errors);
      return;
    }
  };

  const handleOpenModalCreateInvoice = (e) => {
    setOpenPopupSub({ open: true, isEdit: false, data: [] });
  };

  const handleOpenModalEditInvoice = (e) => {
    if (rowSelectionModel.length > 1) {
      setMsgError();
      alert(message({ type: "singleRecord" }));
      setRowSelectionModel([]);
      return;
    }
    if (!rowSelectionModel.length) {
      const msg = messageDisplay({
        type: MSG_TYPE.ERROR,
        code: "MSTD1016AERR",
        msg: [""],
      });
      setMsgError([msg]);
      return;
    }
    const selectedNo = rowSelectionModel[0];
    const row = rows.find((v) => v.rowNumber === selectedNo);

    setMsgError([]);
    setOpenPopupSub({ open: true, isEdit: true, data: row });
  };

  const handleOpenModalCancelVanningResult = (e) => {
    setOpenPopupCancelSub(true);
  };

  const handleCancelInvoice = async (e) => {
    setMsgError([]);
    setMsgAlert([]);
    if (!rowSelectionModel.length) {
      alert(
        "MDN95008AERR: A record must be selected to execute Cancel Invoice operation."
      );
      return;
    }
    if (rowSelectionModel.length > 1) {
      setMsgError();
      alert(message({ type: "singleRecord" }));
      setRowSelectionModel([]);
      return;
    }
    const confirm = await confirmDialogCtx.success({
      msg: "MDN95032ACFM: Do you want to confirm to Cancel Invoice?",
    });
    if (!confirm) {
      return;
    }

    try {
      if (!rowSelectionModel.length) {
        const msg = messageDisplay({
          type: MSG_TYPE.ERROR,
          code: "MDN90024AERR",
          msg: [""],
        });
        setMsgError([msg]);
        return;
      }
      const selectedNo = rowSelectionModel[0];
      const row = rows.find((v) => v.rowNumber === selectedNo);

      const body = {
        dataOwner: userProfile?.dataOwner,
        loginUserCompanyAbbr: userProfile?.dataOwner,
        userId: userProfile.userId,
      };

      const nonCommInvHId = row.nonCommInvHId;
      const response = await cancelInvoice(nonCommInvHId, body);
      const msgDelete = responseSuccess(response);
      setMsgAlert([msgDelete]);

      // //Search new data
      refreshRowsTable();
    } catch (error) {
      console.log(error);
      const errors = responseErrors(error);
      setMsgError([`${errors}`]);
      return;
    }
  };

  return (
    <Grid container alignItems="center" spacing={2}>
      <Grid item xs={7}>
        {onSearch && (
          <Grid container alignItems="center" spacing={1}>
            <Grid item xs={3}>
              <strong>* Report Format :</strong>
            </Grid>
            <Grid item xs={3}>
              <DropDown
                id="select-report-format"
                fullWidth
                required
                defaultValue=""
                placeholder="<Select>"
                value={form?.reportFormatCd}
                onChange={(e) =>
                  setForm((old) => ({
                    ...old,
                    reportFormatCd: e.target.value,
                  }))
                }
                menu={reportFormatData?.map((val) => ({
                  key: val?.cd,
                  value: val?.value,
                }))}
                disabled={[ModeAction.ADD, ModeAction.EDIT].includes(mode)}
              />
            </Grid>
            <Grid item xs={6}>
              <InputButton
                id="generate-reports"
                value={BUTTON_VALUE.GENERATE_REPORTS}
                onClick={handleDownloadExcel}
              />
              <CheckBox
                id="checkbox-totals-m3-flag"
                placement=""
                label="Total M3"
                sx={{
                  color: "#000",
                  fontFamily: "Kanit",
                  fontWeight: "bold",
                  ml: 1,
                }}
                checked={form?.totalsM3Flag}
                onChange={(e) => handleCheckTotalM3(e)}
              />
            </Grid>
          </Grid>
        )}
      </Grid>
      <Grid item xs={5}>
        <Grid container alignItems="flex-end" direction={"row"} spacing={1}>
          <Grid item xs={onSearch ? 2 : 4} />
          <Grid item xs={4} alignContent={"flex-end"}>
            <InputButton
              id="button-create-invoice"
              value={BUTTON_VALUE.CREATE_INVOICE}
              minWidth="100%"
              onClick={(e) => handleOpenModalCreateInvoice(e)}
            />
          </Grid>
          {onSearch && (
            <>
              <Grid item xs={2}>
                <InputButton
                  id="button-edit"
                  value={BUTTON_VALUE.EDIT}
                  minWidth="100%"
                  onClick={(e) => handleOpenModalEditInvoice(e)}
                />
              </Grid>
              <Grid item xs={4}>
                <InputButton
                  id="button-cancel-invoice"
                  value={BUTTON_VALUE.CANCEL_INVOICE}
                  minWidth="100%"
                  onClick={handleCancelInvoice}
                />
              </Grid>
            </>
          )}
          <Grid item xs={onSearch ? 12 : 4} justifyContent="end" display="flex">
            <InputButton
              id="button-cancel-vanning-result"
              value={BUTTON_VALUE.CANCEL_VANNING_RESULT}
              onClick={handleOpenModalCancelVanningResult}
              style={{ marginRight: 0 }}
              minWidth={onSearch ? null : "100%"}
              onKeyDown={(e) => {
                if (!onSearch && e.key === "Tab") {
                  e.preventDefault();
                  document.getElementById("select-importer").focus();
                }
              }}
            />
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
}
