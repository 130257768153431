/* eslint-disable react-hooks/rules-of-hooks */
import { Box, Grid } from "@mui/material";
import React, { Fragment, useEffect, useMemo, useState } from "react";
import { GridRowModes, useGridApiContext } from "@mui/x-data-grid";
import {
  InputText,
  InputDropDown,
  AlertMessage,
  DatePickerField,
  EditableDatePicker,
} from "components/UI/index";
import { MessageType, ModeAction } from "state/enum";
import { HeaderContentBar } from "components/Layout";
import ActionBar from "pages/Partials/ActionBar";
import {
  findObject,
  isPastDate,
  message,
  messageTypeDisplay,
  responseDownloadFileErrors,
  responseErrors,
} from "utils";
import SubmitBar from "pages/Partials/SubmitBar";
import DataTable from "pages/DataTable";
import { columEditTable } from "utils/columEditTable";
import {
  useCreateGentanI,
  useDeleteGentanI,
  useGetCarFamilyCodeTableDropDown,
  useGetImpExpCdDropDown,
  useGetImpExpCdTableDropDown,
  useGetRpkg,
  useGetRpkgTypeTableDropDown,
  useGetSubSeriesTableDropDown,
  useGetType,
  useSearchGentanI,
  useUpdateGentanI,
  useGentanIDownloadExcelMutation,
} from "service/gentan-i";
import { useConfirmDialogContext } from "context/confirmDialogContext";
import { userProfile } from "constant";
import {
  validationRequestErrors,
  validationSearchForm,
} from "utils/validation";
import { getLocalDate } from "utils/init-config-date";

const GentanIMasterScreen = () => {
  const confirmDialogCtx = useConfirmDialogContext();

  const [rows, setRows] = useState([]);
  const [rowModesModel, setRowModesModel] = useState({});
  const [rowSelectionModel, setRowSelectionModel] = useState([]);

  const [mode, setMode] = useState("view");

  const [searchForm, setSearchForm] = useState({
    gtiTypeId: "",
    expCode: "",
    impCode: "",
    rPkgTypeId: "",
    carFamilyCd: "",
    subSeriesCd: "",
    effDtFrom: "",
    effDtTo: "",
  });

  // 1. pagination
  const [pagination, setPagination] = useState({});
  const [pageNumber, setPageNumber] = useState(1);

  const handleChangePagination = async (event, value) => {
    setMsgError([]);
    setMsgAlert([]);

    setPageNumber(value);
    const { searchData, data } = await getSearch(value);

    setPagination(searchData?.result?.pagination ?? {});
    setPageNumber(searchData?.result?.pagination?.pageNumber);
    setRows(data);
  };

  const [onSearch, setOnSearch] = useState(false);

  const [msgError, setMsgError] = useState([]);
  const [msgAlert, setMsgAlert] = useState([]);

  useEffect(() => {
    document.getElementById("select_gtiTypeId").focus();
  }, []);

  // 2. api
  const downloadExcel = useGentanIDownloadExcelMutation();
  const { data: typeData } = useGetType({
    dataOwner: userProfile.dataOwner,
  });

  const { data: impCdData, refetch: refecthImpCdData } = useGetImpExpCdDropDown(
    {
      dataOwner: userProfile.dataOwner,
      type: "IMP_CD",
    }
  );

  const { data: expCdData, refetch: refetchExpCdData } = useGetImpExpCdDropDown(
    {
      dataOwner: userProfile.dataOwner,
      type: "EXP_CD",
    }
  );

  const { data: rPkgData, refetch: refetchRPkgData } = useGetRpkg({
    dataOwner: userProfile.dataOwner,
    rPkgOwnerCompAbbr: userProfile.rPkgOwnerCompAbbr,
  });

  const { data: impCdTableData } = useGetImpExpCdTableDropDown({
    dataOwner: userProfile.dataOwner,
    type: "I",
  });

  const { data: expCdTableData } = useGetImpExpCdTableDropDown({
    dataOwner: userProfile.dataOwner,
    type: "E",
  });

  const { data: rPkgTypeTableData } = useGetRpkgTypeTableDropDown({
    dataOwner: userProfile.dataOwner,
    rPkgOwnerCompAbbr: userProfile.rPkgOwnerCompAbbr,
  });

  const { data: carFamilyCodeTableData } = useGetCarFamilyCodeTableDropDown({
    dataOwner: userProfile.dataOwner,
  });

  const { data: subSeriesTableData } = useGetSubSeriesTableDropDown({
    dataOwner: userProfile.dataOwner,
  });

  const { mutateAsync: searchDataAsync } = useSearchGentanI({});
  const { mutateAsync: createDataAsync } = useCreateGentanI();
  const { mutateAsync: editDataAsync } = useUpdateGentanI({
    gentanIId: rows?.find((val) => val.rowNumber === rowSelectionModel[0])
      ?.gentanIId,
  });
  const { mutateAsync: deleteDataAsync } = useDeleteGentanI({
    gentanIId: rows?.find((val) => val.rowNumber === rowSelectionModel[0])
      ?.gentanIId,
  });

  const columns = useMemo(
    () => [
      {
        field: "rowNumber",
        sortable: false,
        headerName: "No",
        width: 50,
        editable: false,
        renderCell: (params) => {
          if (
            params.row.rowNumber === rows.slice(-1)?.[0].rowNumber &&
            ModeAction.ADD === mode
          ) {
            return <div></div>;
          }
          // return params.value;
          return params.value;
        },
      },
      columEditTable({
        field: "gentanITypeCd",
        headerName: "Gentan-I Type",
        editable: mode === ModeAction.ADD,
        width: 150,
        type: "dropdown",
        required: true,
        onFocus: true,
        inputOption: {
          data: typeData,
          property: "cd",
          field: "value",
          memu: typeData?.map((val) => ({
            key: val.cd,
            value: val.value,
          })),
          placeholder: "<Select>",
        },
        rule: [
          {
            type: MessageType.EMPTY,
            key: ["Gentan-I Type"],
          },
        ],
      }),
      columEditTable({
        field: "expCd",
        headerName: "Exporter Code",
        editable: mode === ModeAction.ADD,
        width: 150,
        type: "dropdown",
        required: true,
        inputOption: {
          data: expCdTableData,
          property: "impExpCd",
          field: "impExpCd",
          memu: expCdTableData?.map((val) => ({
            key: val.impExpCd,
            value: val.impExpCd,
          })),
          placeholder: "<Select>",
        },
        rule: [
          {
            type: MessageType.EMPTY,
            key: ["Exporter Code"],
          },
        ],
      }),
      columEditTable({
        field: "impCd",
        headerName: "Importer Code",
        editable: mode === ModeAction.ADD,
        width: 150,
        type: "dropdown",
        required: true,
        inputOption: {
          data: impCdTableData,
          property: "impExpCd",
          field: "impExpCd",
          memu: impCdTableData?.map((val) => ({
            key: val.impExpCd,
            value: val.impExpCd,
          })),
          placeholder: "<Select>",
        },
        rule: [
          {
            type: MessageType.EMPTY,
            key: ["Importer Code"],
          },
        ],
      }),
      columEditTable({
        field: "rPkgTypeId",
        headerName: "R-Package Type",
        editable: mode === ModeAction.ADD,
        width: 150,
        type: "dropdown",
        required: true,
        inputOption: {
          data: rPkgTypeTableData,
          property: "rPkgTypeId",
          field: "rPkgType",
          memu: rPkgTypeTableData?.map((val) => ({
            key: val.rPkgTypeId,
            value: val.rPkgType,
          })),
          placeholder: "<Select>",
        },
        rule: [
          {
            type: MessageType.EMPTY,
            key: ["R-Package Type"],
          },
        ],
      }),
      columEditTable({
        field: "carFamilyCd",
        headerName: "Car Family Code",
        editable: mode === ModeAction.ADD,
        width: 150,
        type: "dropdown",
        required: true,
        inputOption: {
          data: carFamilyCodeTableData,
          property: "cd",
          field: "cd",
          memu: carFamilyCodeTableData?.map((val) => ({
            key: val.cd,
            value: val.cd,
          })),
          placeholder: "<Select>",
        },
        rule: [
          {
            type: MessageType.EMPTY,
            key: ["Car Family Code"],
          },
        ],
      }),
      columEditTable({
        field: "subSeries",
        headerName: "Sub Series",
        editable: mode === ModeAction.ADD,
        width: 150,
        type: "dropdown",
        required: true,
        inputOption: {
          data: subSeriesTableData,
          property: "cd",
          field: "cd",
          memu: subSeriesTableData?.map((val) => ({
            key: val.cd,
            value: val.cd,
          })),
          placeholder: "<Select>",
        },
        rule: [
          {
            type: MessageType.EMPTY,
            key: ["Sub Series"],
          },
        ],
      }),
      columEditTable({
        field: "value",
        headerName: "Gentan-I Value",
        width: 150,
        editable: true,
        type: "number",
        align: "right",
        maxLimit: 10000,
        decimalScale: 2,
        required: true,
        rule: [
          {
            type: MessageType.EMPTY,
            key: ["Gentan-I Value"],
          },
          {
            type: MessageType.MORETHAN_ZERO,
            key: ["Gentan-I Value", "0"],
          },
        ],
      }),

      {
        field: "effDtFrom",
        headerName: "Effective Date (From) (DD/MM/YYYY)",
        editable: true,
        width: 240,
        sortable: false,
        rule: [
          {
            type: MessageType.EMPTY,
            key: ["Effective Date (From)"],
          },
        ],
        renderEditCell: (params) => {
          return (
            <EditableDatePicker
              {...params}
              required
              disabled={
                mode !== ModeAction.ADD &&
                getLocalDate(params.formattedValue, "DD/MM/YYYY").isBefore(
                  getLocalDate(),
                  "day"
                )
              }
            />
          );
        },
        renderCell: (params) => params.value,
      },
      {
        field: "effDtTo",
        headerName: `Effective Date (To) \n(DD/MM/YYYY)`,
        editable: true,
        width: 240,
        sortable: false,
        renderEditCell: (params) => {
          return <EditableDatePicker {...params} />;
        },
        renderCell: (params) => params.value,
      },
    ],
    [rows]
  );

  const getSearch = async (pageNumber = 1) => {
    setOnSearch(true);
    const searchData = await searchDataAsync({
      dataOwner: userProfile.dataOwner,
      gantanITypeCd: searchForm.gtiTypeId,
      expCd: searchForm.expCode,
      impCd: searchForm.impCode,
      rPkgTypeId: searchForm.rPkgTypeId,
      carFamilyCd: searchForm.carFamilyCd,
      subSeriesCd: searchForm.subSeriesCd,
      effDtFrom: searchForm.effDtFrom,
      // effDtFrom: formatDateToSave(document.querySelector(".input_effDtFrom input").value, "DD/MM/YYYY"),
      effDtTo: searchForm.effDtTo,
      // effDtTo: formatDateToSave(document.querySelector(".input_effDtTo input").value, "DD/MM/YYYY"),
      pageNumber: pageNumber,
      rowsPerPage: 10,
    });

    const data =
      searchData?.result?.data?.map((item, index) => ({
        rowNumber: index + 1,
        ...item,
      })) ?? [];

    return { searchData, data };
  };

  const handleSearch = async (e) => {
    try {
      e.preventDefault();
      setRows([]);
      setMsgError([]);
      setMsgAlert([]);
      setRowSelectionModel([]);

      // validate

      const { isSuccess, errors } = validationSearchForm({
        data: searchForm,
        rule: [
          {
            field: "gtiTypeId",
            type: MessageType.EMPTY,
            key: ["Gentan-I Type"],
          },
          {
            field: "expCode",
            type: MessageType.EMPTY,
            key: ["Exporter Code"],
          },
        ],
      });

      if (!isSuccess) {
        setMsgError(errors);
        window.scrollTo(0, 0);
        return;
      }

      const { searchData, data } = await getSearch();
      if (!data?.length) {
        setMsgError([message({ type: "notFound" })]);
      }

      setPagination(searchData?.result?.pagination ?? {});
      setPageNumber(searchData?.result?.pagination?.pageNumber);
      setRows(data);
    } catch (error) {
      const errors = responseErrors(error);
      setMsgError(errors);
      window.scrollTo(0, 0);
      return;
    }
  };

  // 9. handleClear
  const handleClear = () => {
    setMode(ModeAction.VIEW);
    setMsgError([]);
    setMsgAlert([]);
    setRowSelectionModel([]);
    setOnSearch(false);
    setSearchForm((prev) => ({
      ...prev,
      gtiTypeId: "",
      expCode: "",
      impCode: "",
      rPkgTypeId: "",
      carFamilyCd: "",
      subSeriesCd: "",
      effDtFrom: "",
      effDtTo: "",
    }));
    setRows([]);
  };

  // 10. handleDownloadExcel
  const handleDownloadExcel = async () => {
    try {
      // validate
      setMsgError([]);
      setMsgAlert([]);
      try {
        const { data } = await getSearch();
        if (!data.length) {
          const msg = messageTypeDisplay(MessageType.NOT_FOUND);
          setMsgError((old) => [...old, msg]);
          return;
        }
      } catch (error) {
        const errors = responseErrors(error);
        setMsgError(errors);
        window.scrollTo(0, 0);
        return;
      }

      const option = {
        dataOwner: userProfile.dataOwner,
        gantanITypeCd: searchForm.gtiTypeId,
        expCd: searchForm.expCode,
        impCd: searchForm.impCode,
        rPkgType: searchForm.rPkgTypeId,
        carFamilyCd: searchForm.carFamilyCd,
        subSeriesCd: searchForm.subSeriesCd,
        effDtFrom: searchForm.effDtFrom,
        effDtTo: searchForm.effDtTo,
        pageNumber: pageNumber,
        rowsPerPage: 10,
      };
      // const filename = `Gentan_I_${getLocalDate().format("YYYYMMDDHHmm")}.xlsx`;
      const { isSuccess, errors } = validationSearchForm({
        data: option,
        rule: [
          {
            field: "gantanITypeCd",
            type: MessageType.EMPTY,
            key: ["Gentan-I Type"],
          },
          {
            field: "expCd",
            type: MessageType.EMPTY,
            key: ["Exporter Code"],
          },
        ],
      });

      if (!isSuccess) {
        setMsgError(errors);
        window.scrollTo(0, 0);
        return;
      }

      await downloadExcel(option);
      return;
    } catch (error) {
      const errors = responseDownloadFileErrors(error);
      setMsgError(errors);
    }
  };

  const handleDeleteClick = async () => {
    setMsgError([]);
    setMsgAlert([]);
    const confirm = await confirmDialogCtx.success({ type: "confirmDelete" });
    if (!confirm) {
      return;
    }

    // validate
    const newRow = rows.find((val) => val.rowNumber === rowSelectionModel[0]);
    if (getLocalDate().isAfter(newRow.effDtFrom, "days")) {
      setMsgError([
        "MSTD0017AERR: Data can not be deleted because effective date is in the past.",
      ]);
      window.scrollTo(0, 0);
      setMode(ModeAction.VIEW);
      setRowSelectionModel([]);
      return;
    }

    try {
      await deleteDataAsync({
        updateBy: userProfile.userId, // TODO: get dataOwner from profile
      });

      const { searchData, data } = await getSearch();
      if (data.length > 0) {
        setPagination(searchData?.result?.pagination ?? {});
        setPageNumber(searchData?.result?.pagination?.pageNumber);
        setRows(data);
      } else {
        setSearchForm((prev) => ({
          ...prev,
          // gtiTypeId: "",
          expCode: "",
          impCode: "",
          rPkgTypeId: "",
          carFamilyCd: "",
          subSeriesCd: "",
          effDtFrom: "",
          effDtTo: "",
        }));
        setRows([]);
      }
      setMode(ModeAction.VIEW);
      setRowSelectionModel([]);
      setRows(data);

      setMsgAlert([message({ type: "deleted" })]);

      refetchRPkgData();
      refetchExpCdData();
      refecthImpCdData();
    } catch (error) {
      const errors = responseErrors(error);
      setMsgError(errors);
      return;
    }
  };

  const handleCopy = () => {
    setMode(ModeAction.ADD);
    setMsgError([]);
    setMsgAlert([]);
    setOnSearch(true);

    const rowCopy = rows.find((v) => v.rowNumber === rowSelectionModel[0]);
    const maxNo =
      !rows || rows.length === 0
        ? 1
        : Math.max(...rows.map((item) => item.rowNumber));

    console.log("+++rows2", rows);
    console.log("+++rowCopy2", rowCopy);

    setRows((oldRows) => [
      ...oldRows,
      {
        ...rowCopy,
        effDtFrom: "",
        rowNumber: maxNo + 1,
        isNew: true,
      },
    ]);

    setRowModesModel((oldModel) => ({
      ...oldModel,
      [maxNo + 1]: { mode: GridRowModes.Edit },
    }));

    setRowSelectionModel([maxNo + 1]);
  };

  const onRowSelectionModelChange = (newRowSelectionModel) => {
    setMsgError([]);
    setMsgAlert([]);

    if (newRowSelectionModel?.length) {
      setMode(ModeAction.SELECTED);
    } else {
      setMode(ModeAction.VIEW);
    }
    setRowSelectionModel(newRowSelectionModel);
  };

  const processRowUpdate = async (newRow) => {
    // const effDtFromValue = document.querySelector('[data-field="effDtFrom"] input').value;
    // const effDtToValue = document.querySelector('[data-field="effDtTo"] input').value;

    setMsgError([]);
    setMsgAlert([]);

    if (newRow?.isNew) {
      // to do save
      try {
        // validate
        const { isSuccess, errors } = validationRequestErrors({
          columns: columns,
          data: newRow,
        });

        if (!isSuccess) {
          setMsgError((oldMsg) => [...oldMsg, ...errors]);
          window.scrollTo(0, 0);
          return;
        }

        await createDataAsync({
          dataOwner: userProfile.dataOwner,
          gantanITypeCd: newRow?.gentanITypeCd,
          expCompanyId: findObject({
            data: expCdTableData,
            value: newRow?.expCd,
            property: "impExpCd",
            field: "companyId",
          }),
          expCompanyAbbr: findObject({
            data: expCdTableData,
            value: newRow?.expCd,
            property: "impExpCd",
            field: "companyAbbr",
          }),
          expPlantId: findObject({
            data: expCdTableData,
            value: newRow?.expCd,
            property: "impExpCd",
            field: "plantId",
          }),
          expCd: newRow?.expCd,
          impCompanyId: findObject({
            data: impCdTableData,
            value: newRow?.impCd,
            property: "impExpCd",
            field: "companyId",
          }),
          impCompanyAbbr: findObject({
            data: impCdTableData,
            value: newRow?.impCd,
            property: "impExpCd",
            field: "companyAbbr",
          }),
          impPlantId: findObject({
            data: impCdTableData,
            value: newRow?.impCd,
            property: "impExpCd",
            field: "plantId",
          }),
          impCd: newRow?.impCd,
          rPkgTypeId: newRow?.rPkgTypeId,
          rPkgType:
            findObject({
              data: rPkgTypeTableData,
              value: newRow?.rPkgTypeId,
              property: "rPkgTypeId",
              field: "rPkgType",
            }) || newRow?.rPkgType,
          rPkgOwnerCompAbbr:
            findObject({
              data: rPkgTypeTableData,
              value: newRow?.rPkgTypeId,
              property: "rPkgTypeId",
              field: "rPkgOwnerCompAbbr",
            }) || newRow?.rPkgOwnerCompAbbr,
          carFamilyCd: newRow?.carFamilyCd,
          subSeries: newRow?.subSeries,
          value: parseFloat(newRow?.value.replace(",", "")),
          effDtFrom: newRow.effDtFrom,
          effDtTo: newRow.effDtTo,
          // effDtFrom: formatDateToSave(effDtFromValue),
          // effDtTo: formatDateToSave(effDtToValue),
          createBy: userProfile.userId,
        });

        const msg = messageTypeDisplay(MessageType.ADDED);
        setMsgAlert([msg]);
        window.scrollTo(0, 0);

        refetchRPkgData();
        refetchExpCdData();
        refecthImpCdData();

        if (rows.length === 1 && ModeAction.ADD === mode) {
          setMode(ModeAction.VIEW);
          setRowSelectionModel([]);
          setRows([]);
        } else {
          const { searchData, data } = await getSearch(pageNumber);

          setMode(ModeAction.VIEW);
          setRowSelectionModel([]);

          setPagination(searchData?.result?.pagination ?? {});
          setPageNumber(searchData?.result?.pagination?.pageNumber);
          setRows(data);
        }

        const updatedRow = {
          ...newRow,
          effDtFrom: newRow.effDtFrom,
          effDtTo: newRow.effDtTo,
          isNew: false,
        };
        return updatedRow;
      } catch (error) {
        console.log("error -->", error);
        const errors = responseErrors(error);
        setMsgError(errors);
        window.scrollTo(0, 0);
        return;
      }
    } else {
      // to do edit
      try {
        // validate
        const { isSuccess, errors } = validationRequestErrors({
          columns: columns,
          data: newRow,
        });

        if (!isSuccess) {
          setMsgError((oldMsg) => [...oldMsg, ...errors]);
          window.scrollTo(0, 0);
          return;
        }

        await editDataAsync({
          value: parseFloat(newRow?.value.replace(",", "")),
          effDtFrom: newRow.effDtFrom,
          effDtTo: newRow.effDtTo,
          updateBy: userProfile.userId, // TODO: get dataOwner from profile
        });

        const msg = messageTypeDisplay(MessageType.UPDATED);
        setMsgAlert([msg]);
        window.scrollTo(0, 0);

        if (rows.length === 1 && ModeAction.ADD === mode) {
          setMode(ModeAction.VIEW);
          setRowSelectionModel([]);
          setRows([]);
        } else {
          const { searchData, data } = await getSearch(pageNumber);

          setMode(ModeAction.VIEW);
          setRowSelectionModel([]);

          setPagination(searchData?.result?.pagination ?? {});
          setPageNumber(searchData?.result?.pagination?.pageNumber);
          setRows(data);
        }
        const updatedRow = {
          ...newRow,
          effDtFrom: newRow.effDtFrom,
          effDtTo: newRow.effDtTo,
          isNew: false,
        };
        return updatedRow;
      } catch (error) {
        const errors = responseErrors(error);
        setMsgError(errors);
        window.scrollTo(0, 0);
        return;
      }
    }
  };

  const handleEdit = async () => {
    setMsgError([]);
    setMsgAlert([]);
    // validate
    const newRow = rows.find((val) => val.rowNumber === rowSelectionModel[0]);
    if (
      getLocalDate().isAfter(getLocalDate(newRow.effDtTo, "DD/MM/YYYY"), "days")
    ) {
      const msg = messageTypeDisplay(
        MessageType.DELETE_EFFECTIVE_DATE_TO_PASS,
        ["delete or edit Gentan I,", newRow.effDtTo]
      );
      setMsgError([msg]);
      window.scrollTo(0, 0);
      setMode(ModeAction.VIEW);
      setRowSelectionModel([]);
      return;
    }

    setMode(ModeAction.EDIT);

    let tempRowModesModel = rowModesModel;

    const rowSelect = rowSelectionModel.map((val) => {
      tempRowModesModel = {
        ...tempRowModesModel,
        [val]: { mode: GridRowModes.Edit },
      };
    });

    if (rowSelect) {
      setRowModesModel(tempRowModesModel);
      // setRowSelectionModel()
    }
  };

  return (
    <Fragment>
      <Box sx={{ padding: "1rem" }}>
        <HeaderContentBar title="WDN91070 : Gentan-I Master Maintenance Screen" />

        <AlertMessage type={"warning"} message={msgError} />
        <AlertMessage type={"success"} message={msgAlert} />

        <Grid container spacing={0} sx={{ mt: 4, alignItems: "center" }}>
          <Grid item xs={2}>
            <strong>* Gentan-I Type : </strong>
          </Grid>
          <Grid item xs={2}>
            <InputDropDown
              id="select_gtiTypeId"
              required
              value={searchForm.gtiTypeId}
              onChange={(e) =>
                setSearchForm((old) => ({
                  ...old,
                  gtiTypeId: e.target.value,
                }))
              }
              memu={typeData?.map((val) => ({
                key: val.cd,
                value: val.value,
              }))}
              disabled={[ModeAction.ADD, ModeAction.EDIT].includes(mode)}
              placeholder="<Select>"
              defaultValue=""
            />
          </Grid>
          <Grid item xs={1}></Grid>
          <Grid item xs={2}>
            <strong>* Exporter Code : </strong>
          </Grid>
          <Grid item xs={2} sx={{ ml: 3 }}>
            <InputDropDown
              id="select_expCode"
              required
              value={searchForm.expCode}
              onChange={(e) =>
                setSearchForm((old) => ({
                  ...old,
                  expCode: e.target.value,
                }))
              }
              memu={expCdData?.map((val) => ({
                key: val.cd,
                value: val.cd,
              }))}
              disabled={[ModeAction.ADD, ModeAction.EDIT].includes(mode)}
              placeholder="<Select>"
              defaultValue=""
            />
          </Grid>
          <Grid item xs={2}></Grid>

          <Grid item xs={2} sx={{ mt: 2 }}>
            <strong>Importer Code : </strong>
          </Grid>
          <Grid item xs={2} sx={{ mt: 2 }}>
            <InputDropDown
              id="select_impCode"
              value={searchForm.impCode}
              onChange={(e) =>
                setSearchForm((old) => ({
                  ...old,
                  impCode: e.target.value,
                }))
              }
              memu={impCdData?.map((val) => ({
                key: val.cd,
                value: val.cd,
              }))}
              disabled={[ModeAction.ADD, ModeAction.EDIT].includes(mode)}
              placeholder="<All>"
              defaultValue=""
            />
          </Grid>
          <Grid item xs={1}></Grid>
          <Grid item xs={2} sx={{ mt: 2 }}>
            <strong>R-Package Type : </strong>
          </Grid>
          <Grid item xs={2} sx={{ mt: 2, ml: 3 }}>
            <InputDropDown
              id="select_rPkgTypeId"
              value={searchForm.rPkgTypeId}
              onChange={(e) =>
                setSearchForm((old) => ({
                  ...old,
                  rPkgTypeId: e.target.value,
                }))
              }
              memu={rPkgData?.map((val) => ({
                key: val.rPkgTypeId,
                value: val.rPkgType,
              }))}
              disabled={[ModeAction.ADD, ModeAction.EDIT].includes(mode)}
              placeholder="<All>"
              defaultValue=""
            />
          </Grid>
          <Grid item xs={2}></Grid>

          <Grid item xs={2}>
            <strong>Car Family Code : </strong>
          </Grid>
          <Grid item xs={2}>
            <InputText
              id="input_carFamCd"
              fullWidth={true}
              maxLength={4}
              value={searchForm.carFamilyCd}
              onChange={(e) =>
                setSearchForm((old) => ({
                  ...old,
                  carFamilyCd: e.target.value,
                }))
              }
              disabled={[ModeAction.ADD, ModeAction.EDIT].includes(mode)}
            />
          </Grid>
          <Grid item xs={1}></Grid>
          <Grid item xs={2}>
            <strong>Sub Series : </strong>
          </Grid>
          <Grid item xs={2} sx={{ ml: 3 }}>
            <InputText
              id="input_subSerCd"
              fullWidth={true}
              maxLength={10}
              value={searchForm.subSeriesCd}
              onChange={(e) =>
                setSearchForm((old) => ({
                  ...old,
                  subSeriesCd: e.target.value,
                }))
              }
              disabled={[ModeAction.ADD, ModeAction.EDIT].includes(mode)}
            />
          </Grid>
          <Grid item xs={2}></Grid>

          <Grid item xs={2}>
            <strong>Effective Date (From) : </strong>
          </Grid>
          <Grid item xs={2} sx={{ mt: 1 }}>
            <DatePickerField
              id="input_effDtFrom"
              className="input_effDtFrom"
              fullWidth={true}
              onChange={(e) =>
                setSearchForm((old) => ({
                  ...old,
                  effDtFrom: e,
                }))
              }
              value={searchForm.effDtFrom}
              disabled={[ModeAction.ADD, ModeAction.EDIT].includes(mode)}
            />
          </Grid>
          <Grid item xs={1}></Grid>
          <Grid item xs={2} sx={{ mt: 2 }}>
            <strong>Effective Date (To) :</strong>
          </Grid>
          <Grid item xs={2} sx={{ ml: 3 }}>
            <DatePickerField
              id="input_effDtTo"
              className="input_effDtTo"
              fullWidth={true}
              onChange={(e) =>
                setSearchForm((old) => ({
                  ...old,
                  effDtTo: e,
                }))
              }
              value={searchForm.effDtTo}
              disabled={[ModeAction.ADD, ModeAction.EDIT].includes(mode)}
            />
          </Grid>
        </Grid>
      </Box>

      <ActionBar
        mode={mode}
        rows={rows}
        columns={columns}
        setRows={setRows}
        setMode={setMode}
        setMsgError={setMsgError}
        setMsgAlert={setMsgAlert}
        setOnSearch={setOnSearch}
        setRowModesModel={setRowModesModel}
        setRowSelectionModel={setRowSelectionModel}
        rowSelectionModel={rowSelectionModel}
        rowModesModel={rowModesModel}
        handleSearch={handleSearch}
        handleClear={handleClear}
        handleDownloadExcel={handleDownloadExcel}
        handleDeleteClick={handleDeleteClick}
        handleCopy={handleCopy}
        handleEdit={handleEdit}
        firstField={"select_gtiTypeId"}
      />
      <Box sx={{ padding: "1rem" }}>
        <Grid container spacing={2}>
          {onSearch && (
            <DataTable
              mode={mode}
              onSearch={onSearch}
              rowSelectionModel={rowSelectionModel}
              setRowSelectionModel={setRowSelectionModel}
              setMode={setMode}
              rows={rows}
              rowModesModel={rowModesModel}
              columns={columns}
              setRowModesModel={setRowModesModel}
              processRowUpdate={processRowUpdate}
              pagination={pagination}
              pageNumber={pageNumber}
              handleChangePagination={handleChangePagination}
              columnGroupingModel={null}
              columnVisibilityModel={{
                currencys: false,
                // rPkgTypeId: false,
              }}
              onRowSelectionModelChange={onRowSelectionModelChange}
            />
          )}
          <SubmitBar
            mode={mode}
            rows={rows}
            setMode={setMode}
            setRows={setRows}
            setRowModesModel={setRowModesModel}
            setMsgError={setMsgError}
            rowModesModel={rowModesModel}
            rowSelectionModel={rowSelectionModel}
            setRowSelectionModel={setRowSelectionModel}
          />
        </Grid>
      </Box>
    </Fragment>
  );
};

export default GentanIMasterScreen;
