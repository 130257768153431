import React, { Fragment, useState, useMemo } from "react";
// Component
import { Grid, Box } from "@mui/material";
import GroupingFilterBar from "pages/Forecast/ShipmentManagementScreen/components/Grouping/GroupingFilterBar";
import GroupingTable from "pages/Forecast/ShipmentManagementScreen/components/Grouping/GroupingTable";
import GroupingButtonBar from "pages/Forecast/ShipmentManagementScreen/components/Grouping/GroupingButtonBar";
// Hook
import useColGrouping from "pages/Forecast/ShipmentManagementScreen/hooks/useColGrouping";
// Type
export default function Grouping(props) {
  const {
    setForm,
    form,
    onSearch,
    setOnSearch,
    mode,
    setMode,
    setMsg: { setMsgAlert, setMsgError },
    dataList: { exporterList, importerList, rPkgList, statusList },
  } = props;

  // Table
  const [rows, setRows] = useState([]);
  const [rPkg, setRPkg] = useState([]);
  const [pagination, setPagination] = useState({});
  const [pageNumber, setPageNumber] = useState(1);

  const columns = useMemo(() => {
    // eslint-disable-next-line react-hooks/rules-of-hooks
    return useColGrouping({ rPkg });
  }, [rPkg]);

  return (
    <Fragment>
      <Box sx={{ padding: "1rem", mt: 1 }}>
        <GroupingFilterBar
          mode={mode}
          form={form}
          setForm={setForm}
          dataList={{ exporterList, importerList, rPkgList, statusList }}
        />
      </Box>
      <Grid container spacing={2} sx={{ md: 4 }}>
        <Grid item xs={12}>
          <GroupingButtonBar
            setMode={setMode}
            form={form}
            setForm={setForm}
            rows={rows}
            setRows={setRows}
            rPkg={rPkg}
            setRPkg={setRPkg}
            setOnSearch={setOnSearch}
            pageNumber={pageNumber}
            setPageNumber={setPageNumber}
            pagination={pagination}
            setPagination={setPagination}
            setMsg={{ setMsgAlert, setMsgError }}
            dataList={{ exporterList, importerList, rPkgList, statusList }}
          />
        </Grid>
        <Grid item xs={12}>
          {onSearch && (
            <GroupingTable
              form={form}
              mode={mode}
              columns={columns}
              rows={rows}
              setRows={setRows}
              setRPkg={setRPkg}
              setOnSearch={setOnSearch}
              setMsg={{ setMsgAlert, setMsgError }}
              pagination={{ pagination, pageNumber, setPageNumber, setPagination }}
              dataList={{ exporterList, importerList, rPkgList }}
            />
          )}
        </Grid>
      </Grid>
    </Fragment>
  );
}
