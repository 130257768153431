import React, { useEffect } from "react";
import { Box, IconButton, Popover, TextField, styled } from "@mui/material";
import { DateCalendar, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import InputMask from "react-input-mask";
import InsertInvitationIcon from "@mui/icons-material/InsertInvitation";

import { getLocalDate, getLocalDateForValidate } from "utils/init-config-date";
const StyledTextField = styled(TextField)(({ theme, error }) => ({
  "& .MuiInputBase-input": {
    border: `1.5px solid ${error ? theme.palette.primary.main : theme.palette.secondary.medium}`,
    borderRadius: ".3rem",
    backgroundColor: theme.palette.primary.light,
    textAlign: "left",
    padding: "5px 10px",
  },
  "& .MuiInput-root:focus": {
    border: `2px solid black !important`,
    borderRadius: ".3rem",
    color: "#000",
  },
  "& .MuiOutlinedInput-root:focus": {
    border: `2px solid black !important`,
    borderRadius: ".3rem",
    color: "#000",
  },
  "& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline": {
    border: `2px solid black !important`,
  },
}));
const StyledDateCalendar = styled(DateCalendar)(({ theme, error }) => ({
  "& .MuiButtonBase-root": {
    "&.MuiPickersDay-root:hover": {
      backgroundColor: `${theme.palette.secondary.medium}`,
    },
  },
  "& .Mui-selected": {
    backgroundColor: `${theme.palette.tertiary.chip}`,
    color: "#000000",
  },
}));
const DatePickerField = ({
  id,
  onChange,
  value,
  required,
  fullWidth = false,
  disabled = false,
  autoFocus = false,
  defaultValue = "",
  format = "DD/MM/YYYY",
  mask = "DD/MM/YYYY",
  views,
  ...props
}) => {
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [select, setSelect] = React.useState(getLocalDate());
  const [date, setDate] = React.useState(value);
  const open = Boolean(anchorEl);

  const handleClick = event => {
    if (disabled) {
      handleClose();
      return;
    }
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };
  const handleSelect = date => {
    try {
      setSelect(date);
      setDate(getLocalDate(date).format(format));
      onChange(getLocalDate(date).format(format));
      handleClose();
      return;
    } catch (error) {
      console.error(error);
    }
  };
  const onBlur = event => {
    try {
      const newValue = event.target.value;
      if (!newValue) {
        setDate("");
        onChange(newValue);
        setSelect(getLocalDate());
        return;
      }
      setDate(newValue);
      onChange(newValue);
      if (!getLocalDate(newValue, format, true).isValid()) {
        setSelect(getLocalDate());
        return;
      }
      setSelect(getLocalDate(newValue, format));
      return;
    } catch (error) {
      console.log(error);
      return false;
    }
  };
  const handleChange = event => {
    const newValue = event.target.value;
    if (!newValue) {
      setDate("");
      setSelect(getLocalDate());
      onChange("");
      return;
    }
    setDate(newValue);
    onChange(newValue);
    if (!getLocalDateForValidate(newValue, format, true)) {
      setSelect(getLocalDate());
      return;
    }
    setSelect(getLocalDate(newValue, format));
  };

  const formattedValue = date => {
    try {
      // date equal null
      if (!date) {
        return "";
      }
      if (typeof date === "string") {
        return getLocalDate(date, format);
      }

      return date;
    } catch (error) {
      console.error(error);
      return false;
    }
  };
  useEffect(() => {
    setDate(value);
  }, [value]);
  return (
    <Box
      width={"100%"}
      sx={{
        display: "flex",
        justifyContent: "center",
        flexWrap: "row wrap",
        alignItems: "center",
      }}>
      <LocalizationProvider dateAdapter={AdapterDayjs}>
        <InputMask
          id={id}
          data-testid={id}
          mask={mask}
          formatChars={{
            Y: "[0-9]",
            M: "[0-9]",
            D: "[0-9]",
          }}
          value={date}
          required={required}
          disabled={disabled}
          fullWidth={fullWidth}
          onChange={handleChange}
          onBlur={onBlur}
          placeholder={format}
          maskChar={null}
          alwaysShowMask={false}
          autoFocus={autoFocus}>
          {inputProps => <StyledTextField disabled={disabled} {...inputProps} />}
        </InputMask>
        <IconButton onClick={handleClick}>
          <InsertInvitationIcon sx={{ color: "#58595B", ml: 1, mb: "2px" }} />
        </IconButton>

        <Popover
          open={open}
          anchorEl={anchorEl}
          onClose={handleClose}
          sx={{ transform: "scale(1.25)", transformOrigin: "top left" }}
          anchorOrigin={{
            vertical: "bottom",
            horizontal: "left",
          }}>
          <StyledDateCalendar views={views} value={formattedValue(select)} onChange={handleSelect} />
        </Popover>
      </LocalizationProvider>
    </Box>
  );
};

export default DatePickerField;
