import { getLocalDate } from "utils/init-config-date";

export const formatRow = dataList => {
  try {
    const delivery = dataList?.result?.delivery;
    const total = dataList?.result?.total;
    const printed = dataList?.result?.printed;
    const accumulatePrinted = dataList?.result?.accumulatePrinted;
    const accumulateRemaining = dataList?.result?.accumulateRemaining;
    const warehouseSupplierList = ["", "Total(Sum)", "Printed", "Accumulate Printed", "Accumulate Remaining"];

    let data = [];
    let deliveryDate = [];
    let tempRow = {};

    // Get Total Sum

    // index === 2
    const deliveryPlanDt = getLocalDate(`${delivery[0]?.month}/${delivery[0]?.year}`, "M/YYYY").format("MMM YYYY");
    for (let index = 0; index < 5; index++) {
      let row;

      delivery.forEach((val, indx) => {
        if (index === 0) {
          val.dayQty.forEach(item => {
            tempRow[item.day] = item.value;
            if (!deliveryDate.includes(item.day)) return deliveryDate.push(item.day);
          });
          row = createRow(index, `${val?.whImpExpCd}(${val?.whName})`, tempRow);
        } else if (index === 1) {
          total.forEach(item => {
            tempRow[item.day] = item?.value;
          });
          row = createRow(index, warehouseSupplierList[index], tempRow);
        } else if (index === 2) {
          printed.forEach(item => {
            tempRow[item.day] = item.value;
          });
          row = createRow(index, warehouseSupplierList[index], tempRow);
        } else if (index === 3) {
          accumulatePrinted.forEach(item => {
            tempRow[item.day] = item.value;
          });
          row = createRow(index, warehouseSupplierList[index], tempRow);
        } else if (index === 4) {
          accumulateRemaining.forEach(item => {
            tempRow[item.day] = item.value;
          });
          row = createRow(index, warehouseSupplierList[index], tempRow);
        }
      });
      data.push(row);
    }
    return { data, deliveryDate, deliveryPlanDt };
  } catch (error) {
    console.error(error);
  }
};
function createRow(index, rowHeader, data) {
  return { no: index + 1, rowNumber: index + 1, warehouseSupplier: rowHeader, ...data };
}
