import React, { Fragment } from "react";
// Utils
import { messageDisplay, responseErrors, responseSuccess } from "utils";
// Hooks
import { useConfirmDialogContext } from "context/confirmDialogContext";
//COmponents
import { InputButton } from "components/UI";
//API
import { useOnDemandExcelCancelMutation, useOnDemandExcelDeleteMutation } from "shared/services/common/monitoring";
// Constants
import { BUTTON_VALUE } from "shared/constants/button.constant";
import { MSG_TYPE, ModeAction } from "state/enum";
import { API_NAME_ON_DEMAND_EXCEL, PAGINATION, ROW_PER_PAGE } from "shared/constants";
export default function ActionTableBar(props) {
  const {
    mode,
    form,
    rows,
    setRows,
    setMode,
    getSearch,
    rowSelection: { rowSelectionModel, setRowSelectionModel },
    pagination: { pageNumber, setPagination, setPageNumber },
    setMsg: { setMsgAlert, setMsgError },
  } = props;
  const confirmDialogCtx = useConfirmDialogContext();
  const deleteOnDemandExcel = useOnDemandExcelDeleteMutation();
  const cancelOnDemandExcel = useOnDemandExcelCancelMutation();
  const handleDeleteBtn = async () => {
    setMsgError([]);
    setMsgAlert([]);
    const confirm = await confirmDialogCtx.success({ type: "COMMON_ON_DEMAND_CONFIRM_DELETE" });
    if (!confirm) {
      return;
    }
    try {
      if (!rowSelectionModel.length) {
        const msg = messageDisplay({ type: MSG_TYPE.ERROR, code: "MSTD1017AERR", msg: [""] });
        setMsgError([msg]);
        return;
      }
      const selectedNo = rowSelectionModel[0];
      const row = rows.find(v => v.rowNumber === selectedNo);
      const docId = row?.docId;
      const deleteData = {
        [API_NAME_ON_DEMAND_EXCEL.DATA_OWNER]: form?.dataOwner,
        [API_NAME_ON_DEMAND_EXCEL.FILE_STATUS]: [row?.fileStatus],
        [API_NAME_ON_DEMAND_EXCEL.UPDATE_BY]: form?.userId,
      };

      const response = await deleteOnDemandExcel(docId, deleteData);
      const msg = responseSuccess(response);
      setMsgAlert([msg]);
      // Search
      const bodySearch = {
        [API_NAME_ON_DEMAND_EXCEL.DATA_OWNER]: form?.dataOwner,
        [API_NAME_ON_DEMAND_EXCEL.REQUEST_DATE]: form?.requestDate,
        [API_NAME_ON_DEMAND_EXCEL.REPORT_STATUS]: form?.reportStatus,
        [API_NAME_ON_DEMAND_EXCEL.REPORT_NAME]: form?.reportName,
        [API_NAME_ON_DEMAND_EXCEL.USER_ID]: form?.userId,
        [API_NAME_ON_DEMAND_EXCEL.ORDER_BY_DATE]: form?.orderByDate,
        [PAGINATION.PAGE_NUMBER]: pageNumber,
        [PAGINATION.ROW_PER_PAGE]: ROW_PER_PAGE,
      };
      const { searchData, data } = await getSearch(bodySearch);
      if (!data.length) {
        setMsgError([messageDisplay({ type: MSG_TYPE.ERROR, code: "MSTD0059AERR", msg: [""] })]);
        return;
      }
      setMode(ModeAction.VIEW);
      setRowSelectionModel([]);
      setPagination(searchData?.result?.pagination ?? {});
      setPageNumber(searchData?.result?.pagination?.pageNumber);
      setRows(data);
    } catch (error) {
      const errors = responseErrors(error);
      setMsgError([`${errors}`]);
      return;
    }
  };
  const handleCancelBtn = async () => {
    setMsgError([]);
    setMsgAlert([]);
    const confirm = await confirmDialogCtx.success({
      type: "COMMON_ON_DEMAND_CONFIRM_CANCEL",
    });
    if (!confirm) {
      return;
    }
    try {
      if (!rowSelectionModel.length) {
        const msg = messageDisplay({ type: MSG_TYPE.ERROR, code: "MSTD1018AERR", msg: [""] });
        setMsgError([msg]);
        return;
      }
      const selectedNo = rowSelectionModel[0];
      const row = rows.find(v => v.rowNumber === selectedNo);
      const docId = row?.docId;
      const cancelData = {
        [API_NAME_ON_DEMAND_EXCEL.DATA_OWNER]: form?.dataOwner,
        [API_NAME_ON_DEMAND_EXCEL.FILE_STATUS]: [row?.fileStatus],
        [API_NAME_ON_DEMAND_EXCEL.UPDATE_BY]: form?.userId,
      };

      const response = await cancelOnDemandExcel(docId, cancelData);
      const msg = responseSuccess(response);
      setMsgAlert([msg]);
      // Search
      const bodySearch = {
        [API_NAME_ON_DEMAND_EXCEL.DATA_OWNER]: form?.dataOwner,
        [API_NAME_ON_DEMAND_EXCEL.REQUEST_DATE]: form?.requestDate,
        [API_NAME_ON_DEMAND_EXCEL.REPORT_STATUS]: form?.reportStatus,
        [API_NAME_ON_DEMAND_EXCEL.REPORT_NAME]: form?.reportName,
        [API_NAME_ON_DEMAND_EXCEL.USER_ID]: form?.userId,
        [API_NAME_ON_DEMAND_EXCEL.ORDER_BY_DATE]: form?.orderByDate,
        [PAGINATION.PAGE_NUMBER]: pageNumber,
        [PAGINATION.ROW_PER_PAGE]: 5,
      };
      const { searchData, data } = await getSearch(bodySearch);
      if (!data.length) {
        setMsgError([messageDisplay({ type: "error", code: "MSTD0059AERR", msg: [""] })]);
        return;
      }
      setMode(ModeAction.VIEW);
      setRowSelectionModel([]);
      setPagination(searchData?.result?.pagination ?? {});
      setPageNumber(searchData?.result?.pagination?.pageNumber);
      setRows(data);
    } catch (error) {
      const errors = responseErrors(error);
      setMsgError([`${errors}`]);
      return;
    }
  };
  return (
    <Fragment>
      <InputButton
        id="button-delete"
        value={BUTTON_VALUE.DELETE}
        disabled={mode === ModeAction.SELECTED ? false : true}
        onClick={handleDeleteBtn}
      />
      <InputButton
        id="button-cancel"
        value={BUTTON_VALUE.CANCEL}
        disabled={mode === ModeAction.SELECTED ? false : true}
        onClick={handleCancelBtn}
      />
    </Fragment>
  );
}
