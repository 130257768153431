// src/store.js
import { configureStore } from '@reduxjs/toolkit';
import counterReducer from './slices/counterSlice'
import cancelVanningReducer from './slices/export-invoice/cancelVanningSlice'

const store = configureStore({
  reducer: {
    counter: counterReducer,
    cancelVanning: cancelVanningReducer
  },
});

export default store;
