import React, { Fragment } from "react";
import { Grid } from "@mui/material";
import { InputButton } from "components/UI";
// Utils
import { responseErrors, messageDisplay } from "utils";
import { validationSearchForm } from "utils/validation";
// Service
import { useSystemMasterSearchMutation } from "shared/services/master-maintenance/system-master.service";
// Constant
import { ModeAction, MSG_TYPE, MessageType } from "state/enum";
import { BUTTON_VALUE, PAGINATION, ROW_PER_PAGE } from "shared/constants";
import { API_SYSTEM_MASTER, FIRST_PAGE } from "shared/constants";
import { userProfile } from "constant";
import { useLoadingContext } from "shared/hooks/LoadingContext";

export default function ButtonBar(props) {
  const {
    form,
    setForm,
    setOnSearch,
    setRows,
    setMode,
    setRowSelectionModel,
    setRowModesModel,
    setMsg: { setMsgAlert, setMsgError },
    stateBtn: { searchBtn, clearBtn },
    pagination: { pageNumber, setPagination, setPageNumber },
  } = props;
  const { startLoading, stopLoading } = useLoadingContext();
  const systemMasterSearch = useSystemMasterSearchMutation();
  const handleSearchBtn = async e => {
    try {
      e.preventDefault();
      setMsgError([]);
      setMsgAlert([]);
      // Reset Data of Table
      setMode(ModeAction.VIEW);
      setRows([]);
      setPagination({});
      setPageNumber(FIRST_PAGE);
      setRowSelectionModel([]);
      setRowModesModel({});
      setOnSearch(false);

      const body = {
        [API_SYSTEM_MASTER.DATA_OWNER]: userProfile.dataOwner,
        [API_SYSTEM_MASTER.CATEGORY]: form?.category,
        [API_SYSTEM_MASTER.SUB_CATEGORY]: form?.subCategory,
        [API_SYSTEM_MASTER.CODE]: form?.code,
        [PAGINATION.PAGE_NUMBER]: FIRST_PAGE,
        [PAGINATION.ROW_PER_PAGE]: ROW_PER_PAGE,
      };
      // MSTD0043AERR: Invalid Search criteria used for Code field.
      const { isSuccess, errors } = validationSearchForm({
        data: body,
        rule: [
          {
            field: API_SYSTEM_MASTER.CATEGORY,
            type: MessageType.EMPTY,
            key: ["Category"],
          },
        ],
      });
      if (!isSuccess) {
        setMsgError(errors);
        window.scrollTo(0, 0);
        return;
      }
      startLoading();
      const searchData = await systemMasterSearch(body);
      stopLoading();
      const data =
        searchData?.result?.data?.map((item, index) => ({
          no: index + 1,
          [API_SYSTEM_MASTER.ROW_NUMBER]: item?.rowNumber,
          [API_SYSTEM_MASTER.CATEGORY]: item?.category,
          [API_SYSTEM_MASTER.SUB_CATEGORY]: item?.subCategory,
          [API_SYSTEM_MASTER.CODE]: item?.code,
          [API_SYSTEM_MASTER.VALUE]: item?.value,
          [API_SYSTEM_MASTER.REMARK]: item?.remark,
          [API_SYSTEM_MASTER.STATUS]: item?.status,
          [API_SYSTEM_MASTER.STATUS_ID]: item?.statusId,
          [API_SYSTEM_MASTER.CREATE_BY]: item?.createBy,
          [API_SYSTEM_MASTER.CREATE_DATE]: item?.createDt,
          [API_SYSTEM_MASTER.UPDATE_DATE]: item?.updateDt,
          [API_SYSTEM_MASTER.UPDATE_BY]: item?.updateBy,
          [API_SYSTEM_MASTER.UPDATE_KEY]: item?.updateKey,
        })) ?? [];

      if (!data.length) {
        setMsgError([messageDisplay({ type: MSG_TYPE.ERROR, code: "MSTD0059AERR", msg: [""] })]);
        return false;
      }
      setPagination(searchData?.result?.pagination ?? {});
      setPageNumber(searchData?.result?.pagination?.pageNumber);
      setRows(data);
      setOnSearch(true);
    } catch (error) {
      stopLoading();
      const errors = responseErrors(error);
      setMsgError([`${errors}`]);
      return;
    }
  };
  const handleClearBtn = async () => {
    setMode(ModeAction.VIEW);
    setMsgError([]);
    setMsgAlert([]);
    setRowSelectionModel([]);
    setRowModesModel({});
    setOnSearch(false);
    // reset form
    setForm({
      userId: userProfile.userId,
      [API_SYSTEM_MASTER.DATA_OWNER]: userProfile.dataOwner,
      [API_SYSTEM_MASTER.CATEGORY]: "",
      [API_SYSTEM_MASTER.SUB_CATEGORY]: "",
      [API_SYSTEM_MASTER.CODE]: "",
      [API_SYSTEM_MASTER.VALUE]: "",
      [API_SYSTEM_MASTER.REMARK]: "",
      [API_SYSTEM_MASTER.STATUS]: "",
      [API_SYSTEM_MASTER.STATUS_ID]: "",
      [API_SYSTEM_MASTER.CREATE_BY]: "",
      [API_SYSTEM_MASTER.CREATE_DATE]: "",
      [API_SYSTEM_MASTER.UPDATE_BY]: "",
      [API_SYSTEM_MASTER.UPDATE_DATE]: "",
    });
    setRows([]);
    setPagination({});
    setPageNumber(FIRST_PAGE);
    document.getElementById("select_category").focus();
  };

  return (
    <Fragment>
      <Grid container>
        <Grid item xs={12} sx={{ display: "flex", justifyContent: "flex-end", flexWrap: "wrap" }}>
          <InputButton id="btn_search" value={BUTTON_VALUE.SEARCH} disabled={searchBtn} onClick={handleSearchBtn} />
          <InputButton id="btn_clear" value={BUTTON_VALUE.CLEAR} disabled={clearBtn} onClick={handleClearBtn} />
        </Grid>
      </Grid>
    </Fragment>
  );
}
