import React, { Fragment } from "react";
import { InputButton } from "components/UI";
//Utils
import { messageDisplay, responseDownloadFileErrors, responseErrors, responseSuccess } from "utils";
import { validationSearchForm } from "utils/validation";
import { formatCol } from "../hooks/formatCol";
// Hook
import { useConfirmDialogContext } from "context/confirmDialogContext";
//Constant
import { MSG_TYPE, MessageType } from "state/enum";
import { API_EXCEL } from "shared/constants";
import {
  useExcelDownloadConfirmRecordMutation,
  useExcelDownloadExcelFileMutation,
  useExcelDownloadCsvFileMutation,
  useExcelDownloadToOnDemandMutation,
} from "shared/services/common/monitoring";
export default function ActionBar(props) {
  const {
    form,
    rowSelection: { rowSelectionModel },
    setMsg: { setMsgAlert, setMsgError },
  } = props;
  const confirmDialogCtx = useConfirmDialogContext();
  const confirmRecord = useExcelDownloadConfirmRecordMutation();
  const excelDownload = useExcelDownloadExcelFileMutation();
  const csvDownload = useExcelDownloadCsvFileMutation();
  const uploadToOnDemand = useExcelDownloadToOnDemandMutation();
  // constant value
  const NO_RECORD = 0;

  const handleDownload = async fileType => {
    try {
      setMsgAlert([]);
      setMsgError([]);
      const edited = formatCol(rowSelectionModel);
      const body = {
        [API_EXCEL.DATA_OWNER]: form?.dataOwner,
        [API_EXCEL.TABLE_NAME]: form?.tableName,
        [API_EXCEL.SETTING_ID]: form?.bookMasks,
        [API_EXCEL.REPORT_NAME]: form?.reportName,
        [API_EXCEL.REPORT_TITLE]: form?.reportTitle,
        [API_EXCEL.START_COLUMN]: form?.startColumn,
        [API_EXCEL.START_ROW]: form?.startRow,
        [API_EXCEL.BIG_DATA]: form?.bigData ? "1" : "0",
        [API_EXCEL.COLUMNS]: edited,
        [API_EXCEL.USER]: {
          [API_EXCEL.USER_INFO]: {
            [API_EXCEL.NAME]: form?.name,
            [API_EXCEL.EMAIL]: form?.mail,
          },
          [API_EXCEL.ROLE]: [
            {
              [API_EXCEL.ROLE_ID]: form?.roleId,
            },
          ],
        },
      };
      const { isSuccess, errors } = validationSearchForm({
        data: form,
        rule: [
          {
            field: API_EXCEL.TABLE_NAME,
            type: MessageType.EMPTY,
            key: ["Table Name"],
          },
        ],
      });
      if (!isSuccess) {
        setMsgError(errors);
        window.scrollTo(0, 0);
        return;
      }

      const confirmResult = await confirmRecord(body);
      // if record in table === 0 will show data not found
      if (confirmResult?.result?.record === NO_RECORD) {
        setMsgError([messageDisplay({ type: MSG_TYPE.ERROR, code: "MSTD0059AERR", msg: "" })]);
        return false;
      }
      // Upload to WSTD3020: On Demand Excel
      if (form?.bigData) {
        const downloadConfirm = await confirmDialogCtx.success({
          type: "COMMON_EXCEL_DOWNLOAD",
          msg: confirmResult?.result?.record,
        });
        if (!downloadConfirm) {
          return false;
        }
        const response = await uploadToOnDemand(body, fileType);
        const msg = responseSuccess(response);
        setMsgAlert([msg]);
        window.scrollTo(0, 0);
        return true;
      } else if (!form?.bigData && fileType === "excel") {
        await handleExcelDownloadFile(fileType, confirmResult?.result?.record, body);
      } else if (!form?.bigData && fileType === "csv") {
        await handleCsvDownloadFile(fileType, confirmResult?.result?.record, body);
      }
      return true;
    } catch (error) {
      const errors = responseErrors(error);
      setMsgError(errors);
      window.scrollTo(0, 0);
      return false;
    }
  };
  const handleExcelDownloadFile = async (fileType, record, body) => {
    try {
      const downloadConfirm = await confirmDialogCtx.success({
        type: "COMMON_EXCEL_DOWNLOAD",
        msg: record,
      });
      if (!downloadConfirm) {
        return false;
      }

      // const fileName = `${form?.reportName ? form?.reportName : form?.tableName}.xlsx`;
      // Export Excel or Csv
      await excelDownload(body, fileType);
      return true;
    } catch (error) {
      const err = responseDownloadFileErrors(error);
      setMsgError(err);
      window.scroll(0, 0);
      return;
    }
  };
  const handleCsvDownloadFile = async (fileType, record, body) => {
    try {
      const downloadConfirm = await confirmDialogCtx.success({
        type: "COMMON_EXCEL_DOWNLOAD",
        msg: record,
      });
      if (!downloadConfirm) {
        return false;
      }

      // const fileName = `${form?.reportName ? form?.reportName : form?.tableName}.${fileType}`;

      // Export Excel or Csv
      await csvDownload(body, fileType);
      return true;
    } catch (error) {
      const err = responseErrors(error);
      setMsgError(err);
      window.scroll(0, 0);
      return;
    }
  };

  return (
    <Fragment>
      <InputButton
        id="btn-excel-download"
        value="Excel Download"
        disabled={false}
        onClick={() => handleDownload("excel")}
      />
      <InputButton id="btn-csv-download" value="CSV Download" disabled={false} onClick={() => handleDownload("csv")} />
    </Fragment>
  );
}
