export const API_EXPORT_INVOICE_CREATION_INFO = {
  ROW_NUMBER: "rowNumber",
  INVOICE_NO: "invNo",
  ETD_DATE: "etdDt",
  ETA_DATE: "etaDt",
  EXP_COMPANY_ABBR: "expCompanyAbbr",
  IMPORTER_COMPANY: "impCompanyAbbr",
  IMPORTER_COMPANY_ID: "impCompanyId",
  VESSEL_NAME: "vesselName",
  INVOICE_DATE: "invDt",
  INVOICE_NET_WEIGHT: "invNetWeight",
  INVOICE_FOB_PRICE: "invFobPrice",
  CURRENCY_CD: "currencyCd",
  ORG_BL_FILE_NAME: "orgBlFileName",
  EXP_CUSTOMS_STK_DATE: "expCustomsStkDt",
  UPDATE_DATE: "updateDt",
  UPDATE_BY: "updateBy",
  NON_COMM_INVOICE_HID: "nonCommInvHId",
  EXP_CD: "expCd",
  IMP_COMPANY_CD: "impCompanyCd",
  INVOICE_TYPE: "invType",
  EXP_BROKER_COMPANY_ID: "expBrokerCompanyId",
  EXP_BROKER_COMPANY: "expBrokerCompanyAbbr",
  EXP_ENTRY_NO: "expEntryNo",
  SHIPMENT_CODE: "shipmentCd",
};

export const API_EXPORT_INVOICE_CREATION_SUB_SCREEN_INFO = {
  ROW_NUMBER: "rowNumber",
  EXP_CODE: "expCd",
  EXP_COPANY_ID: "expCompanyId",
  EXP_COMPANY: "expCompanyAbbr",
  EXP_PLANT_ID: "expPlantId",
  EXP_COUNTRY_NAME: "expCountryName",
  IMP_CODE: "impCd",
  IMP_COMPANY_ID: "impCompanyId",
  IMP_COMPANY_ABBR: "impCompanyAbbr",
  IMP_PLANT_ID: "impPlantId",
  IMP_COUNTRY_NAME: "impCountryName",
  CONSIGNEE: "consignee",
  VESSEL_NAME: "vesselName",
  ETD_DATE: "etdDt",
  RENBAN_NO: "renbanNo",
  ORDER_TYPE_CD: "orderTypeCd",
  ORDER_TYPE: "orderType",
  CONTAINER_NO: "containerNo",
  R_PKG_TYPE_ID: "rPkgTypeId",
  R_PKG_TYPE: "rPkgType",
  R_PKG_OWNER_COMP_ABBR: "rPkgOwnerCompAbbr",
  PACK_STATUS: "packStatus",
  QTY: "qty",
  PACK_STS: "packSts",
  WEIGHT: "weight",
  CATEGORY_CD: "categoryCd",
  LOAD_PORT_DESC: "loadPortDesc",
  DISC_PORT_DESC: "discPortDesc",
  VANNING_INFO_H_ID: "vanningInfoHId",
  SAVED_FLAG: "savedFlag",
  INV_TYPE: "invType",
  PACKING_MONTH: "packingMonth",
  VANNING_PLANT: "vanningPlant",
  BROKER_COMPANY_ID: "brokerCompanyId",
  BROKER_COMPANY_ABBR: "brokerCompanyAbbr",
};

export const API_EXPORT_INVOICE_CANCEL_VANNING_RESULT_SUB_SCREEN_INFO = {
  ROW_NUMBER: "rowNumber",
  EXP_CD: "expCd",
  IMPORTER_COMPANY: "impCompanyAbbr",
  RENBAN_NO: "renbanNo",
  ETD_DATE: "etdDt",
  CONTAINER_NO: "containerNo",
  QTY: "qty",
  INVOICE_NO: "invNo",
  NON_COMM_INVOICE_HID: "nonCommInvHId",
  VANNING_INFO_H_ID: "vanningInfoHId",
  SHIPPING_INFO_H_ID: "shippingInfoHId",
  VAN_UPDATE_DATE: "vanUpdateDt",
  VAN_UPDATE_BY: "vanUpdateBy",
  SHIP_UPDATE_DATE: "shipUpdateDt",
  SHIP_UPDATE_BY: "shipUpdateBy",
};
