import React from "react";
import { Button } from "@mui/material";

const InputButton = ({
  id,
  value,
  variant = "contained",
  color = "primary",
  type = "button",
  disabled = false,
  onClick = undefined,
  size = "medium",
  style = {},
  props,
  minWidth = "100px",
  onKeyDown,
}) => {
  return (
    <Button
      {...props}
      data-testid={id}
      id={id}
      type={type}
      variant={variant}
      color={color}
      disabled={disabled}
      onClick={onClick}
      size={size}
      onKeyDown={onKeyDown}
      style={{
        textTransform: "none",
        marginRight: "8px",
        minWidth: minWidth,
        ...style,
      }}>
      {value}
    </Button>
  );
};

export default InputButton;
