import { Box, Grid } from "@mui/material";
import React, { Fragment, useEffect, useMemo, useState } from "react";
import { InputText, InputDropDown } from "components/UI/index";
import { message, responseErrors, responseDownloadFileErrors, responseSuccess } from "utils";
import { HeaderContentBar } from "components/Layout";
import { AlertMessage } from "components/UI";
import ActionBar from "../../Partials/ActionBar";
import { ModeAction } from "state/enum";
import { useConfirmDialogContext } from "context/confirmDialogContext";
import SubmitBar from "pages/Partials/SubmitBar";
import DataTable from "pages/DataTable";
import { ModalContainerGroup } from "pages/Master/ContainerGroupScreen/component";
import useColumn from "./hooks/useColumn";

import {
  useContainerGroupGetApmcQuery,
  useContainerGroupGetContainerTypeQuery,
  useContainerGroupDownloadExcelMutation,
  useContainerGroupSearchMutation,
  useContainerGroupDeleteMutation,
} from "shared/services/master";
import { userProfile } from "constant";
import { FIRST_PAGE } from "shared/constants";
import { getLocalDate } from "utils/init-config-date";

const ContainerGroupScreen = () => {
  const confirmDialogCtx = useConfirmDialogContext();

  const [mode, setMode] = useState("view");
  const [rows, setRows] = useState([]);
  const [rowModesModel, setRowModesModel] = useState({});
  const [rowSelectionModel, setRowSelectionModel] = useState([]);

  const [onSearch, setOnSearch] = useState(false);
  const [msgError, setMsgError] = useState([]);
  const [msgAlert, setMsgAlert] = useState([]);

  useEffect(() => {
    document.getElementById("select_apmc").focus();
  }, []);

  const [searchForm, setSearchForm] = useState({
    dataOwner: userProfile.dataOwner, // TODO: get dataOwner from profile
    refCompanyId: "",
    refPlantId: "",
    containerType: "",
    containerGroupCode: "",
  });
  // 1. pagination
  const [pagination, setPagination] = useState({});
  const [pageNumber, setPageNumber] = useState(1);
  const handleChangePagination = async (event, value) => {
    setPageNumber(value);
    const searchData = await searchDataAsync({
      ...searchForm,
      pageNumber: value,
      rowsPerPage: 10,
    });

    const data =
      searchData?.result?.data?.map((item, index) => ({
        rowNumber: index + 1,
        ...item,
      })) ?? [];

    setPagination(searchData?.result?.pagination ?? {});
    setPageNumber(searchData?.result?.pagination?.pageNumber);

    setRows(data);
  };

  // 2. api
  const { data: apmcData } = useContainerGroupGetApmcQuery({
    dataOwner: userProfile.dataOwner,
  });

  const { data: containerTypeData, refetch: refetchContainerType } = useContainerGroupGetContainerTypeQuery({
    dataOwner: userProfile.dataOwner,
  });
  const downloadExcel = useContainerGroupDownloadExcelMutation();
  const searchDataAsync = useContainerGroupSearchMutation();
  const deleteContainerGroup = useContainerGroupDeleteMutation();
  // handle click detail
  const [rowNumber, setRowNumber] = useState();
  const [modalForm, setModalForm] = useState({
    apmc: "",
    containerGroupCode: "",
    containerTypeId: "",
    numRetry: "",
    score: "",
    effDtFrom: "",
    effDtTo: "",
    rPackageType: [],
    warehouse: [],
  });
  const handleClickDetail = row => {
    setModalForm({
      apmc: row?.refImpExpCd,
      containerGroupCode: row?.containerGroupCd,
      containerTypeId: row?.containerTypeId,
      numRetry: row?.numRetry,
      score: row?.priority,
      effDtFrom: row?.effDtFrom,
      effDtTo: row?.effDtTo,
      rPackageType: row?.rPkgType,
      warehouse: row?.warehouse,
    });
    setRowNumber(row.id);
    setIsOpenModalContainerGroup(true);
  };

  // 4. columns
  const columns = useMemo(() => {
    // eslint-disable-next-line react-hooks/rules-of-hooks
    return useColumn({ rows, mode, handleClickDetail });
  }, [rows, mode]);

  // 6. handleSearch
  const handleSearch = async e => {
    try {
      e.preventDefault();
      setMsgError([]);
      setMsgAlert([]);
      setMode(ModeAction.VIEW);
      setRows([]);
      setRowSelectionModel([]);
      setRowModesModel({});
      setOnSearch(true);

      const searchData = await searchDataAsync({
        ...searchForm,
        dataOwner: userProfile.dataOwner,
        pageNumber: 1,
        rowsPerPage: 10,
      });

      const data =
        searchData?.result?.data?.map((item, index) => ({
          rowNumber: index + 1,
          ...item,
        })) ?? [];

      // data not found
      if (!data.length) {
        setMsgError([message({ type: "notFound" })]);
      }

      setPagination(searchData?.result?.pagination ?? {});
      setPageNumber(searchData?.result?.pagination?.pageNumber);
      setRows(data);
    } catch (error) {
      const errors = responseErrors(error);
      setMsgError(errors);
      return;
    }
  };

  // 7. handleDeleteClick
  const handleDeleteClick = async () => {
    setMsgError([]);
    setMsgAlert([]);
    const confirm = await confirmDialogCtx.success({ type: "confirmDelete" });
    if (!confirm) {
      return;
    }
    // TODO: Action Delete
    try {
      const selectedNo = rowSelectionModel[0];
      const containerGroupId = rows.find(v => v.rowNumber === selectedNo)?.containerGroupId;

      const body = { updateBy: userProfile.userId };
      const response = await deleteContainerGroup(containerGroupId, body);
      console.log(response);
      const msg = responseSuccess(response);
      const newRows = rows.filter(row => !rowSelectionModel.includes(row.rowNumber));
      setRows(newRows);
      // const msg = messageTypeDisplay(MessageType.DELETED);
      setMsgAlert([msg]);
      window.scroll(0, 0);
      await refetchSearch();

      return;
    } catch (error) {
      const errors = responseErrors(error);
      setMsgError(errors);
      window.scroll(0, 0);
      return;
    }
  };

  // 9. handleClear
  const handleClear = () => {
    setMode(ModeAction.VIEW);
    setMsgError([]);
    setMsgAlert([]);

    setSearchForm({
      dataOwner: userProfile.dataOwner, // TODO: get dataOwner from profile
      refCompanyId: "",
      refPlantId: "",
      containerType: "",
      containerGroupCode: "",
    });
    setModalForm({
      apmc: "",
      containerGroupCode: "",
      containerTypeId: "",
      numRetry: "",
      score: "",
      effDtFrom: "",
      effDtTo: "",
      rPackageType: [],
      warehouse: [],
    });
    setRowModesModel({});
    setRowSelectionModel([]);
    setOnSearch(false);
    setRows([]);
    document.getElementById("select_apmc").focus();
  };

  // 10. handleDownloadExcel
  const handleDownloadExcel = async () => {
    // validate
    setMsgError([]);
    setMsgAlert([]);
    try {
      const searchData = await searchDataAsync({
        ...searchForm,
        dataOwner: userProfile.dataOwner,
        pageNumber: 1,
        rowsPerPage: 10,
      });

      // data not found
      if (!searchData?.result?.data.length) {
        setMsgError([message({ type: "notFound" })]);
      }
    } catch (error) {
      const errors = responseErrors(error);
      setMsgError(errors);
      window.scrollTo(0, 0);
      return;
    }
    try {
      // const filename = `Container_Group_${getLocalDate().format("YYYYMMDDHHmm")}.xlsx`;
      const body = {
        dataOwner: userProfile.dataOwner,
        refCompanyId: searchForm.refCompanyId,
        refPlantId: searchForm.refPlantId,
        containerType: searchForm.containerType,
        containerGroupCode: searchForm.containerGroupCode,
        pageNumber: pageNumber,
        rowsPerPage: 10,
      };
      await downloadExcel(body);
    } catch (error) {
      const errors = responseDownloadFileErrors(error);
      setMsgError(errors);
      return;
    }
  };

  // modal container group
  const [isOpenModalContainerGroup, setIsOpenModalContainerGroup] = useState(false);
  const handleAdd = async () => {
    setModalForm({
      apmc: "",
      containerGroupCode: "",
      containerTypeId: "",
      numRetry: "",
      score: "",
      effDtFrom: "",
      effDtTo: "",
      rPackageType: [],
      warehouse: [],
    });
    setMsgError([]);
    setMsgAlert([]);

    setMode(ModeAction.ADD);
    setRowNumber(0);
    setIsOpenModalContainerGroup(true);
  };

  // handle edit
  const handleEdit = async () => {
    setMsgError([]);
    setMsgAlert([]);
    const row = rows.find(val => val.rowNumber === rowSelectionModel[0]);
    setModalForm({
      apmc: row?.refImpExpCd,
      containerGroupCode: row?.containerGroupCd,
      containerTypeId: row?.containerTypeId,
      numRetry: row?.numRetry,
      score: row?.priority,
      effDtFrom: row?.effDtFrom,
      effDtTo: row?.effDtTo,
      rPackageType: row?.rPkgType,
      warehouse: row?.warehouse,
    });
    setMode(ModeAction.EDIT);
    setRowNumber(rowSelectionModel[0]);
    setIsOpenModalContainerGroup(true);
    setRowSelectionModel([]);
  };
  // handle copy
  const handleCopy = () => {
    setMsgError([]);
    setMsgAlert([]);
    const row = rows.find(val => val.rowNumber === rowSelectionModel[0]);
    setModalForm({
      apmc: row?.refImpExpCd,
      containerGroupCode: row?.containerGroupCd,
      containerTypeId: row?.containerTypeId,
      numRetry: row?.numRetry,
      score: row?.priority,
      effDtFrom: row?.effDtFrom,
      effDtTo: row?.effDtTo,
      rPackageType: row?.rPkgType,
      warehouse: row?.warehouse,
    });
    setMode(ModeAction.ADD);
    setRowNumber(rowSelectionModel[0]);
    setIsOpenModalContainerGroup(true);
    setRowSelectionModel([]);
  };
  const refetchSearch = async () => {
    try {
      const searchData = await searchDataAsync({
        ...searchForm,
        dataOwner: userProfile.dataOwner,
        pageNumber: pageNumber,
        rowsPerPage: 10,
      });
      const data =
        searchData?.result?.data?.map((item, index) => ({
          rowNumber: index + 1,
          ...item,
        })) ?? [];

      // data not found
      if (!data.length) {
        setOnSearch(false);
        setPagination({});
        setPageNumber(FIRST_PAGE);
        setRows([]);
        setRowModesModel({});
        setRowSelectionModel([]);
        return;
      }

      setPagination(searchData?.result?.pagination ?? {});
      setPageNumber(searchData?.result?.pagination?.pageNumber);
      setRows(data);
      setOnSearch(true);
    } catch (error) {
      const errors = responseErrors(error);
      setMsgError(errors);
      return;
    }
  };
  return (
    <Fragment>
      <Box sx={{ padding: "1rem" }}>
        {/* Header */}
        <HeaderContentBar title="WDN91090 : Container Group Master Maintenance Screen" />
        {/* Alert Message */}
        <AlertMessage type={"warning"} message={msgError} />
        <AlertMessage type={"success"} message={msgAlert} />
        <Grid container spacing={0} sx={{ mt: 4, alignItems: "center" }}>
          <Grid item xs={2}>
            <strong>APMC : </strong>
          </Grid>
          <Grid item xs={3}>
            <InputDropDown
              id="select_apmc"
              value={searchForm.refPlantId}
              onChange={e => {
                const refCompanyId = apmcData.filter(item => item.refPlantId === e.target.value)[0]?.refCompanyId;
                setSearchForm(old => ({
                  ...old,
                  refCompanyId: refCompanyId,
                  refPlantId: e.target.value,
                }));
              }}
              memu={apmcData?.map(val => ({
                key: val.refPlantId,
                value: val.companyAbbrImpExpCd,
              }))}
              placeholder="<All>"
              defaultValue=""
            />
          </Grid>
          <Grid item xs={1} />

          <Grid item xs={2}>
            <strong>Container Group Code : </strong>
          </Grid>
          <Grid item xs={3}>
            <InputText
              fullWidth
              id="input_containerGroupCode"
              value={searchForm.containerGroupCode}
              onChange={e =>
                setSearchForm(old => ({
                  ...old,
                  containerGroupCode: e.target.value.toUpperCase(),
                }))
              }
              maxLength={10}
              regularExp={/^[A-Za-z0-9\s]*$/}
            />
          </Grid>
          <Grid item xs={1} />

          <Grid item xs={2}>
            <strong>Container Type: </strong>
            {/* <pre>{JSON.stringify(containerTypeDropdownData, null, 2)}</pre> */}
          </Grid>
          <Grid item xs={3}>
            <InputDropDown
              id="select_containerType"
              value={searchForm.containerType}
              onChange={e =>
                setSearchForm(old => ({
                  ...old,
                  containerType: e.target.value,
                }))
              }
              memu={containerTypeData?.map(val => ({
                // key: val.containerType,
                key: val.containerType,
                value: val.containerType,
              }))}
              disabled={[ModeAction.ADD, ModeAction.EDIT].includes(mode)}
              placeholder="<All>"
              defaultValue=""
            />
          </Grid>
        </Grid>
      </Box>
      <ActionBar
        mode={mode}
        rows={rows}
        columns={columns}
        setRows={setRows}
        setMode={setMode}
        setMsgError={setMsgError}
        setMsgAlert={setMsgAlert}
        setOnSearch={setOnSearch}
        setRowModesModel={setRowModesModel}
        setRowSelectionModel={setRowSelectionModel}
        rowSelectionModel={rowSelectionModel}
        rowModesModel={rowModesModel}
        handleSearch={handleSearch}
        handleClear={handleClear}
        handleDownloadExcel={handleDownloadExcel}
        handleDeleteClick={handleDeleteClick}
        handleAdd={handleAdd}
        handleEdit={handleEdit}
        handleCopy={handleCopy}
        firstField={"select_apmc"}
      />
      <Box sx={{ padding: "1rem" }}>
        <Grid container spacing={2}>
          {onSearch && rows.length !== 0 && (
            <>
              <DataTable
                mode={mode}
                onSearch={onSearch}
                rowSelectionModel={rowSelectionModel}
                setRowSelectionModel={setRowSelectionModel}
                setMode={setMode}
                rows={rows}
                rowModesModel={rowModesModel}
                columns={columns}
                setRowModesModel={setRowModesModel}
                processRowUpdate={null}
                pagination={pagination}
                pageNumber={pageNumber}
                handleChangePagination={handleChangePagination}
                columnGroupingModel={null}
                columnVisibilityModel={{
                  currencys: false,
                  rPkgTypeId: false,
                }}
              />
            </>
          )}
          <SubmitBar
            mode={mode}
            rows={rows}
            setMode={setMode}
            setRows={setRows}
            setRowModesModel={setRowModesModel}
            setMsgError={setMsgError}
            rowModesModel={rowModesModel}
            rowSelectionModel={rowSelectionModel}
            setRowSelectionModel={setRowSelectionModel}
          />
        </Grid>
      </Box>

      {/* ModalContainerGroup */}
      <ModalContainerGroup
        modalForm={modalForm}
        setModalForm={setModalForm}
        open={isOpenModalContainerGroup}
        setOpen={setIsOpenModalContainerGroup}
        initialData={[]}
        mode={mode}
        setMode={setMode}
        rows={rows}
        rowNumber={rowNumber}
        setRowModesModel={setRowModesModel}
        setRowSelectionModel={setRowSelectionModel}
        refetchSearch={refetchSearch}
        setMsgAlertMainScreen={setMsgAlert}
        apmcData={apmcData}
        refetchContainerType={refetchContainerType}
      />
    </Fragment>
  );
};

export default ContainerGroupScreen;
