import { Grid } from "@mui/material";
import { DatePickerField, DropDown } from "components/UI";
import { FORM_DEFAULT_RETURNING } from "pages/Report/constants/constants";
import React, { useEffect, useState } from "react";
import ButtonAction from "../ButtonAction";

import {
  useExcelDownloadMutation,
  useExcelDownloadMutationInquiry,
  useExcelDownloadOnlineMutation,
  useReportCommonGetRPackageOwnerQuery,
  useExcelDownloadOnlineJavaMutation,
} from "shared/services/report";
import { responseDownloadFileErrors, responseErrors, responseSuccess } from "utils";
import { userProfile } from "constant";
import axios from "axios";
import { getLocalDate } from "utils/init-config-date";
import { useLoadingContext } from "shared/hooks/LoadingContext";
import { ResponseText } from "utils/5xxResponseText";

export default function ReturningPunctualityReport(props) {
  const [form, setForm] = useState(FORM_DEFAULT_RETURNING);

  const { data: rPkgOwnerList, failureReason: rPkgOwnerFailureReason } = useReportCommonGetRPackageOwnerQuery();

  useEffect(() => {
    let message = [];

    if (rPkgOwnerFailureReason?.response?.status >= 404) {
      message.push(`R-Package Owner List: ${ResponseText[rPkgOwnerFailureReason?.response?.status]}`);
    }
    props.setMsgServerErrorAlert(message);
  }, [rPkgOwnerFailureReason?.response?.status, props]);

  const [disabledSubmitBtn, setDisabledSubmitBtn] = useState(false);

  const generateReport = useExcelDownloadOnlineJavaMutation();
  // const generateReport = useExcelDownloadOnlineMutation();
  const { startLoading, stopLoading } = useLoadingContext();
  const handleDownload = async () => {
    try {
      props.setMsgError([]);
      props.setMsgAlert([]);
      const body = {
        userId: userProfile.userId,
        displayName: userProfile.displayName,
        dataOwner: userProfile.dataOwner,
        rPackageOwner: form?.rPkgTypeOwner,
        monthFrom: form?.monthForm,
        monthTo: form?.monthTo,
      };
      const pathReport = "generate-returning-punctuality-report";
      // const fileName = `Returning_Punctuality_${getLocalDate().format("YYYYMMDDHHmm")}.xlsx`;
      startLoading();
      const response = await generateReport(body, pathReport);
      stopLoading();
      setDisabledSubmitBtn(false);

      return;
    } catch (error) {
      if (error?.response?.status >= 500) {
        props.setMsgServerErrorAlert([ResponseText[error?.response?.status]]);
      } else {
        const msgError = responseDownloadFileErrors(error);
        props.setMsgError(msgError);
      }
      setDisabledSubmitBtn(false);
      stopLoading();
    }
    stopLoading();
  };

  useEffect(() => {
    document.getElementById("select-rpackage-owner").focus();
  }, []);

  return (
    <Grid container spacing={2}>
      {/* Container item #1 */}
      <Grid container spacing={1} sx={{ alignItems: "center", mb: 2 }}>
        <Grid item xs={2}>
          <strong>*Report : </strong>
        </Grid>
        <Grid item xs={3}>
          <DropDown
            id="select-report"
            fullWidth
            required
            defaultValue=""
            placeholder="<Select>"
            menu={[{ key: "1", value: "Returning Punctuality Report" }]}
            value={form?.reportId}
            disabled
          />
        </Grid>
      </Grid>

      {/* Container item #2 */}
      <Grid container spacing={1} sx={{ alignItems: "center", mb: 2 }}>
        <Grid item xs={2}>
          <strong>*R-Package Owner : </strong>
        </Grid>
        <Grid item xs={3}>
          <DropDown
            id="select-rpackage-owner"
            fullWidth
            required
            value={form?.rPkgTypeOwner}
            defaultValue=""
            placeholder="<Select>"
            // autoFocus={true}
            menu={rPkgOwnerList?.map(val => ({
              key: val.rPkgOwnerCompAbbr,
              value: val.rPkgOwnerCompAbbr,
            }))}
            onChange={e =>
              setForm(old => ({
                ...old,
                rPkgTypeOwner: e.target.value,
              }))
            }
          />
        </Grid>
      </Grid>

      {/* Container item #3 */}
      <Grid container spacing={1} sx={{ alignItems: "center", mb: 1 }}>
        <Grid item xs={2}>
          <strong>*Month (From) : </strong>
        </Grid>
        <Grid item xs={3}>
          <DatePickerField
            views={["year", "month"]}
            format="MM/YYYY"
            mask="MM/YYYY"
            fullWidth={true}
            value={form?.monthForm}
            onChange={e =>
              setForm(old => ({
                ...old,
                monthForm: e,
              }))
            }
            required
          />
        </Grid>
        <Grid item xs={1}>
          (MM/YYYY)
        </Grid>
        <Grid item xs={2} textAlign="right">
          <strong>*Month (To) : </strong>
        </Grid>
        <Grid item xs={3}>
          <DatePickerField
            views={["year", "month"]}
            format="MM/YYYY"
            mask="MM/YYYY"
            fullWidth={true}
            value={form?.monthTo}
            onChange={e =>
              setForm(old => ({
                ...old,
                monthTo: e,
              }))
            }
            required
          />
        </Grid>
        <Grid item xs={1}>
          (MM/YYYY)
        </Grid>
      </Grid>

      <ButtonAction
        handleDownload={handleDownload}
        setFrom={setForm}
        defaultFrom={FORM_DEFAULT_RETURNING}
        disabled={disabledSubmitBtn}
        setDisabled={setDisabledSubmitBtn}
        clearMsg={() => {
          props.setMsgAlert([]);
          props.setMsgError([]);
        }}
        firstField={"select-rpackage-owner"}
      />
    </Grid>
  );
}
