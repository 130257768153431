import { InputButton } from "components/UI";
import React from "react";
import { ModeAction } from "state/enum";

export default function useColumn({ rows, mode, handleClickDetail }) {
  return [
    {
      field: "rowNumber",
      sortable: false,
      headerName: "No",
      width: 50,
      editable: false,
      // flex: 1,
      renderCell: params => {
        if (params.row.rowNumber === rows.slice(-1)?.[0].rowNumber && ModeAction.ADD === mode) {
          return <div></div>;
        }
        // return params.value;
        return params.value;
      },
    },
    {
      field: "refCompanyAbbrImpExpCd",
      sortable: false,
      headerName: "APMC",
      headerAlign: "center",
      width: 180,
      editable: false,
      // flex: 2,
      renderCell: params => {
        return params.value;
      },
    },
    {
      field: "containerGroupCd",
      sortable: false,
      headerName: "Container Group Code",
      headerAlign: "center",
      width: 180,
      editable: false,
      // flex: 2,
      renderCell: params => {
        return params.value;
      },
    },
    {
      field: "containerType",
      sortable: false,
      headerName: "Container Type",
      headerAlign: "center",
      width: 180,
      editable: false,
      // flex: 2,
      renderCell: params => {
        return params.value;
      },
    },
    {
      field: "numRetry",
      sortable: false,
      headerName: "Number of Retry",
      headerAlign: "center",
      align: "right",
      width: 180,
      editable: false,
      // flex: 2,
      renderCell: params => {
        return params.value;
      },
    },
    {
      field: "priority",
      sortable: false,
      headerName: "Score",
      headerAlign: "center",
      align: "right",
      width: 180,
      editable: false,
      // flex: 2,
      renderCell: params => {
        return params.value.toLocaleString();
      },
    },
    {
      field: "rPkgType",
      sortable: false,
      headerName: "R-Package Type",
      headerAlign: "center",
      width: 180,
      editable: false,
      // flex: 3,
      renderCell: params => {
        if (params.value?.length) {
          return <div style={{ textWrap: "wrap" }}>{params.value.map(item => item.rPkgType).join(" | ")}</div>;
        }
        return "";
      },
    },
    {
      field: "warehouse",
      sortable: false,
      headerName: "Possible Warehouse",
      headerAlign: "center",
      width: 180,
      editable: false,
      // flex: 3,
      renderCell: params => {
        if (params.value?.length) {
          return <div style={{ textWrap: "wrap" }}>{params.value.map(item => item.whImpExpCd).join(" | ")}</div>;
        }
        return "";
      },
    },
    {
      field: "effDtFrom",
      headerName: "Effective Date (From) (DD/MM/YYYY)",
      editable: mode === ModeAction.ADD,
      width: 200,
      type: "text",
      align: "center",
      sortable: false,
      // flex: 2,
      renderCell: params => {
        return params.value;
      },
    },
    {
      field: "effDtTo",
      headerName: "Effective Date (To) (DD/MM/YYYY)",
      editable: mode === ModeAction.ADD,
      width: 200,
      type: "text",
      align: "center",
      sortable: false,
      // flex: 2,
      renderCell: params => {
        return params.value;
      },
    },
    {
      field: "detail",
      headerName: "",
      width: 150,
      align: "center",
      renderCell: params => {
        return (
          <InputButton
            value="Detail"
            color="secondary"
            style={{ minWidth: "80px", color: "#ffff" }}
            size="small"
            onClick={() => handleClickDetail(params.row)}
          />
        );
      },
    },
  ];
}
