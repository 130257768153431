import { Box, styled } from "@mui/material";
import * as React from "react";
import Sidebar from "./SideBar/Sidebar";
import route from "components/Layout/SideBar/route.constant";
import { Footer, Navbar } from ".";
import { Outlet } from "react-router-dom";
const StyledBox = styled(Box)(() => ({
  marginTop: "4rem",
  marginLeft: "23rem",
  marginRight: "2rem",
  marginBottom: "10rem",
}));

const Main = ({ children }) => {
  return (
    <>
      <Navbar />
      <Sidebar items={route} />
      <StyledBox>
        {/* {children} */}
        <Outlet />
      </StyledBox>
      <Box sx={{ display: "flex", marginTop: "50px" }}>
        <Footer />
      </Box>
    </>
  );
};

export default Main;
