import { Grid } from "@mui/material";
import { InputButton } from "components/UI";
import React from "react";

export default function renderUpload({
  searchForm,
  setSearchForm,
  setSelectedFile,
  handleTabLogDetail,
  handleUpload,
  handleDownloadTemplateExcel,
  setMsgAlert,
  setMsgError,
}) {
  const handleFileChange = event => {
    const file = event.target.files[0];
    setSelectedFile(file);
  };
  // handle clear upload
  const handleClearUpload = () => {
    setMsgAlert([]);
    setMsgError([]);
    document.getElementById("upload-file").value = null;
    setSearchForm(old => ({
      ...old,
      upload: "",
    }));
  };

  if (searchForm.upload !== "") {
    return (
      <>
        <Grid item xs={1} />
        <Grid item xs={1}>
          <strong>*File : </strong>
        </Grid>
        <Grid item xs={4}>
          <input
            id="upload-file"
            type="file"
            accept=".xlsx"
            required
            style={{ width: "100%" }}
            onChange={handleFileChange}
          />
        </Grid>
        <Grid item xs={10}>
          {searchForm?.dataTableSearch?.length ? (
            <table id="table_upload">
              <thead>
                <tr>
                  <td>Type</td>
                  <td>File Name</td>
                  <td>Upload by</td>
                  <td>
                    Upload Date
                    <br />
                    (DD/MM/YYYY)
                  </td>
                  <td>Status</td>
                </tr>
              </thead>
              <tbody>
                {searchForm?.dataTableSearch?.map((val, index) => {
                  return (
                    <tr key={index}>
                      <td>{val.type}</td>
                      <td>{val.fileName}</td>
                      <td>{val.updateBy}</td>
                      <td>{val.updateDt}</td>
                      <td>
                        <span
                          style={{ color: "blue", cursor: "pointer" }}
                          onClick={() => {
                            handleTabLogDetail(val);
                          }}>
                          {val.status}
                        </span>
                      </td>
                    </tr>
                  );
                })}
              </tbody>
            </table>
          ) : (
            <></>
          )}
        </Grid>
        <Grid item xs={12} display="flex" justifyContent="flex-end" marginTop="36px" alignItems="center">
          <InputButton value="Download Template" variant="text" color="info" onClick={handleDownloadTemplateExcel} />
          <InputButton value="Upload" onClick={handleUpload} />
          <InputButton value="Clear" onClick={handleClearUpload} />
        </Grid>
      </>
    );
  }
  return null;
}
