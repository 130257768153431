import React, { Fragment } from "react";
// Component
import { Grid } from "@mui/material";
import { InputButton, LinkFile } from "components/UI";
//Utils
import { responseDownloadFileErrors } from "utils";
// Constant & Type
import { API_SHIPMENT, TEMPLATE_FILE_CODE, BUTTON_VALUE } from "shared/constants";
// Service
import { useDownloadTemplateMutation } from "shared/services/inventory-forecast";
import { useLoadingContext } from "shared/hooks/LoadingContext";
export default function ActionTableBar({ setMsgAlert, setMsgError }) {
  const { startLoading, stopLoading } = useLoadingContext();
  const exportTemplate = useDownloadTemplateMutation();
  const handleDownloadTemplate = async () => {
    setMsgAlert([]);
    setMsgError([]);
    try {
      const infoFile = TEMPLATE_FILE_CODE.find(v => v.value === "Actual Upload");
      const body = { [API_SHIPMENT.DATA_OWNER]: "TMATH", cd: infoFile?.cd };
      startLoading();
      await exportTemplate(body);
      stopLoading();
    } catch (error) {
      stopLoading();
      const err = responseDownloadFileErrors(error);
      setMsgError([err]);
      // setMsgError([errors]);
    }
  };
  return (
    <Fragment>
      <Grid container spacing={2} sx={{ mb: 2 }}>
        <Grid item xs={12} sx={{ display: "flex", justifyContent: "flex-end" }}>
          <InputButton id="btn_cancel" value={"Cancel Shipment"} disabled={true} />
          <InputButton id="btn_edit" value={BUTTON_VALUE.EDIT} disabled={true} />
          <InputButton id="btn_add" value={"Add Shipment"} disabled={true} />
          <LinkFile value={"Actual Upload Template"} onClick={handleDownloadTemplate} />
        </Grid>
      </Grid>
    </Fragment>
  );
}
