import { Fragment, useEffect, useMemo, useState } from "react";
import { Box, Divider, Grid } from "@mui/material";
import { HeaderContentBar } from "components/Layout";
import { AlertMessage, InputButton, InputText } from "components/UI";
import {
  useOtherMaterialStockManagementGetStatusQuery,
  useOtherMaterialStockManagementUpdateMutation,
} from "shared/services/inventory";
import { responseErrors, responseSuccess } from "utils";
import { userProfile } from "constant";
import { useConfirmDialogContext } from "context/confirmDialogContext";

const UNFREEZE = "1";
const FREEZE = "0";
const OtherMaterialStockManagementScreen = () => {
  const confirmDialogCtx = useConfirmDialogContext();

  const [msgError, setMsgError] = useState([]);
  const [msgAlert, setMsgAlert] = useState([]);

  // const [mode, setMode] = useState("view");

  const { data: statusOtherMaterial, error: statusOtherMaterialErr } = useOtherMaterialStockManagementGetStatusQuery();
  const updateOtherMaterial = useOtherMaterialStockManagementUpdateMutation();
  const [searchForm, setSearchForm] = useState({
    systemStatus: "",
    systemStatusCd: "",
    // systemStatus: statusOtherMaterial[0].systemStatus,
    // systemStatusCd: statusOtherMaterial[0].systemStatusCd,
  });
  useEffect(() => {
    if (statusOtherMaterialErr) {
      setMsgError([
        "MSTD7024BERR: Missing mandatory data from TB_M_SYSTEM, CATEGORY=TMATH, SUB_CATEGORY=SYSTEM_STATUS, CD=SYSTEM_STATUS_FLAG",
      ]);
      return;
    }
    if (statusOtherMaterial && statusOtherMaterial[0].remark === "*Remaining some parking data to be clear") {
      setMsgError(["MSTD0000AINF: *Remaining some parking data to be clear"]);
    }
    if (!statusOtherMaterial?.length) {
      setSearchForm({ systemStatus: "", systemStatusCd: "" });
      return;
    }
    setSearchForm({
      systemStatus: statusOtherMaterial[0].systemStatus,
      systemStatusCd: statusOtherMaterial[0].systemStatusCd,
    });
    return;
  }, [statusOtherMaterial, statusOtherMaterialErr]);

  const isDisabledFreezeBtn = useMemo(() => {
    if (!statusOtherMaterial || statusOtherMaterialErr) {
      return true;
    }
    if (
      statusOtherMaterial[0].remark === "*Remaining some parking data to be clear" ||
      statusOtherMaterial[0].systemStatusCd === "0"
    ) {
      return true;
    }
    return false;
  }, [statusOtherMaterial]);

  const isDisabledUnFreezeBtn = useMemo(() => {
    if (!statusOtherMaterial || statusOtherMaterialErr) {
      return true;
    }
    if (
      statusOtherMaterial[0].remark === "*Remaining some parking data to be clear" ||
      statusOtherMaterial[0].systemStatusCd === "1"
    ) {
      return true;
    }
    return false;
  }, [statusOtherMaterial]);

  const handleUpdate = async statusId => {
    const confirm = await confirmDialogCtx.success({
      type: statusId === "0" ? "MDN93007ACFM_CONFIRM_FREEZE" : "MDN93007ACFM_CONFIRM_UNFREEZE",
    });
    if (!confirm) {
      return;
    }
    try {
      setMsgAlert([]);
      setMsgError([]);
      const body = {
        dataOwner: userProfile.dataOwner,
        systemStatusCd: statusId,
        updateBy: userProfile.userId,
      };
      const response = await updateOtherMaterial(body);
      const msg = responseSuccess(response);
      setMsgAlert([msg]);
      return;
    } catch (error) {
      const errors = responseErrors(error);
      setMsgError(errors);
      return;
    }
  };
  return (
    <Fragment>
      <Box sx={{ padding: "1rem" }}>
        {/* Header */}
        <HeaderContentBar title="WDN93040 : Other Material Stock Management Screen" />
        {/* Alert Message */}
        <AlertMessage type={"warning"} message={msgError} />
        <AlertMessage type={"success"} message={msgAlert} />

        <Grid container spacing={0} sx={{ mt: 1, alignItems: "center", padding: "1rem" }}>
          <Grid item xs={2}>
            <strong>System Status : </strong>
          </Grid>
          <Grid item xs={2}>
            <InputText
              id="input_system_status"
              fullWidth={true}
              value={searchForm.systemStatus}
              onChange={e => {
                setSearchForm(old => ({
                  ...old,
                  systemStatus: e.target.value,
                }));
              }}
              disabled
              maxLength={30}
            />
          </Grid>
          <Grid item xs={4} pl={3}>
            <InputButton
              value={"Freeze"}
              color="inherit"
              size="small"
              style={{ textTransform: "none" }}
              onClick={() => handleUpdate(FREEZE)}
              disabled={isDisabledFreezeBtn}
            />
            <InputButton
              value={"Unfreeze"}
              color="info"
              size="small"
              style={{ textTransform: "none" }}
              onClick={() => handleUpdate(UNFREEZE)}
              disabled={isDisabledUnFreezeBtn}
            />
          </Grid>
        </Grid>
        <Grid item xs={12} mt={2}>
          <Divider />
        </Grid>

        <Grid container spacing={0} sx={{ mt: 2, alignItems: "center" }}></Grid>
      </Box>
    </Fragment>
  );
};

export default OtherMaterialStockManagementScreen;
