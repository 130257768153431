import { AppBar, Typography } from "@mui/material";
import { userProfile } from "constant";
import React from "react";
import { getLocalDate } from "utils/init-config-date";

export const HeaderContentBar = ({ title = "", isAdmin = false, setIsAdmin = undefined }) => {
  const style = {
    areaHeader: {
      display: "flex",
      justifyContent: "space-between",
      padding: 8,
      borderBottom: "1px solid #cec5c5",
    },
    bgUserNameBar: {
      backgroundColor: "#e2dfdf",
    },
    bgTitleBar: {
      backgroundColor: "#969191",
      color: "#ffff",
    },
    shadowNone: {
      boxShadow: "none",
    },
    appBar: {
      marginBottom: 30,
    },
  };

  const styleAppBar = {
    ...style.shadowNone,
    ...style.appBar,
  };

  const styleUserBar = {
    ...style.areaHeader,
    ...style.bgUserNameBar,
  };

  const styleTitleBar = {
    ...style.areaHeader,
    ...style.bgTitleBar,
  };
  const handleRole = () => {
    if (setIsAdmin) setIsAdmin(!isAdmin);
  };
  const USER = `User: ${userProfile.displayName}`;
  const ADMIN = `Admin: ${userProfile.displayName}`;

  return (
    <AppBar position="static" color="secondary" style={styleAppBar} enableColorOnDark>
      <div style={styleUserBar}>
        <div style={{ color: "red", fontWeight: "bold", marginTop: "5px" }}>POLYGON</div>
        <Typography component="button" onClick={handleRole} sx={{ border: "none", backgroundColor: "#e2dfdf" }}>
          {/* if want to show user delete color css*/}
          {isAdmin ? ADMIN : USER}
        </Typography>
      </div>
      <div style={styleTitleBar}>
        <div>{title}</div>
        <div>{getLocalDate().format("DD MMM YYYY HH:mm")}</div>
      </div>
    </AppBar>
  );
};
