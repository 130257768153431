import React, { useEffect, Fragment } from "react";
import { useState } from "react";
import { useLocation } from "react-router-dom";

import { Box, Grid } from "@mui/material";
import { HeaderContentBar } from "components/Layout";
import { AlertMessage, InputDropDown } from "components/UI";
import RePrintMode from "./partials/RePrintMode";
import NewPrintMode from "./partials/NewPrintMode";
import HistoryMode from "./partials/HistoryMode";
import { useGetBarcodeMode } from "service/barcodeprint";
import { userProfile } from "constant";

export const BarcodePrintScreen = () => {
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);

  const [mode, setMode] = useState("");
  const [msgError, setMsgError] = useState([]);
  const [msgAlert, setMsgAlert] = useState([]);

  // api
  const { data: barcodeModeData } = useGetBarcodeMode({
    dataOwner: userProfile.dataOwner,
  });

  useEffect(() => {
    setMsgAlert([]);
    setMsgError([]);
    setMode(queryParams.get("mode"));
    return;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location.search]);

  return (
    <Fragment>
      <Box sx={{ padding: "1rem" }}>
        {/* Header */}
        <HeaderContentBar title="WDN910C0 : Barcode Print Screen" />
        {/* Alert Message */}
        <AlertMessage type={"warning"} message={msgError} />
        <AlertMessage type={"success"} message={msgAlert} />
        <Grid container spacing={2} sx={{ mt: 4, alignItems: "center" }}>
          <Grid item xs={2}>
            <strong>*Mode</strong>
          </Grid>
          <Grid item xs={3}>
            <InputDropDown
              id="select_mode"
              value={mode}
              onChange={e => setMode(e.target.value)}
              memu={barcodeModeData?.map(val => ({
                key: val?.cd,
                value: val?.value,
              }))}
              disabled
              placeholder="<All>"
              defaultValue=""
              required
            />
          </Grid>
          <Grid item xs={1} />

          {/* New Print */}
          {mode === "N" && <NewPrintMode mode={mode} setMsgError={setMsgError} setMsgAlert={setMsgAlert} />}

          {/* Re Print */}
          {mode === "R" && <RePrintMode mode={mode} setMsgError={setMsgError} setMsgAlert={setMsgAlert} />}

          {/* History */}
          {mode === "H" && <HistoryMode printType={mode} setMsgError={setMsgError} setMsgAlert={setMsgAlert} />}
        </Grid>
      </Box>
    </Fragment>
  );
};

export default BarcodePrintScreen;
