export const getFunctionIDTemplate = dataType => {
  switch (dataType) {
    case "PK":
      return "DN920A0";
    case "PD":
      return "DN92090";
    case "PT":
      return "DN920H0";
    case "TR":
      return "DN920I0";
    case "RC":
      return "DN920J0";

    default:
      return "";
  }
};
