const COLUMN_EXPORT_INVOICE_CREATION_INFO = {
  NO: "No.",
  SHIPMENT_CODE: "Shipment Code",
  INVOICE_NO: "Invoice No.",
  ETD_DATE: "ETD (DD/MM/YYYY)",
  IMPORTER_COMPANY: "Importer",
  VESSEL_NAME: "Vessel Name",
  INVOICE_DATE: "Invoice Date (DD/MM/YYYY)",
  INVOICE_NET_WEIGHT: "Net Weight (KGS)",
  INVOICE_FOB_PRICE: "FOB Price",
  EXP_CUSTOMS_STK_DATE: "Export Customs Stock Date (DD/MM/YYYY)",
};

const COLUMN_EXPORT_INVOICE_CREATION_SUB_SCREEN_INFO = {
  NO: "No.",
  PACKING_MONTH: "Packing Month (MM/YYYY)",
  EXPORTER_CODE: "Exporter Code",
  IMPORTER_COMPANY: "Importer Company",
  IMPORTER_CODE: "Importer Code",
  VANNING_PLANT: "Vanning Plant",
  RENBAN_NO: "Renban No.",
  ORDER_TYPE: "Order Type",
  VESSEL_NAME: "Vessel Name",
};

const COLUMN_EXPORT_INVOICE_CANCEL_VANNING_RESULT_SUB_SCREEN_INFO = {
  NO: "No.",
  EXPORTER_CODE: "Exporter Code",
  IMPORTER_COMPANY: "Importer Company",
  RENBAN_NO: "Renban No.",
  ETD_DATE: "ETD (DD/MM/YYYY)",
  CONTAINER_NO: "Container No.",
  QTY: "Qty",
  INVOICE_NO: "Invoice No.",
};

export {
  COLUMN_EXPORT_INVOICE_CREATION_INFO,
  COLUMN_EXPORT_INVOICE_CREATION_SUB_SCREEN_INFO,
  COLUMN_EXPORT_INVOICE_CANCEL_VANNING_RESULT_SUB_SCREEN_INFO,
};
