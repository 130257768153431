export const allowAlphabhetNumberUnderScore = (old, value, isSearch = true) => {
  if (value === "") {
    return value;
  }

  if (isSearch && /^[a-zA-Z0-9_*]*$/.test(value)) {
    return value;
  }
  if (/^[a-zA-Z0-9_]*$/.test(value)) {
    return value;
  }
  return old.toUpperCase();
};
export const allowAlphabhetNumber = (old, value, isSearch = true) => {
  if (value === "") {
    return value;
  }

  if (isSearch && /^[a-zA-Z0-9*]*$/.test(value)) {
    return value;
  }
  if (/^[a-zA-Z0-9]*$/.test(value)) {
    return value;
  }
  return old;
};
