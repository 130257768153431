import React, { useMemo, useState } from "react";
import { Box, Grid, Divider } from "@mui/material";
import {
  DatePickerField,
  InputButton,
  InputDropDown,
  InputText,
} from "components/UI";
import {
  useGetMaker,
  useGetPONumberType,
  useGetRPackageType,
  useNewPrint,
  useSearchNewBarcodePrint,
} from "service/barcodeprint";
import { userProfile } from "constant";
import {
  findObject,
  message,
  responseDownloadFileErrors,
  responseErrors,
} from "utils";
import { formatRow } from "../utils/formatRow";
import useColumnNewPrint from "../hooks/useColumnNewPrint";
import DataTable from "pages/DataTable";
import InputCurrency from "components/UI/InputCurrency";
import { useConfirmDialogContext } from "context/confirmDialogContext";

const NewPrintMode = ({ mode, setMsgError, setMsgAlert }) => {
  const [form, setForm] = useState({
    mode: mode,
    makerImpExpCd: "",
    poNo: "",
    rPackTypeId: "",
    barcodeFrom: "",
    barcodeTo: "",

    totalRemainingQuantity: "",
    poPeriod: "",
    printed: "",
    numberOfCopy: "",
    dataSearch: {},
  });

  const [rows, setRows] = useState([]);
  const [onSearch, setOnSearch] = useState(false);
  const [deliveryDate, setDeliveryDate] = useState([]);
  const [deliveryPlanDt, setDeliveryPlanDt] = useState("");
  const [disabledPrintBarcodeBtn, setDisabledPrintBarcodeBtn] = useState(false);

  // api
  const { data: RmMakerData } = useGetMaker({
    dataOwner: userProfile.dataOwner,
  });

  const { data: poNumberData } = useGetPONumberType({
    dataOwner: userProfile.dataOwner,
    impExpCd: form?.makerImpExpCd,
  });

  const { data: RpackageTypData } = useGetRPackageType({
    dataOwner: userProfile.dataOwner,
    rPkgOwnerCompAbbr: userProfile.rPkgOwnerCompAbbr,
  });

  const { mutateAsync: searchDataAsync } = useSearchNewBarcodePrint({});

  const confirmDialogCtx = useConfirmDialogContext();

  const createDataAsync = useNewPrint();
  const columnGroupingModel = [
    {
      groupId: "Warehouse Supplier",
      headerAlign: "center",
      children: [{ field: "warehouseSupplier" }],
      headerClassName: "align-items-end",
    },
    {
      groupId: `${deliveryPlanDt}`,
      headerAlign: "center",
      children: deliveryDate.map((date) => ({ field: date })),
      headerClassName: "table-header-group",
    },
  ];
  const columns = useMemo(() => {
    try {
      // eslint-disable-next-line react-hooks/rules-of-hooks
      return useColumnNewPrint({ deliveryDate, deliveryPlanDt });
    } catch (error) {
      console.error(error);
    }
  }, [deliveryDate, deliveryPlanDt]);
  // handle clear
  const handleClear = () => {
    setMsgAlert([]);
    setMsgError([]);
    setOnSearch(false);
    setForm({
      makerImpExpCd: "",
      poNo: "",
      rPackTypeId: "",
      barcodeFrom: "",
      barcodeTo: "",

      totalRemainingQuantity: "",
      poPeriod: "",
      printed: "",
      numberOfCopy: "",
      dataSearch: {},
    });
    // setForm(old => ({
    //   ...old,
    //   makerImpExpCd: "",
    //   poNo: "",
    //   rPackTypeId: "",
    //   barcodeFrom: "",
    //   barcodeTo: "",
    //   numberOfCopy: "",
    //   printed: "",
    // }));
  };
  // handle search
  const handleSearch = async () => {
    try {
      // reset Form
      setMsgAlert([]);
      setMsgError([]);
      setRows([]);
      setDeliveryDate([]);
      setDeliveryPlanDt("");
      setDisabledPrintBarcodeBtn(false);

      const body = {
        dataOwner: userProfile.dataOwner,
        printType: mode,
        makerImpExpCd: form.makerImpExpCd,
        poNo: form.poNo,
        rPkgTypeId: form.rPackTypeId,
        rPkgType: findObject({
          data: RpackageTypData,
          value: form.rPackTypeId,
          property: "rPkgTypeId",
          field: "rPkgType",
        }),
        barcodeFrom: form.barcodeFrom,
        barcodeTo: form.barcodeTo,
        // barcodeFrom: formatDateToSave(document.querySelector(".input_barcodeFrom input").value),
        // barcodeTo: formatDateToSave(document.querySelector(".input_barcodeTo input").value),
      };
      const searchData = await searchDataAsync(body);

      const { data, deliveryDate, deliveryPlanDt } = formatRow(searchData);
      setRows(data);
      setDeliveryDate(deliveryDate);
      setDeliveryPlanDt(deliveryPlanDt);

      setForm((old) => ({
        ...old,
        totalRemainingQuantity: searchData?.result?.totalRemainingQuantity,
        numberOfCopy: searchData?.result?.numberOfCopy,
        poPeriod: searchData?.result?.poPeriod,
        printed: "",
        dataSearch: searchData?.result,
      }));
      setOnSearch(true);
    } catch (error) {
      const errors = responseErrors(error);
      setMsgError(errors);
      window.scrollTo(0, 0);
      return;
    }
  };

  // handle print
  const handlePrint = async () => {
    setMsgAlert([]);
    setMsgError([]);
    const saveConfirm = await confirmDialogCtx.success({
      type: "confirmPrint",
    });
    if (!saveConfirm) {
      return;
    }
    try {
      const body = {
        dataOwner: userProfile.dataOwner,
        rPkgType: findObject({
          data: RpackageTypData,
          value: form.rPackTypeId,
          property: "rPkgTypeId",
          field: "rPkgType",
        }),
        poNo: form.poNo,
        printQty: form.printed,
        numOfCopy: form.numberOfCopy,
        //TODO fixed printLocation: "TMATH-EM",
        printLocation: "TMATH-EM",
        printBy: userProfile.userId,
      };

      await createDataAsync(body);
      setDisabledPrintBarcodeBtn(true);

      setMsgAlert([message({ type: "added" })]);
      handleSearch();
      return;
    } catch (error) {
      const errors = responseDownloadFileErrors(error);
      setMsgError(errors);
      window.scrollTo(0, 0);
      return;
    }
  };

  return (
    <>
      <Grid item xs={6} />
      <Grid item xs={2}>
        <strong>*R/M Maker : </strong>
      </Grid>
      <Grid item xs={3}>
        <InputDropDown
          id="select_makerImpExpCd"
          value={form?.makerImpExpCd}
          focus={true}
          onChange={(e) =>
            setForm((old) => ({
              ...old,
              makerImpExpCd: e.target.value,
              poNo: "",
            }))
          }
          memu={RmMakerData?.map((val) => ({
            key: val.impExpCd,
            value: val.impExpCd,
          }))}
          disabled={onSearch}
          placeholder="<Select>"
          defaultValue=""
          required
        />
      </Grid>
      <Grid item xs={1} />
      <Grid item xs={2}>
        <strong>*P/O Number : </strong>
      </Grid>
      <Grid item xs={3}>
        <InputDropDown
          id="select_poNo"
          value={form?.poNo}
          onChange={(e) =>
            setForm((old) => ({
              ...old,
              poNo: e.target.value,
            }))
          }
          memu={poNumberData?.map((val) => ({
            key: val.poNo,
            value: val.poNo,
          }))}
          placeholder="<Select>"
          defaultValue=""
          required
          disabled={onSearch}
        />
      </Grid>
      <Grid item xs={1} />
      <Grid item xs={2}>
        <strong>*R-Package Type : </strong>
      </Grid>
      <Grid item xs={3}>
        <InputDropDown
          id="select_rPackTypeId"
          value={form?.rPackTypeId}
          onChange={(e) =>
            setForm((old) => ({
              ...old,
              rPackTypeId: e.target.value,
            }))
          }
          memu={RpackageTypData?.map((val) => ({
            key: val.rPkgTypeId,
            value: val.rPkgType,
          }))}
          placeholder="<Select>"
          defaultValue=""
          required
          disabled={onSearch}
        />
      </Grid>
      <Grid item xs={6} />

      <Grid item xs={2}>
        <strong>Delivery Plan Date (From) : </strong>
      </Grid>
      <Grid item xs={3}>
        <DatePickerField
          id="input_barcodeFrom"
          fullWidth
          onChange={(e) =>
            setForm((old) => ({
              ...old,
              barcodeFrom: e,
            }))
          }
          value={form?.barcodeFrom}
        />
      </Grid>
      <Grid item xs={1} />

      <Grid item xs={2}>
        <strong>Delivery Plan Date (To) : </strong>
      </Grid>
      <Grid item xs={3}>
        <DatePickerField
          id="input_barcodeTo"
          fullWidth
          onChange={(e) =>
            setForm((old) => ({
              ...old,
              barcodeTo: e,
            }))
          }
          value={form?.barcodeTo}
        />
      </Grid>

      <Grid
        item
        xs={12}
        display="flex"
        justifyContent="flex-end"
        marginTop="24px"
      >
        <InputButton value="Search" onClick={handleSearch} />
        <InputButton
          value="Clear"
          onClick={handleClear}
          onKeyDown={e => {
            if (!onSearch && e.key === "Tab") {
              e.preventDefault();
              document.getElementById("select_makerImpExpCd").focus();
            }
          }}
        />
      </Grid>
      <Grid item xs={12} sx={{ mb: 2 }}>
        <Divider sx={{ my: 2 }} />
      </Grid>
      {onSearch && (
        <>
          <Box sx={{ width: "100%" }}>
            <Grid item xs={12}>
              <DataTable
                rowHeightAuto={true}
                // tableHeight="300px"
                checkboxSelection={false}
                onSearch={onSearch}
                rows={rows}
                columns={columns}
                enabledPagination={false}
                processRowUpdate={null}
                handleChangePagination={null}
                columnGroupingModel={columnGroupingModel}
                onRowSelectionModelChange={null}
                columnVisibilityModel={{ rowNumber: false }}
              />
            </Grid>
          </Box>

          <Box sx={{ mt: 1 }}>
            <Grid container spacing={0} display="flex" alignItems="center">
              <Grid item xs={3}>
                <strong>Total Remaining Quantity : </strong>
              </Grid>
              <Grid item xs={3}>
                <InputText
                  fullWidth
                  id="input_totalRemainingQuantity"
                  value={form?.totalRemainingQuantity}
                  onChange={(e) =>
                    setForm((old) => ({
                      ...old,
                      totalRemainingQuantity: e.target.value,
                    }))
                  }
                  style={{ textAlign: "right" }}
                  disabled
                />
              </Grid>
              <Grid item xs={1} />
              <Grid item xs={2}>
                <strong>P/O Period : </strong>
              </Grid>
              <Grid item xs={3}>
                <InputText
                  fullWidth
                  id="input_poPeriod"
                  value={form?.poPeriod}
                  onChange={(e) =>
                    setForm((old) => ({
                      ...old,
                      poPeriod: e.target.value,
                    }))
                  }
                  disabled
                />
              </Grid>
              <Grid item xs={3}>
                <strong>*Print Quantity : </strong>
              </Grid>
              <Grid item xs={3}>
                <InputCurrency
                  fullWidth
                  id="input_printed"
                  value={form.printed}
                  onChange={(e) =>
                    setForm((old) => ({
                      ...old,
                      printed: e.value,
                    }))
                  }
                  style={{ textAlign: "right" }}
                  required
                  maxLimit={1000000}
                />
              </Grid>
              <Grid item xs={6} />
              <Grid item xs={3}>
                <strong>*Number or Copy : </strong>
              </Grid>
              <Grid item xs={2}>
                <InputText
                  fullWidth
                  id="input_numberOfCopy"
                  value={form?.numberOfCopy}
                  onChange={(e) =>
                    setForm((old) => ({
                      ...old,
                      numberOfCopy: e.target.value,
                    }))
                  }
                  style={{ textAlign: "right" }}
                  required
                />
              </Grid>
            </Grid>
          </Box>

          <Box width="100%" textAlign="right" marginTop="16px">
            <InputButton
              value="Print Barcode"
              onClick={handlePrint}
              disabled={disabledPrintBarcodeBtn}
            />
          </Box>
        </>
      )}
    </>
  );
};

export default NewPrintMode;
