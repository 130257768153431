/**
 * Compares two strings lexicographically.
 * The comparison is based on the Unicode value of each character in
 * the strings. The character sequence represented by this
 * String object is compared lexicographically to the
 * character sequence represented by the argument string. The result is
 * a negative integer if this String object
 * lexicographically precedes the argument string. The result is a
 * positive integer if this String object lexicographically
 * follows the argument string. The result is zero if the strings
 * are equal; compareTo returns 0 exactly when
 * the equals(Object) method would return true.
 *
 * @param {string} anotherString - the String to be compared.
 * @return {number} the value 0 if the argument string is equal to
 *          this string; a value less than 0 if this string
 *          is lexicographically less than the string argument; and a
 *          value greater than 0 if this string is
 *          lexicographically greater than the string argument.
 */
export function compareTo(value, anotherString) {
  if (value === anotherString) {
    return 0;
  }

  const len1 = value.length;
  const len2 = anotherString.length;
  const lim = Math.min(len1, len2);

  for (let i = 0; i < lim; i++) {
    const char1 = value.charCodeAt(i);
    const char2 = anotherString.charCodeAt(i);
    if (char1 !== char2) {
      return char1 - char2;
    }
  }

  return len1 - len2;
}
