"use client";
import { QueryClientProvider } from "@tanstack/react-query";
import React, { useState } from "react";
import queryClient from "./queryClient";

const TanStackQueryProvider = ({ children }) => {
  const [client] = useState(() => queryClient);

  return <QueryClientProvider client={client}>{children}</QueryClientProvider>;
};

export default TanStackQueryProvider;
