import React from "react";
import { EditableDropDown, InputEditTableMask, InputEditableUploadFile, LinkTableCell } from "components/UI";
// Constants
import { ModeAction } from "state/enum";
import { MONTHLY_DATA_TYPE, GENERATE_STATUS } from "../constants/monthly.type";
import { API_MONTHLY } from "shared/constants/api-name/forecast/monthly";
import { MONTH_COLUMNS_NAME } from "../constants/column.constant";
export const useColumn = props => {
  const {
    rows,
    mode,
    dataList: { dataTypeList },
    handleUploadFile,
    handleApmcScreen,
    handleLinkUploadStatus,
    handleLinkGenerateStatus,
  } = props;
  return [
    // Col 1
    {
      field: API_MONTHLY.ROW_NUMBER,
      headerName: MONTH_COLUMNS_NAME.NO,
      headerAlign: "center",
      align: "right",
      minWidth: 100,
      flex: 0.1,
      sortable: false,
      editable: false,
      renderCell: params => {
        if (params?.row?.rowNumber === rows.slice(-1)?.[0]?.rowNumber && ModeAction.ADD === mode) {
          return <div></div>;
        }
        return params.value;
      },
    },
    // Col 2
    {
      field: API_MONTHLY.FORECAST_MONTH,
      headerName: MONTH_COLUMNS_NAME.FORECAST_MONTH,
      headerAlign: "center",
      align: "center",
      flex: 0.1,
      minWidth: 150,
      sortable: false,
      editable: true,
      renderEditCell: params => {
        if (mode === ModeAction.ADD) {
          return (
            <InputEditTableMask
              {...params}
              mask="mM/YYYY"
              formatChars={{
                Y: "[0-9]",
                m: "[0-1]",
                M: "[0-9]",
              }}
              required
              maskChar={null}
              alwaysShowMask={false}
              autoFocus={true}
            />
          );
        } else {
          return params.value;
        }
      },
    },
    // Col 3
    {
      field: API_MONTHLY.DATA_TYPE,
      headerName: MONTH_COLUMNS_NAME.DATA_TYPE,
      headerAlign: "center",
      align: "left",
      type: "select",
      flex: 0.1,
      minWidth: 150,
      sortable: false,
      editable: true,
      renderCell: params => {
        return params?.value === MONTHLY_DATA_TYPE.ARMC_SOURCE_DATA ? (
          <LinkTableCell {...params} params={params} processScreen={handleApmcScreen} color="#1e88e5">
            {params?.value}
          </LinkTableCell>
        ) : (
          params.value
        );
      },
      renderEditCell: params => {
        if (mode === ModeAction.ADD) {
          return (
            <EditableDropDown
              {...params}
              style={{ margin: "0px 15px", width: "100%" }}
              required
              defaultValue=""
              menu={dataTypeList?.map(val => ({
                key: val.cd,
                value: val.value,
              }))}
              placeholder="<Select>"
            />
          );
        } else {
          return params.value;
        }
      },
    },
    // Col 4
    {
      field: API_MONTHLY.ORG_FILE_NAME,
      headerName: MONTH_COLUMNS_NAME.FILE_NAME,
      headerAlign: "center",
      align: "left",
      flex: 0.3,
      minWidth: 300,
      sortable: false,
      editable: true,
      renderEditCell: params => {
        if ([ModeAction.ADD, ModeAction.EDIT].includes(mode)) {
          return (
            <InputEditableUploadFile
              {...params}
              fileType=".xlsx"
              uploadProcess={handleUploadFile}
              required
              style={{ ml: "2px" }}
              maxLength={50}
            />
          );
        } else {
          return params.value;
        }
      },
      renderCell: params => {
        if (params?.row?.dataType === MONTHLY_DATA_TYPE.ARMC_SOURCE_DATA) {
          return <div></div>;
        } else {
          return params?.value;
        }
      },
    },
    // Col 5
    {
      field: API_MONTHLY.UPLOAD_STATUS,
      headerName: MONTH_COLUMNS_NAME.UPLOAD_STATUS,
      headerAlign: "center",
      align: "left",
      flex: 0.2,
      minWidth: 100,
      sortable: false,
      editable: false,
      renderCell: params => {
        return params.row.dataType === MONTHLY_DATA_TYPE.ARMC_SOURCE_DATA ? (
          <div></div>
        ) : (
          <LinkTableCell href="#" color="#1e88e5" processScreen={handleLinkUploadStatus} {...params} params={params}>
            {params?.value}
          </LinkTableCell>
        );
      },
    },
    // Col 6
    {
      field: API_MONTHLY.UPLOAD_DATE,
      headerName: MONTH_COLUMNS_NAME.UPLOAD_DATE,
      headerAlign: "center",
      align: "center",
      flex: 0.2,
      minWidth: 150,
      sortable: false,
      editable: false,
      renderCell: params => {
        return params?.row?.dataType === MONTHLY_DATA_TYPE.ARMC_SOURCE_DATA ? <div></div> : params.value;
      },
    },
    // Col 7
    {
      field: API_MONTHLY.UPLOAD_BY,
      headerName: MONTH_COLUMNS_NAME.UPLOAD_BY,
      headerAlign: "center",
      align: "left",
      flex: 0.2,
      minWidth: 150,
      sortable: false,
      editable: false,
      renderCell: params => {
        return params?.row?.dataType === MONTHLY_DATA_TYPE.ARMC_SOURCE_DATA ? <div></div> : params.value;
      },
    },
    // Col 8
    {
      field: API_MONTHLY.USE_STATUS,
      headerName: MONTH_COLUMNS_NAME.GENERATE_STATUS,
      headerAlign: "center",
      align: "left",
      flex: 0.2,
      minWidth: 150,
      sortable: false,
      editable: false,
      renderCell: params => {
        if (params?.value === GENERATE_STATUS.GENERATED) {
          return (
            <LinkTableCell
              href="#"
              color="#1e88e5"
              processScreen={handleLinkGenerateStatus}
              {...params}
              params={params}>
              {params?.value}
            </LinkTableCell>
          );
        } else {
          return params?.value;
        }
      },
    },
    // Col 9
    {
      field: API_MONTHLY.USE_DATE,
      headerName: MONTH_COLUMNS_NAME.GENERATE_DATE,
      headerAlign: "center",
      align: "center",
      flex: 0.2,
      minWidth: 130,
      width: 130,
      sortable: false,
      editable: false,
      renderCell: params => {
        if (params?.row?.dataType === MONTHLY_DATA_TYPE.ARMC_SOURCE_DATA) {
          return params?.row?.useSts !== GENERATE_STATUS.GENERATED ? <div></div> : params.value;
        }
        if (params?.row?.useSts !== GENERATE_STATUS.GENERATED) {
          return <div></div>;
        }
        return params.value;
      },
    },
    // Col 10
    {
      field: API_MONTHLY.USE_BY,
      headerName: MONTH_COLUMNS_NAME.GENERATE_BY,
      headerAlign: "center",
      align: "left",
      flex: 0.2,
      minWidth: 120,
      width: 120,
      sortable: false,
      editable: false,
      renderCell: params => {
        if (params?.row?.dataType === MONTHLY_DATA_TYPE.ARMC_SOURCE_DATA) {
          return params?.row?.useSts !== GENERATE_STATUS.GENERATED ? <div></div> : params.value;
        }
        if (params?.row?.useSts !== GENERATE_STATUS.GENERATED) {
          return <div></div>;
        }
        return params.value;
      },
    },
    {
      field: API_MONTHLY.FILE_UPLOAD_ID,
      editable: false,
    },
    {
      field: API_MONTHLY.MODE_CD,
      editable: false,
    },
    {
      field: API_MONTHLY.DATA_TYPE_CD,
      editable: false,
    },
    {
      field: API_MONTHLY.API_ID,
      editable: false,
    },
    {
      field: API_MONTHLY.MODULE_ID,
      editable: false,
    },
    {
      field: API_MONTHLY.FUNCTION_ID,
      editable: false,
    },
    {
      field: API_MONTHLY.D_HODTCRE,
      editable: false,
    },
  ];
};
